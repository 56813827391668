import React from "react";
import Button from "../../atoms/ZButton";
import { AddSquare } from "../../../Icons";

export default function CongesNoRecords({
    openAddConge,
}: EmployeesNoRecordProps) {
    return (
        <div className='max-w-[520px] mx-auto py-8'>
            <div className='flex flex-col items-center gap-6'>
                <img src='/assets/imgs/no-employees-record.png' />
                <h2 className='font-bold text-5xl text-n-700 text-center'>
                  Aucun congé 
                </h2>
                <p className='text-n-500 text-md text-center'>
                Commencez par ajouter votre première demande de congé
                    
                </p>
                    <Button
                        leftComponent={<AddSquare />}
                        size='lg'
                        variant='primary'
                        title= "Ajouter un congé"
                        onClick={openAddConge}
                    />
            </div>
        </div>
    );
}

interface EmployeesNoRecordProps {
    openAddConge: () => void;
}
