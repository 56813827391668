import React, { useEffect } from "react";
import Modal from "../Modal";
import Button from "../../atoms/ZButton";
import { ReactComponent as CheckIcon } from '../../atoms/Checkbox/checkedIcon.svg';
import { ReactComponent as CloseIcon } from '../../../utils/img/icons/close.svg';
import TextData from "../../atoms/TextData";
import ProfilePicture from "../../molecules/ProfilePicture";
import moment from "moment";
import { ROUTER } from "../../../constants/env";
import TimingComponent from "../../molecules/TimingComponent";
import { ReactComponent as  MessageSquareText } from'../../../utils/img/lucideReact-icons/message-square-text.svg';
import ZTextarea from "../../atoms/ZTextarea";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";
import { calculateDays, congeTypes } from "../../../_helpers/Functions";

interface VacationRequestCardInterface {
    display: boolean;
    onclose?: () => void;
    employee: any;
    onReject: (congeId: any, cause: any, userId: any) => void;
    onValid: (congeId: any, cause: any, userId: any) => void;
    onDelete: (congeId: any) => void;
    loading: any;
}
const UserInfo = ({ employee }: { employee: any }) => {
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee?.user?.picture),
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 20px",
                padding: "5px",
            }}
        >
            <TextData
                variant="label"
                width="auto"
                size="22px"
                fontWeight="700"
                color="#2A346D"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        width: "fit-content",
                        height: "fit-content",
                    }}
                >
                    <ProfilePicture
                        profileImage={
                            employee?.user?.picture &&
                            generatePicUrl(employee?.user?.picture)
                        }
                        firstName={employee?.user?.fname || " "}
                        lastName={employee?.user?.lname || " "}
                        pictureProps={pictureProps}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {employee?.user?.fname} {employee?.user?.lname}
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                    >
                        Ajouté le{" "}
                        {moment(employee?.created_at, "YYYY-MM-DD")
                            .locale("fr")
                            .format("DD/MM/YYYY")}
                    </div>
                </div>
            </TextData>
        </div>
    );
};

const HolidayLabel = ({ employee }: { employee: any }) => {
    const congeType = congeTypes?.find(
        (type) => type?.value === employee?.type?.toLowerCase()?.trim(),
    );
    return (
        <div className="w-full text-lg font-medium rounded-lg flex items-center gap-2 p-3 text-default bg-n-0">
            <MessageSquareText color="#4c5690" width={20} />
            
            {congeType ? congeType?.name : "Pas de type"}
        </div>
    );
};
const VacationRequestCard = ({
    display,
    onclose,
    employee,
    onValid,
    onReject,
    onDelete,
    loading,
}: VacationRequestCardInterface) => {
    const [cause, setCause] = React.useState("");
    const [days, setDays] = React.useState(employee ? employee?.cause : "");
    useEffect(() => {
        if (employee) {
            setCause(employee?.cause);
            const calculatedDays = calculateDays(
                employee?.fromDay,
                employee?.fromHour,
                employee?.toDay,
                employee?.toHour,
            );
            setDays(calculatedDays);
        }
    }, [employee]);

    const handleAccept = () => {
        onValid(employee?._id, cause, employee?.user?._id);
        setCause("");
    };
    const handleReject = () => {
        onReject(employee?._id, cause, employee?.user?._id);
        setCause("");
    };
    const handleDelete = () => {
        onDelete(employee?._id);
    };

    return (
        <div>
            {employee && (
                <Modal
                    width={460}
                    height={610}
                    show={display}
                    onHide={onclose}
                    title={"Demande de congé"}
                    body={
                        <div className="w-full flex flex-col  space-y-3">
                            <UserInfo employee={employee} />

                            <div className="flex justify-center w-full">
                                <TimingComponent
                                    startTime={moment(employee?.fromDay)?.format("DD/MM/YY")}
                                    endTime={moment(employee?.toDay)?.format("DD/MM/YY")}
                                    hasPause={days}
                                    dateType={days > 1 ? " jours" : " jour"}
                                />
                            </div>

                            <HolidayLabel employee={employee} />

                            <p className="text-default font-semibold">
                                Commentaire :{" "}
                                <span className="text-default font-medium">
                                    {employee?.message?.length > 0
                                        ? employee?.message
                                        : "Pas de commentaire"}
                                </span>
                            </p>

                            <ZTextarea
                                field={{ name: "cause", value: cause }}
                                required={false}
                                label={"Notes"}
                                onUpdate={(newValue) =>
                                    setCause(newValue.toString())
                                }
                            />
                        </div>
                    }
                    ConfirmComponent={
                        <Button
                            loading={loading}
                            variant="primary"
                            title={"Approuver"}
                            leftComponent={
                                <div className="">
                                    <CheckIcon   height={20}
                                                width={20} />
                                </div>
                            }
                            onClick={handleAccept}
                        />
                    }
                    CancelComponent={
                        <Button
                            loading={loading}
                            containerClasses="bg-red-500 text-white hover:bg-red-500/75"
                            variant="danger"
                            title={"Refuser"}
                            leftComponent={
                                <div className="rotate-180">
                                    <CloseIcon  height={20}
                                                width={20} 
                                                color='#2A8BAB'
                                                />
                                </div>
                            }
                            onClick={handleReject}
                        />
                    }
                    // Ps: FOR NEXT UPDATE
                    // cancel="ou"
                    // cancelRightComponent={
                    //     <Button
                    //         loading={loading}
                    //         containerClasses="bg-red-500 text-white hover:bg-red-500/75"
                    //         variant="danger"
                    //         title={"Refuser"}
                    //         leftComponent={
                    //             <div className="rotate-180">
                    //                 <Close />
                    //             </div>
                    //         }
                    //         onClick={ handleReject}
                    //     />
                    // }
                    // cancelLeftComponent={
                    //     <div
                    //         className="rounded-lg flex items-center justify-center gap-1 bg-red-500 text-white hover:bg-red-500/75 text-md px-3 py-2"
                    //         onClick={handleDelete}
                    //     >
                    //         <Trash2 className="" />
                    //     </div>
                    // }
                />
            )}
        </div>
    );
};

export default VacationRequestCard;
