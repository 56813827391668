import { congeTypes } from "../../../../_helpers/Functions";


export const TypeColumnFormatter = (content: any) => {
  const congeType = congeTypes.find((type) => type.value === content?.toLowerCase()?.trim());

  return (
    <span className="text-v-800 text-md font-light">
      {congeType ? congeType.name : "Pas de type"}
    </span>
  );
};