import * as React from "react";

import DashboardTemplate from "../../components/templates/Dashboard";
import TextData from "../../components/atoms/TextData";
import Button from "../../components/atoms/Button";
import SettingSection from "../../components/organism/SettingSection";

import TabNavigator from "../../components/molecules/TabNavigationV2";

import Input from "../../components/atoms/Input";
import ToggleButton from "../../components/atoms/Toggle";
import Radio from "../../components/atoms/Radio";
import SelectInput from "../../components/atoms/SelectInput";
import { useTimeTracker } from "../../hooks/useTimeTracker";
import NoAccess from "./Elements/NoAccess";
import { ReactComponent as NoAccessSVG } from "./icons/no-access.svg";
import { useBranch } from "../../hooks/useBranch";
import ZAlert from "../../components/atoms/ZAlert";
import { CircleInfos } from "../../Icons";

export default function TimeTracker(props: TimeTrackerProps) {
    const { getTimeTracker } = useTimeTracker();
    const { selectedBranchSettings } = useBranch();
    React.useEffect(() => {
        getTimeTracker();
    }, []);

    const {
        noAccessParams,
        settings,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const alertBody = () => {
        return (
            <div>
                Pensez à relancer l’application pointeuse si vous modifiez un
                paramètre ou si vous ajoutez un collaborateur.
            </div>
        );
    };
    return (
        <div className="time-tracker-container">
            <div
                style={{
                    height: "calc(100vh - 290px)",
                    overflow: "auto",
                }}
            >
                {!selectedBranchSettings._id && <NoAccess />}
                <div
                    style={{
                        color: "red",
                        padding: "10px",
                        fontWeight: "500",
                    }}
                >
                    <ZAlert icon={<CircleInfos />} formatter={alertBody} />
                </div>
                {settings?.map((elem, index) => {
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                        />
                    );
                })}
            </div>
            {includeButtons && (
                <div className="settings-footer">
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor="#EDEFF5"
                        textColor="#2A346D"
                        onClick={handleCancelButton}
                        width="120px"
                        height="40px"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2A8BAB",
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor="#2A8BAB"
                        textColor="white"
                        onClick={handleSubmitButton}
                        width="120px"
                        height="40px"
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface TimeTrackerProps {
    noAccessParams?: {
        ImageComponent?: React.ReactNode;
        title?: string;
        description?: string;
    };
    settings?: {
        headerTitle?: string;
        headerDescription?: string;
        configOptions?: React.ReactNode | ConfigOptionTemplate[];
    }[];
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
TimeTracker.defaultProps = {
    noAccessParams: {
        ImageComponent: NoAccessSVG,
        title: "Paramètres",
        description:
            "Veuillez sélectionner un établissement pour accéder aux paramètres !",
    },
    settings: [
        {
            headerTitle: "Application pointeuse",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: true
                ? [
                      {
                          optionTitle: "Utiliser la pointeuse",
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionDescription:
                              "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                          optionInput: <ToggleButton isChecked={true} />,
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Autoriser les pauses",
                          optionDescription:
                              "Les collaborateurs auront la possibilité de badges les pauses sur la tablette. En plus des boutons (Arrivée, départ) vous aurez (Départ en pause) et (Retour de pause). ",
                          optionInput: <ToggleButton isChecked={true} />,
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle:
                              "Prendre une photo au moment du pointage",
                          optionDescription:
                              "Afin de s’assurer de la présence du salarié, vous pouvez activer cette option qui prendra une photo.Sachez que la photo est automatiquement supprimée au bout d’un mois ou si vous validez le pointage.Important : Assurez-vous d’informer vos salariés de l’activation de cette option et ne l’activez pas en permanence. ",
                          optionInput: <ToggleButton isChecked={true} />,
                          optionInput2: (
                              <Radio
                                  selectedValue="each-time"
                                  items={[
                                      {
                                          itemValue: "random",
                                          itemDesignation: "Aléatoire",
                                          itemDescription:
                                              "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                                      },
                                      {
                                          itemValue: "each-time",
                                          itemDesignation: "à chaque pointage",
                                          itemDescription:
                                              "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                                      },
                                  ]}
                              />
                          ),
                      },
                  ]
                : [
                      {
                          optionTitle: "Utiliser la pointeuse",
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionDescription:
                              "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                          optionInput: <ToggleButton isChecked={true} />,
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle:
                              "Prendre une photo au moment du pointage",
                          optionDescription:
                              "Afin de s’assurer de la présence du salarié, vous pouvez activer cette option qui prendra une photo.Sachez que la photo est automatiquement supprimée au bout d’un mois ou si vous validez le pointage.Important : Assurez-vous d’informer vos salariés de l’activation de cette option et ne l’activez pas en permanence. ",
                          optionInput: <ToggleButton isChecked={true} />,
                          optionInput2: (
                              <Radio
                                  selectedValue="each-time"
                                  items={[
                                      {
                                          itemValue: "random",
                                          itemDesignation: "Aléatoire",
                                          itemDescription:
                                              "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                                      },
                                      {
                                          itemValue: "each-time",
                                          itemDesignation: "à chaque pointage",
                                          itemDescription:
                                              "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                                      },
                                  ]}
                              />
                          ),
                      },
                  ],
        },
        {
            headerTitle: "Alerte pointage",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "M'alerter en cas de retard après (en minutes)",
                    optionDescription:
                        "Permet de définir une limite de temps pour les retards de vos employés. Vous pouvez spécifier le nombre de minutes de retard acceptables avant que vous soyez alerté. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="3 Min"
                            type="text"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "M'alerter si l'employé n'a pas dépointé au bout de (en minutes)",
                    optionDescription:
                        "Permet de définir une limite de temps pour le dépointage de vos collaborateurs. Exemple : Sébastien doit dépointer à 18h. Si j’indique 15mn, le système m’envoie une notification à 18h16 si Sébastien n’a pas dépointé.  ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="3 Min"
                            type="text"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
            ],
        },
        {
            headerTitle: "Personnalisation des pointages",
            headerDescription:
                "Afin d’adapter Esperoo à votre fonctionnement, vous avez la possibilité d’activer ou désactiver ces options. Sachez que dans la page pointeuse, la colonne « Shift badgé » affichera toujours le pointage du collaborateur et que les options affectent seulement la colonne « Shift réel ». ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Si Lara arrive avant l’heure planifiée, l’heure d’arrivée planifiée sera retenue",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: <ToggleButton isChecked={true} />,
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Si Lara arrive en retard, l’heure d’arrivée planifiée sera retenue",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: <ToggleButton isChecked={false} />,
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Si Lara part avant l’heure planifiée, l’heure planifiée sera retenue",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: <ToggleButton isChecked={true} />,
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Si Lara part après l’heure planifiée, l’heure planifiée sera retenue",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: <ToggleButton isChecked={true} />,
                },
            ],
        },
        {
            headerTitle: "Tolérance pointage",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Tolérance sur l’heure d’arrivée (en minutes)",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="30 Min"
                            type="text"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Tolérance sur l’heure de départ (en minutes)",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="30 Min"
                            type="text"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
            ],
        },
        {
            headerTitle: "",
            headerDescription: "",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Activer 'Valider la semaine' pour les managers",
                    optionDescription: "Nulla Lorem mollit cupidatat irure.",
                    optionInput: <ToggleButton isChecked={false} />,
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Activer 'Les commentaires' lors du pointage",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: <ToggleButton isChecked={false} />,
                },
            ],
        },
        {
            headerTitle: "Licence pointeuse",
            headerDescription:
                "Cette fonctionnalité est utile pour assurer que votre entreprise est en conformité avec les réglementations et les lois relatives à la gestion du temps de travail des employés. Vous pouvez définir des licences pour un nombre limité d’utilisateurs ou pour une période déterminée, et surveiller l’utilisation de la pointeuse en temps réel. ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <SelectInput
                            selectedValue={{
                                label: "Marseille",
                                value: "Marseille",
                            }}
                            style={{ width: "300px" }}
                        />
                    ),
                    optionInput2: (
                        <Input
                            captionText="Code de la licence"
                            captionSize="12px"
                            captionColor="#8E96C1"
                            placeholder=""
                            value="GUEU-X5QK-F9DF-QEDZ"
                            inputWidth="300px"
                            borderColor="#C7CBE2"
                            borderWidth={1}
                        />
                    ),
                },
            ],
        },
    ],
    includeButtons: true,
    submitButtonText: "Modifier",
    handleSubmitButton: () => {},
    cancelButtonText: "Annuler",
    handleCancelButton: () => {},
};
