const { envMode } = require("./envFile");

let envFile;

export const ZENDESK_KEY = "7ab9eeba-ad9b-4192-966b-95b5d0f6a66a";

switch (envMode) {
    case "production":
        envFile = require("./production");
        break;
    case "development":
        envFile = require("./development");
        break;
    case "test":
        envFile = require("./beta");
        break;
    default:
        envFile = require("./development");
        break;
}
const { clientURL, StaticURL } = envFile;

const sections = {
    auth: "",
    settings: "/settings",
    report: "/report",
};
export const ROUTER = {
    HOME: `/`,
    STATIC: `${StaticURL}`,
    STATIC_FILE: (filePath: string) => `${StaticURL}/files/${filePath}`,
    STATIC_FILE2: (filePath: string) => `${StaticURL}/${filePath}`,
    STATIC_IMG: (filePath: string) => `${StaticURL}/images/${filePath}`,
    AUTH: {
        LOGIN: `${sections.auth}/login`,
        FORGET_PASS: `${sections.auth}/forget-password`,
        CHANGE_PASS: `${sections.auth}/change-password`,
        RESET_PASS: `${sections.auth}/reset-password/:resetToken`,
        CHECK_EMAIL: `${sections.auth}/check-email`,
    },
    SETTINGS: {
        BRANCH: `${sections.settings}/branch`,
        SCHEDULE: `${sections.settings}/schedule`,
        TIME_TRACKER: `${sections.settings}/time-tracker`,
        MOBILE: `${sections.settings}/mobile`,
        NOTIFICATIONS: `${sections.settings}/notifications`,
        VACATION: `${sections.settings}/vacation`,
        INTEGRATION: `${sections.settings}/integration`,
        ADMINISTRATIVE: `${sections.settings}/administrative`,
    },
    STARTUP: {
        STARTUP: `/startup`,
    },
    REPORT: {
        WORKED_HOURS: `${sections.report}/worked_hours`,
        DELAY: `${sections.report}/delay`,
        VACATION: `${sections.report}/vacation`,
        PRODUCTIVITY: `${sections.report}/productivity`,
        PAYROLL: `${sections.report}/payroll`,
        ACTIVITY: `${sections.report}/activity`,
    },
    PLANNING: "/planning",
    NOTIFICATIONS: "/notifications",
    DASHBOARD: "/dashboard",
    SUPPORT: "support",
};

export const API = {
    client: {
        notification: {
            get: (userId: any) => `${clientURL}/notifications/${userId}`,
            getAll: (userId: any) =>
                `${clientURL}/notifications/${userId}?all=${true}`,
            set_seen: (userId: any) =>
                `${clientURL}/notifications/set-seen/${userId}`,
            set_clicked: (notificationId: any) =>
                `${clientURL}/notifications/set-clicked/${notificationId}`,
        },
        auth: {
            me: `${clientURL}/me?web=true`,
            login: `${clientURL}/auth/login`,
            forgetPassword: `${clientURL}/auth/reset`,
            resetPassword: `${clientURL}/auth/reset-password`,
            changePassword: `${clientURL}/users/changePassword`,
        },
        settings: {
            branch: {
                getListOfManagedBranchs: `${clientURL}/sites/manage`,
                get: (id: any) =>
                    `${clientURL}/sites/site-settings-etablissement/${id}`,
                update: (id: any) =>
                    `${clientURL}/sites/update-site-settings-etablissement/${id}`,
                updateSiteSettings: (id: any) =>
                    `${clientURL}/sites/update-site-settings/${id}`,
                getSiteSettings: (branchID: any) =>
                    `${clientURL}/sites/site-settings/${branchID}`,
                getPauseRepasConditions: (id: any) =>
                    `${clientURL}/pause-repas/list/${id}`,
                createPauseRepasConditions: `${clientURL}/pause-repas/create`,
                editPauseRepasConditions: `${clientURL}/pause-repas/create`,
                deletePauseRepasConditions: (conditionID: any) =>
                    `${clientURL}/pause-repas/delete/${conditionID}`,
            },
            schedule: {
                getMultiplePlanning: (branchID: any) =>
                    `${clientURL}/planning-departments/${branchID}`,
                saveMultiplePlanning: `${clientURL}/planning-departments/create`,
                deleteMultiplePlanning: (planningID: any) =>
                    `${clientURL}/planning-departments/delete/${planningID}`,
                //Tickets
                getTickets: (branchID: any) =>
                    `${clientURL}/steaker/${branchID}`,
                saveTickets: `${clientURL}/steaker/create`,
                deleteTickets: (ticketID: any) =>
                    `${clientURL}/steaker/delete/${ticketID}`,
                //Services
                getServices: (branchID: any) =>
                    `${clientURL}/service/${branchID}`,
                saveServices: `${clientURL}/service/create`,
                deleteServices: (serviceID: any) =>
                    `${clientURL}/service/delete/${serviceID}`,
                //BonusTypes
                getBonusTypes: (branchID: any) =>
                    `${clientURL}/acompte-type/${branchID}`,
                createBonusTypes: `${clientURL}/acompte-type/create`,
                editBonusTypes: `${clientURL}/acompte-type/update`,
                deleteBonusTypes: `${clientURL}/acompte-type/delete`,
                //Absence
                getAbsence: (branchID: any) =>
                    `${clientURL}/absence-color/${branchID}`,
                editAbsence: `${clientURL}/absence-color/update`,
            },
            timeTracker: {
                getTimeTrackerService: (branchID: any) =>
                    `${clientURL}/sites/site-settings/${branchID}`,
                updateTimeTrackerService: (branchID: any) =>
                    `${clientURL}/sites/update-site-settings/${branchID}`,
            },
            mobile: {
                getMobileService: (branchID: any) =>
                    `${clientURL}/sites/site-settings/${branchID}`,
                updateMobileService: (branchID: any) =>
                    `${clientURL}/sites/update-site-settings/${branchID}`,
            },
            notifications: {
                getNotifService: (branchID: any) =>
                    `${clientURL}/sites/site-settings/${branchID}`,
                updateNotifService: (branchID: any) =>
                    `${clientURL}/sites/update-site-settings/${branchID}`,
            },
            administrative: {
                getAdministrativeService: (branchID: any) =>
                    `${clientURL}/sites/site-settings/${branchID}`,
                updateAdminService: (branchID: any) =>
                    `${clientURL}/sites/update-site-settings/${branchID}`,
                getDPAE: (branchID: any) => {
                    //TODO: this is just for now, next we should remove it
                    return `${clientURL}/dpae/${branchID}`;
                },
                updateDPAE: (branchID: any) =>
                    `${clientURL}/dpae/update-info/${branchID}`,
                getContractTemplateTags: `${clientURL}/contract-template/tags`,
                getContractTemplates: (branchID: any) =>
                    `${clientURL}/contract-template/site/${branchID}`,
                postNewContractTemplate: (branchID: any) =>
                    `${clientURL}/contract-template/${branchID}`,
                updateContractTemplate: (idContractTemplate: any) =>
                    `${clientURL}/contract-template/${idContractTemplate}`,
                deleteContractTemplate: (idContractTemplate: any) =>
                    `${clientURL}/contract-template/${idContractTemplate}`,

                getAllCollectiveAgreements: `${clientURL}/collective-agreement`,
                updateSelectedCollectiveAgreement: `${clientURL}/collective-agreement/change`,
            },
            vacation: {
                getVacationService: (branchID: any) =>
                    `${clientURL}/sites/site-settings/${branchID}`,
                updateVacationService: (branchID: any) =>
                    `${clientURL}/sites/update-site-settings/${branchID}`,
            },
        },
        report: {
            exportWorkedHours: `${clientURL}/rapport/export`,
            exportPayroll: `${clientURL}/rapport/export-paie`,
            generateAttendancePDF: `${clientURL}/pdf/generate`,
            customSort: (branchID: string, department: string | null = null) =>
                `${clientURL}/custom-sort/${branchID}?department=${department}`,
            monthlyWorkHours: `${clientURL}/stats/month`,
            weeklyWorkHours: `${clientURL}/stats/week`,
            employeeFullReport: `${clientURL}/stats/full-employee-report`,
            exportDelay: `${clientURL}/stats/print/employee`,
            periodWorkHours: (employeeID: any) =>
                `${clientURL}/stats/workHours/employee/${employeeID}`,
        },
        users: {
            getUsers: (archived: boolean) =>
                `${clientURL}/users/list?archive=${archived}`,
            depositDPAE: (userID: any) => `${clientURL}/dpae/deposit/${userID}`,
        },
        planning: {
            getPlanningInfo: `${clientURL}/planning/get-planning`,
            weekPlans: `${clientURL}/planning/get-week-planning`,
            usersPlans: (siteId: string) =>
                `${clientURL}/users/planning/${siteId}`,
            getFixedPlans: `${clientURL}/planning/get-fixed-plans`,
            addNote: `${clientURL}/planning/note`,
            print: `${clientURL}/planning/print?v3=true`,
            publish: `${clientURL}/planning/publish-planning`,
            duplicate: `${clientURL}/planning/duplicate`,
            addShift: `${clientURL}/planning/new-plan`,
            shift: {
                create: `${clientURL}/planning/new-plann`,
                update: `${clientURL}/planning/update-plan`,
                delete: `${clientURL}/planning/delete-shift`,
                copy: `${clientURL}/planning/copy`,
            },
            customSort: (siteId: string, department: any) =>
                `${clientURL}/custom-sort/${siteId}?department=${department}`,
            customSortEmployees: (siteId: string, department: any) =>
                `${clientURL}/custom-sort/pretty/${siteId}?department=${department}`,
            updateCustomSort: `${clientURL}/custom-sort/update`,
            disponibility: `${clientURL}/disponibility/findByWeek`,
            steakers: (siteId: string) => `${clientURL}/steaker/${siteId}`,
            createSteaker: `${clientURL}/steaker/create`,
            absenceColor: (siteId: string) =>
                `${clientURL}/absence-color/${siteId}`,
            deletePlanning: `${clientURL}/planning/delete-planning`,
            fixedPlan: {
                create: `${clientURL}/planning/new-fixed-plan`,
                update: `${clientURL}/planning/update-fixed-plan`,
                delete: `${clientURL}/planning/delete-shift`,
            },
            plannedWeeks: `${clientURL}/planning/weeks`,
            verifyConvention: `${clientURL}/collective-agreement/verify`,
        },
        employees: {
            fetchEmployeesUrl: `${clientURL}/users/list`,
            createEmployeeUrl: `${clientURL}/users/create`,
            updateEmployeeUrl: `${clientURL}/users/update`,
            fetchEmployeeUrl: `${clientURL}/users/info`,
            fetchEmployeeInfosUrl: `${clientURL}/human_resources/info`,
            updateEmployeeInfosUrl: `${clientURL}/human_resources/update`,
            fetchEmployeeDisponibilitiesUrl: `${clientURL}/disponibility`,
            createEmployeeDisponibilitiesUrl: `${clientURL}/disponibility/create`,
            updateEmployeeDisponibilitiesUrl: `${clientURL}/disponibility/update`,
            removeUnavaibilityUrl: `${clientURL}/disponibility/delete`,
            fetchRegisterUrl: `${clientURL}/users/registre`,
            restoreEmployeeUrl: `${clientURL}/users/restore`,
            archiveEmployeeUrl: `${clientURL}/users/delete`,
            fetchContractsUrl: `${clientURL}/contract/histor`,
            updateContractUrl: `${clientURL}/contract/update`,
            createContractUrl: `${clientURL}/contract/create`,
            addEmployeeDocumentUrl: `${clientURL}/user_files`,
            fetchEmployeeDocumentsUrl: `${clientURL}/user_files`,
            deleteEmployeeDocumentUrl: `${clientURL}/user_files/delete`,
            fetchEmployeePrimesUrl: `${clientURL}/acompte/user`,
            createEmployeePrimesUrl: `${clientURL}/acompte/create`,
            updateEmployeePrimesUrl: `${clientURL}/acompte/update`,
            deleteEmployeePrimesUrl: `${clientURL}/acompte/delete`,
            listPrimestypesUrl: `${clientURL}/acompte-type`,
            fetchEmployeeHolidaysUrl: `${clientURL}/conge/my-list`,
            updateEmployeePasswordUrl: `${clientURL}/users/changePassword`,
            fetchEployeesDelay: `${clientURL}/stats/employee`,
            updateEmployeeDocument: (fileId: any) =>
                `${clientURL}/user_files/${fileId}`,
        },
        sites: {
            fetchSitesManagementUrl: `${clientURL}/sites/manage`,
        },
        countries: {
            fetchCountriesUrl: `${clientURL}/static/countries`,
        },
        pointeuse: {
            fetchShiftsListUrl: `${clientURL}/shift/list`,
            fetchShiftsWeekUrl: `${clientURL}/shift-week/find`,
            validateShiftUrl: `${clientURL}/shift/validate`,
            addEmployeeToPointeuseUrl: `${clientURL}/shift/add-new-from-app`,
            updatePointageUrl: `${clientURL}/shift/edit`,
            updateShiftUrl: `${clientURL}/shift/update`,
            validateJourneyUrl: `${clientURL}/shift-week/validate/day`,
            fetchDepartementsUrl: `${clientURL}/planning-departments`,
            printShiftList: `${clientURL}/shift/print/list`,
        },
        vacation: {
            getAll: (siteID: any) => `${clientURL}/conge-types/${siteID}`,
            getOne: (idCongeType: any) =>
                `${clientURL}/conge-types/id/${idCongeType}`,
            create: `${clientURL}/conge-types/`,
            update: (idCongeType: any) =>
                `${clientURL}/conge-types/${idCongeType}`,
            delete: (idCongeType: any) =>
                `${clientURL}/conge-types/${idCongeType}`,
        },
        vacationRule: {
            getAll: `${clientURL}/rule-leave-entitlement/`,
            create: `${clientURL}/rule-leave-entitlement/`,
            update: (idRule: any) =>
                `${clientURL}/rule-leave-entitlement/${idRule}`,
            delete: (idRule: any) =>
                `${clientURL}/rule-leave-entitlement/${idRule}`,
            assignUsersToRule: (idRule: any) =>
                `${clientURL}/rule-leave-entitlement/assign/${idRule}`,
            getUsersByRule: (idRule: any) =>
                `${clientURL}/rule-leave-entitlement/get-users-by-rule/${idRule}`,
        },
        leaveBalance: {
            getAllHistoryBySite: (siteID: any) =>
                `${clientURL}/leave-balance/history/bySite/${siteID}`,
            updateAutoScoreBySite: (siteID: any) =>
                `${clientURL}/leave-balance/update-score/${siteID}`,
            correctVacationBalance: (siteID: any) =>
                `${clientURL}/leave-balance/force`,
            getAllHistoryByUserID: (userID: any) =>
                `${clientURL}/leave-balance/history/${userID}`,
        },
        contrat: {
            generate: (employeeID: any) =>
                `${clientURL}/contract-template/generate/${employeeID}`,
        },
        holiday: {
            getUserHolidays: `${clientURL}/conge/list-user-holidays`,
            getvalidatedHolidays: `${clientURL}/conge/get-valid-week-conges`,
            getHolidays: `${clientURL}/conge/list`,
            accept: `${clientURL}/conge/validate`,
            reject: `${clientURL}/conge/reject`,
            create: `${clientURL}/conge/create`,
            delete: `${clientURL}/conge/remove-mine`,
        },
    },
    admin: {},
};
