import React from "react";
import moment from "moment";

export const DepositDateColumnFormatter = (date: string) => {
    return (
        <span className="text-slate-500	 text-md font-light">
            {moment(date)?.format("DD/MM/YYYY")}
        </span>
    );
};
