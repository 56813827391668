import React from "react";
import Conges from "../../components/templates/Conges";
import { useBranch } from "../../hooks/useBranch";
import CongesTable from "./CongesTable";
import { useHoliday } from "../../hooks/useHolidays";
import VacationRequest from "./VacationRequest";
import CongesTraiteTable from "./CongesTraiteTable";
import CreateConge from "./CreateConge";
import { useEmployees } from "../../hooks/useEmployees";

export default function CongesPage() {
    const { selectedBranchSettings } = useBranch();
    const { getEmployees, employees } = useEmployees();
    const { processedHolidays, validHolidays, loading, error, getHolidays } =
        useHoliday();
    const [openVacationRequest, setOpenVacationRequest] = React.useState(false);
    const [openCreateConges, setOpenCreateConges] = React.useState(false);
    const [employee, setEmployee] = React.useState<any>(null);
    const currentUserInfo = sessionStorage.getItem("user");
    let parsedBranch:any
      if (currentUserInfo) {
           parsedBranch = JSON.parse(currentUserInfo);
      }
    React.useEffect(() => {
        if (selectedBranchSettings) {
            getHolidays(
                null,
                "0",
                null,
                null,
                selectedBranchSettings?.license 
                ? selectedBranchSettings.license 
                : parsedBranch?.license?._id 
                    ? parsedBranch.license?._id 
                    : null,
                selectedBranchSettings?._id
                    ? [selectedBranchSettings?._id]
                    : null,
            );
            getHolidays(
                null,
                "1,2",
                null,
                null,
                selectedBranchSettings?.license 
                ? selectedBranchSettings.license 
                : parsedBranch?.license?._id 
                  ? parsedBranch.license?._id 
                  : null,
                selectedBranchSettings?._id
                    ? [selectedBranchSettings?._id]
                    : null,
            );
        }
    }, [selectedBranchSettings?._id  ]);
   

    React.useEffect(() => {
        getEmployees({ archived: false });
    }, [selectedBranchSettings?._id]);

    const closePopUps = () => {
        setOpenVacationRequest(false);
    };
    const openPopUps = () => {
        setOpenVacationRequest(true);
    };
    const closeCreatePopUps = () => {
        setOpenCreateConges(false);
    };
    const handleOpenVacationRequest = () => {
        setOpenCreateConges(true);
    };
    const updateEmployeeValue = (value: any) => {
        setEmployee(value);
    };
 

    return (
        <>
            <VacationRequest
                display={openVacationRequest}
                onClose={closePopUps}
                employee={employee}
                licenseId={selectedBranchSettings?.license ? selectedBranchSettings?.license :   parsedBranch.license._id  }
                siteId={selectedBranchSettings?._id}
            />
            <CreateConge
                    onOpen={openCreateConges}
                    onClose={closeCreatePopUps}
                    employees={employees}
                    licenseId={selectedBranchSettings?.license ? selectedBranchSettings?.license :   parsedBranch.license._id  }
                      siteId={selectedBranchSettings?._id}

             />

            <Conges
                selectedBranch={selectedBranchSettings._id}
                openAddConge={handleOpenVacationRequest}
                processedCount={processedHolidays.length}
                ValidCount={validHolidays.length}
                FirstTabCompoenent={
                    <CongesTable
                        holidayLoading={loading}
                        userHolidays={validHolidays}
                        openVacationRequestDialog={openPopUps}
                        updateEmployeeValue={updateEmployeeValue}
                        openAddConge={handleOpenVacationRequest}
                    />
                }
                SecondTabCompoenent={
                    <CongesTraiteTable
                        holidayLoading={loading}
                        userHolidays={processedHolidays}
                        openAddConge={handleOpenVacationRequest}
                        updateEmployeeValue={updateEmployeeValue}
                        openVacationRequestDialog={openPopUps}
                    />
                }
            />
        </>
    );
}
