import React from "react";
import { ReactComponent as SyncSVG } from "./sync.svg";
import TextData from "../../components/atoms/TextData";
import Button from "../../components/atoms/Button";
import PointeuseSideOptions from "../../components/organism/PointeuseSideOptions";
import PointeuseTableHead from "../../components/organism/PointeuseTableHead";
import PointeuseDataBody from "../../components/organism/PointeuseDataBody";
import PointeuseTableFooter from "../../components/organism/PointeuseTableFooter";
import moment from "moment";
import { ReactComponent as FullScreenSVG } from "./fullscreen.svg";
import { ReactComponent as ExitFullScreenSVG } from "./exitfullscreen.svg";
import usePointeuse from "../../hooks/usePointeuse";
import { useBranch } from "../../hooks/useBranch";
import { useEmployees } from "../../hooks/useEmployees";
import { useLanguage } from "../../hooks/useLanguage";
import { useTimeTracker } from "../../hooks/useTimeTracker";
import { calculateTimePeriod } from "../../_helpers/pointeuseFunctions";
import Loading from "../../components/atoms/Loading";
import { ReactComponent as DownloadSVG } from "./download.svg";

export default function Pointeuse() {
    const [isAllChecked, setIsAllChecked] = React.useState(false);
    const [isCollapse, setIsCollapse] = React.useState(true);

    const {
        lastSync,
        pointeuseFilterBy,
        getShifList,
        setFilterBy,
        shifts,
        actionLoading,
        listLoading,
        validateShift,
        addEmployeeToPointeuse,
        pointeuseEmployees,
        updateShift,
        validateJourney,
        getShiftWeek,
        weekDetails,
        month,
        setMonth,
        getMonthDetails,
        filters,
        onUpdateFilters,
        fetchDepartements,
        departements,
        restPointeuseState,
        filteredDataByDepartments,
        handleSetEmployeesPointeuse,
        printPointage,
    } = usePointeuse();
    const {
        getEmployees,
        employees,
        filteredFromAllSites,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
    } = useEmployees();

    const { selectedBranchSettings, getSiteSettings } = useBranch();
    const [numberOfEmps, setEmpShiftsRowsNumber] = React.useState(0);
    React.useEffect(() => {
        getSiteSettings();
        if (employees?.length === 0) {
            getEmployees({ archived: false });
        }
        if (selectedBranchSettings && selectedBranchSettings._id) {
            getPrimesTypes({ siteId: selectedBranchSettings._id });

            onUpdateFilterBy({
                ...filterBy,
                name: "",
                niveau: [],
                role: [],
                site: selectedBranchSettings._id
                    ? selectedBranchSettings._id.toString()
                    : undefined,
            });
        }
    }, [selectedBranchSettings]);
    const [allowLoading, setAllowLoading] = React.useState(true);
    React.useEffect(() => {
        if (filteredFromAllSites?.length) {
            handleSetEmployeesPointeuse(filteredFromAllSites);
        }
    }, [filteredFromAllSites]);
    const { timeTracker, getTimeTracker } = useTimeTracker();
    const [selectedDay, setSelectedDay] = React.useState(
        moment().format("YYYY-MM-DD"),
    );
    const onValidateJourney = async ({ validate, lock }: any) => {
        const dayNumber = moment(selectedDay).day()
            ? moment(selectedDay).day() - 1
            : 6;
        const year = moment(selectedDay).year();
        const weekNumber = moment(selectedDay).week();
        let res: any = await validateJourney({
            siteId: selectedBranchSettings._id,
            dayNumber: dayNumber,
            year: year,
            weekNumber: weekNumber,
            validated: validate,
            locked: lock,
        });
        if (res?.payload?.status === "success") {
            await getShiftWeek({
                weekNumber: moment(pointeuseFilterBy.from).week(),
                year: moment(pointeuseFilterBy.from).year(),
                siteId: selectedBranchSettings._id,
            });
        }
    };
    const handleValidateShift = async (id: any) => {
        let res: any = await validateShift(id);
        if (res?.payload?.status === "success") {
            await getShifList({
                from: moment(pointeuseFilterBy.from).format("YYYY-MM-DD"),
                to: moment(pointeuseFilterBy.from).format("YYYY-MM-DD"),
                siteId: selectedBranchSettings._id,
                licenseId: selectedBranchSettings.license,
            });
        }
    };
    React.useEffect(() => {
        const fetchData = async () => {
            await getShifList({
                from: moment(pointeuseFilterBy.from).format("YYYY-MM-DD"),
                to: moment(pointeuseFilterBy.from).format("YYYY-MM-DD"),
                siteId: selectedBranchSettings._id,
                licenseId: selectedBranchSettings.license,
            });
            await getShiftWeek({
                weekNumber: moment(pointeuseFilterBy.from).week(),
                year: moment(pointeuseFilterBy.from).year(),
                siteId: selectedBranchSettings._id,
            });
            setAllowLoading(false);
        };
        fetchData();
    }, [pointeuseFilterBy, selectedBranchSettings]);
    React.useEffect(() => {
        if (selectedDay) {
            let day = moment(selectedDay, "YYYY-MM-DD");
            setFilterBy({
                from: day,
                to: day,
            });
        }
    }, [selectedDay]);
    React.useEffect(() => {
        if (selectedBranchSettings && selectedBranchSettings._id) {
            getTimeTracker();
        }
    }, [selectedBranchSettings]);

    React.useEffect(() => {
        getMonthDetails({
            from: moment(month.from).format("YYYY-MM-DD"),
            to: moment(month.to).format("YYYY-MM-DD"),
            siteId: selectedBranchSettings._id,
        });
    }, [month]);

    React.useEffect(() => {
        if (selectedBranchSettings && selectedBranchSettings._id) {
            fetchDepartements({ siteId: selectedBranchSettings._id });
        }
    }, [selectedBranchSettings]);
    const { switchFullScreen, isFullScreen } = useLanguage();

    const toggleFullScreen = () => {
        switchFullScreen();
    };
    const [validated, setValidated] = React.useState(false);
    const [locked, setLocked] = React.useState(false);
    React.useEffect(() => {
        const validatetheWeek = async (isShiftWeekValid: boolean) => {
            if (weekDetails) {
                const { site, year, weekNumber } = weekDetails;
                try {
                    await fetch("https://api.esperoo.fr/v1/shift-week/new", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            siteId: site,
                            weekNumber: String(weekNumber),
                            year,
                            isShiftWeekValid,
                        }),
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        };
        if (weekDetails) {
            let found = false;
            let dayNumber = (moment(selectedDay).day() + 6) % 7;
            for (let day of weekDetails?.days) {
                if (day.number === dayNumber) {
                    setValidated(day.validated);
                    setLocked(day?.locked);
                    found = true;
                    break;
                }
            }
            if (!found) {
                setValidated(false);
                setLocked(false);
            }
            if (
                weekDetails?.days?.filter((day: any) => day?.locked).length ===
                7
            ) {
                //validate the week
                validatetheWeek(true);
            } else {
                //unvalidate the week
                validatetheWeek(false);
            }
        }
    }, [weekDetails, selectedDay]);
    React.useEffect(() => {
        return () => {
            restPointeuseState();
        };
    }, []);
    //filter data
    const { departments, data, setDepartments, setFilteredByDataDepartments } =
        usePointeuse();
    const [departmentIds, setDepartmentsIds] = React.useState<String[]>([]);
    React.useEffect(() => {
        if (selectedDay) {
            setDepartmentsIds([]);
        }
    }, [selectedDay]);
    React.useEffect(() => {
        if (departments.length > 0) {
            let copyDepartments = [...departments];
            for (let i = 0; i < copyDepartments.length; i++) {
                let count = 0;
                for (let emp of copyDepartments[i].employees) {
                    if (data[emp]) {
                        count++;
                    }
                }
                copyDepartments[i] = { ...copyDepartments[i], pointage: count };
            }
            setDepartments(copyDepartments);
        } else {
            setDepartments([]);
        }
    }, [data]);
    React.useEffect(() => {
        let filteredByDataDepartments: { [key: string]: any } = {};
        let uniqueEmployees: any;

        if (Object.keys(data).length <= 0) {
            filteredByDataDepartments = { ...data };
        } else {
            if (
                departmentIds.length === 0 ||
                departmentIds.length === departments.length
            ) {
                filteredByDataDepartments = { ...data };
            } else {
                let listOfEmployees: string[] = [];

                for (let i = 0; i < departments.length; i++) {
                    if (departmentIds.includes(departments[i]._id)) {
                        listOfEmployees = [
                            ...listOfEmployees,
                            ...departments[i].employees,
                        ];
                    }
                }
                uniqueEmployees = Array.from(new Set(listOfEmployees));

                for (let i = 0; i < uniqueEmployees.length; i++) {
                    let id: string = uniqueEmployees[i];
                    if (data[id]) {
                        filteredByDataDepartments[id] = data[id];
                    }
                }
            }
        }
        setFilteredByDataDepartments(filteredByDataDepartments);
    }, [departmentIds, data, departments]);
    const [loadPrint, setLoadPrint] = React.useState(false);
    if (!selectedBranchSettings._id) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "rgb(173 235 255)",
                    borderRadius: "8px",
                    color: "#4c5690",
                }}
            >
                Veuillez sélectionner un établissement
            </div>
        );
    }
    return (
        <div
            // ref={targetRef}
            style={{
                backgroundColor: "#f7f8fa",
                padding: isFullScreen ? "20px" : "",
                transition: "0.3s ease-in-out",
                height: "calc(100vh - 200px)",
            }}
        >
            <div
                className="pointeuse-header"
                style={{
                    display: "flex",
                    width: "auto",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "80px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                    }}
                >
                    <TextData
                        color="#11106E"
                        size="34"
                        fontWeight="bold"
                        style={{
                            paddingLeft: "1.25rem",
                        }}
                    >
                        Pointage
                    </TextData>
                    <TextData
                        className={["fullscreen-btn"]}
                        onClick={toggleFullScreen}
                    >
                        <div className="fullscreen-icon">
                            {isFullScreen ? (
                                <ExitFullScreenSVG
                                    height={18}
                                    width={18}
                                    color="white"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                            ) : (
                                <FullScreenSVG
                                    height={18}
                                    width={18}
                                    color="white"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                            )}
                        </div>
                        <div className="fullscreen-title">
                            <div>
                                {!isFullScreen
                                    ? "Mode plein écran"
                                    : "Quitter le mode plein écran"}
                            </div>
                        </div>
                    </TextData>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 5,
                        fontSize: "16px",
                        marginLeft: "auto",
                        marginRight: "10px",
                    }}
                >
                    <SyncSVG color="#2d2aad" height={20} width={20} />
                    {lastSync ? (
                        <TextData color="#2d2aad">
                            Dernière synchronisation avec la tablette il y a{" "}
                            <b>{calculateTimePeriod(lastSync)}</b>
                        </TextData>
                    ) : (
                        <div>...</div>
                    )}
                </div>
                <Button
                    textColor="white"
                    backgroundColor="#2A8BAB"
                    style={{
                        fontSize: "16px",
                        gap: "0.25rem",
                        // alignSelf: "flex-end",
                        // marginTop: "1rem",
                        // marginBottom: "0.5rem",
                        border: "1px solid #2A8BAB",
                    }}
                    disabled={loadPrint}
                    width="auto"
                    padding="0.75rem 20px"
                    height="50px"
                    onClick={async () => {
                        if (!loadPrint) {
                            setLoadPrint(true);

                            await printPointage({
                                from: moment(pointeuseFilterBy.from).format(
                                    "YYYY-MM-DD",
                                ),
                                to: moment(pointeuseFilterBy.to).format(
                                    "YYYY-MM-DD",
                                ),
                            });
                            setLoadPrint(false);
                        }
                    }}
                >
                    {!loadPrint && <DownloadSVG />}
                    {loadPrint
                        ? "Téléchargement en cours..."
                        : "Rapport des pointages"}
                </Button>
            </div>
            <div
                className="pointeuse-body"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                    maxHeight: `calc(100vh - ${isFullScreen ? 110 : 175}px)`,
                    height: `calc(100vh - ${isFullScreen ? 110 : 175}px)`,
                    transition: "0.3s ease-in-out",
                    width: "100%",
                    overflow: "hidden",
                }}
            >
                <div
                    className="data-section"
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        backgroundColor: "white",
                        height: "100%",
                        width:
                            departements?.length > 0 && isCollapse
                                ? "calc(100% - 60px)"
                                : departements?.length > 0 && !isCollapse
                                  ? "calc(100% - 230px)"
                                  : "100%",
                        overflow: !locked ? "auto" : "hidden",
                        // width: 100%;
                        // border-top-left-radius: 12px;
                        // border-bottom-left-radius: 12px;
                    }}
                >
                    <table className="pointeuse-table">
                        <PointeuseTableHead
                            isAllChecked={isAllChecked}
                            setIsAllChecked={setIsAllChecked}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            onValidateJourney={onValidateJourney}
                            photoAllowed={timeTracker.takePhoto}
                            validated={validated}
                            locked={locked}
                            numberOfEmps={numberOfEmps}
                        />
                        {listLoading && allowLoading ? (
                            <tr
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "calc(100vh - 401px)",
                                    backgroundColor: "#FFF",
                                }}
                            >
                                <th colSpan={8}>
                                    <Loading width="400px" height="200px" />
                                </th>
                            </tr>
                        ) : (
                            <PointeuseDataBody
                                isAllChecked={isAllChecked}
                                selectedDay={selectedDay}
                                onValidateJourney={onValidateJourney}
                                handleValidateShift={handleValidateShift}
                                photoAllowed={timeTracker.takePhoto}
                                validated={validated}
                                locked={locked}
                                setEmpShiftsRowsNumber={setEmpShiftsRowsNumber}
                            />
                        )}
                    </table>
                </div>
                {departements?.length > 0 && (
                    <PointeuseSideOptions
                        isCollapse={isCollapse}
                        setIsCollapse={setIsCollapse}
                        selectedDay={selectedDay}
                        departmentIds={departmentIds}
                        setDepartmentsIds={setDepartmentsIds}
                    />
                )}
            </div>
        </div>
    );
}
