import * as React from "react";
import TextData from "../../../atoms/TextData";
import EmployeeInfoHeaderRow from "../../../molecules/EmployeeInfoHeaderRow";
import SelectInput from "../../../atoms/SelectInput";
import CalendarInput from "../../../molecules/CalendarInput";
import Button from "../../../atoms/Button";
import Table from "../../Table";
import { useReport } from "../../../../hooks/useReport";
import { useBranch } from "../../../../hooks/useBranch";
import { useEmployees } from "../../../../hooks/useEmployees";
import { useUser } from "../../../../hooks/useUsers";
import moment from "moment";
import {
    capitalize,
    extractNumber,
    formatDate,
    employeeRoleColor,
} from "../../../../_helpers/Functions";

import { ROUTER } from "../../../../constants/env";
import "./style.scss";
import WarningPanel from "../../../atoms/WarningPanel";
import { relative } from "path";
import DatepickerInput from "../../../atoms/DatepickerInput";
import Swal from "sweetalert2";

function PayrollReport() {
    const { report, getMonthlyWorkHours, handleSetPayRollDataLength } =
        useReport();
    const { selectedBranchSettings, getBranchSettings } = useBranch();
    const {
        getEmployees,
        employees,
        allActiveEmployees,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
    } = useEmployees();

    // [, selectedBranchSettings?._id]
    const acompteFields = () => {
        if (report.acomptesTotal?.length > 0) {
            return report.acomptesTotal.map((acompte: any) => {
                return {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                            id={acompte.name}
                            key={acompte.name}
                            style={{ padding: "0 7px" }}
                        >
                            {acompte.name}
                        </TextData>
                    ),
                    name: acompte.name,
                    style: {
                        padding: "10px 0",
                        width: "12%",
                        textAlign: "center" as "center",
                        backgroundColor: "#ff66331a",
                        borderRight: "1px solid #ff6633",
                        borderLeft: "1px solid #ff6633",
                        // border: "1px solid #ff6633",
                        // borderTop: "none",
                        // borderBottom: "none",
                    },
                };
            });
        } else {
            return [];
        }
    };
    const loadPayrollData = () => {
        let res: any = [];
        let rowIndex = -1;
        if (report.selectedEmployee === null) {
            if (report.employeesData) {
                res = allActiveEmployees
                    ?.map((emp: any) => {
                        if (!report.employeesData[emp._id]) {
                            return null;
                        }
                        let employee = report.employeesData[emp._id];
                        const acompteData = employee.acomptes.map(
                            (acompte: any) => {
                                return {
                                    value: acompte.amount,
                                    name: acompte.name,
                                    style: {
                                        color: "#4C5690",
                                        height: "80px",
                                        textAlign: "center" as "center",

                                        borderRight: "1px solid #ff6633",
                                        borderLeft: "1px solid #ff6633",
                                        // border: "1px solid #ff6633",
                                        // borderTop: "none",
                                        // borderBottom: "none",
                                    },
                                };
                            },
                        );
                        const totalPay = [];
                        if (employee.baseSalary !== undefined) {
                            totalPay.push({
                                value: employee.baseSalary,
                                name: "baseSalary",
                                style: {
                                    color: "#4C5690",
                                    height: "80px",
                                    textAlign: "center" as "center",
                                    borderRight: "1px solid #651EFF",
                                    borderLeft: "1px solid #651EFF",
                                    // border: "1px solid #651EFF",
                                    // borderTop: "none",
                                    // borderBottom: "none",
                                },
                            });
                        }
                        if (employee.netSalary !== undefined) {
                            totalPay.push({
                                value: employee.netSalary,
                                name: "netSalary",
                                style: {
                                    color: "#4C5690",
                                    fontSize: 14,
                                    textAlign: "center" as "center",
                                    borderRight: "1px solid #651EFF",
                                    borderLeft: "1px solid #651EFF",
                                    // border: "1px solid #651EFF",
                                    // borderTop: "none",
                                    // borderBottom: "none",
                                },
                            });
                        }
                        if (employee.salary !== undefined) {
                            totalPay.push({
                                value: employee.salary,
                                name: "salary",
                                style: {
                                    color: "#4C5690",
                                    fontSize: 14,
                                    textAlign: "center" as "center",
                                    borderLeft: "1px solid #651EFF",
                                },
                            });
                        }
                        if (
                            employee.baseSalary == 0 &&
                            employee.netSalary == 0 &&
                            employee.salary == 0 &&
                            employee.totalWorkedDays == 0
                        ) {
                            return null;
                        } else {
                            rowIndex++;
                            return {
                                dataRow: [
                                    {
                                        //             <EmployeeInfoHeaderRow
                                        //     firstName={employee?.fname}
                                        //     lastName={employee?.lname}
                                        //     position={employee?.role}
                                        //     dotColor={employeeRoleColor(employee?.role)}
                                        //     weekHours={employee?.contractHours + " h"}
                                        //     profileImage={employee?.picture}
                                        // />
                                        value: (
                                            <EmployeeInfoHeaderRow
                                                firstName={employee.fname}
                                                lastName={employee.lname}
                                                position={employee.role}
                                                profileImage={employee.picture}
                                                dotColor={employeeRoleColor(
                                                    employee?.role,
                                                )}
                                                weekHours={
                                                    employee.contractHours +
                                                    " h"
                                                }
                                                imageStyle={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                        ),
                                        name: "phone",
                                    },
                                    ...acompteData,
                                    {
                                        value: employee.totalWorkedDays,
                                        name: "workedDays",
                                        style: {
                                            color: "#4C5690",
                                            height: "80px",
                                            textAlign: "center" as "center",
                                            borderRight: "1px solid #23ECA4",
                                            borderLeft: "1px solid #23ECA4",
                                            // border: "1px solid #23ECA4",
                                            // borderTop: "none",
                                            // borderBottom: "none",
                                        },
                                    },
                                    ...totalPay,
                                ],
                                onClickRow: () => {},
                                style: {
                                    cursor: "pointer",
                                    borderBottom: "1px solid #EDEFF5",
                                },
                                className: ["hover:bg-slate-100"],
                            };
                        }
                    })
                    .filter((elem) => elem);
            }
        } else {
            const emp = Object(report.employeesData)?.values?.find(
                (emp: any) => emp.id === report.selectedEmployee.id,
            );
            if (emp) {
                const acompteData = emp.acomptes.map((acompte: any) => {
                    rowIndex++;
                    return {
                        value: acompte.amount,
                        name: acompte.name,
                        style: {
                            color: "#4C5690",
                            height: "80px",
                            textAlign: "center" as "center",

                            borderRight: "1px solid #ff6633",
                            borderLeft: "1px solid #ff6633",
                            // border: "1px solid #ff6633",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    };
                });

                const totalPay = [];
                if (emp.baseSalary !== undefined) {
                    totalPay.push({
                        value: emp.baseSalary,
                        name: "baseSalary",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                            textAlign: "center" as "center",
                            borderRight: "1px solid #651EFF",
                            borderLeft: "1px solid #651EFF",
                            // border: "1px solid #651EFF",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    });
                }
                if (emp.netSalary !== undefined) {
                    totalPay.push({
                        value: emp.netSalary,
                        name: "netSalary",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                            textAlign: "center" as "center",
                            borderRight: "1px solid #651EFF",
                            borderLeft: "1px solid #651EFF",
                            // border: "1px solid #651EFF",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    });
                }
                if (emp.salary !== undefined) {
                    totalPay.push({
                        value: emp.salary,
                        name: "salary",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                            textAlign: "center" as "center",
                            borderLeft: "1px solid #651EFF",
                        },
                    });
                }

                const dataRow = [
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName={emp.fname}
                                lastName={emp.lname}
                                position={emp.role}
                                profileImage={ROUTER.STATIC_IMG(emp.picture)}
                                weekHours={emp.contractHours}
                                imageStyle={{ marginRight: "10px" }}
                            />
                        ),
                        name: "phone",
                    },
                    ...acompteData,
                    {
                        value: emp.totalWorkedDays,
                        name: "workedDays",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                            textAlign: "center" as "center",
                            borderRight: "1px solid #23ECA4",
                            borderLeft: "1px solid #23ECA4",
                            // border: "1px solid #23ECA4",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    },
                    ...totalPay,
                ];
                res = [
                    {
                        dataRow: dataRow,
                        onClickRow: () => {},
                        style: {
                            cursor: "pointer",
                            borderBottom: "1px solid #EDEFF5",
                        },
                        className: ["hover:bg-slate-100"],
                    },
                ];
            }
        }
        handleSetPayRollDataLength(rowIndex + 1);

        return res;
    };
    const totalAcomptesValues = () => {
        if (report.acomptesTotal?.length > 0) {
            const res = report.acomptesTotal.map((acompte: any) => {
                return {
                    value: acompte.totalAmount,
                    name: acompte.name,
                    style: {
                        color: "#101844",
                        height: "40px",
                        textAlign: "center" as "center",
                        borderRight: "1px solid #ff6633",
                        borderLeft: "1px solid #ff6633",
                        backgroundColor: "#ff66331a",
                        // border: "1px solid #ff6633",
                        // borderTop: "none",
                        // borderBottom: "none",
                    },
                };
            });
            return res;
        } else {
            return [];
        }
    };
    const PayrollTableProps = {
        fields: {
            fieldRow: [
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Employé ({report?.dataLength?.payRoll})
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        paddingLeft: "15px",
                        borderTop: "none",
                        width: "16%",
                    },
                    titleContainerStyle: {
                        height: "70px",
                        display: "flex",
                        alignItems: "center",
                    },
                },
                ...acompteFields(),
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Jours travaillés
                        </TextData>
                    ),
                    name: "workedDays",
                    style: {
                        padding: "10px 0",
                        width: "12%",
                        textAlign: "center" as "center",
                        backgroundColor: "#23ECA41a",

                        borderRight: "1px solid #23ECA4",
                        borderLeft: "1px solid #23ECA4",
                        // border: "1px solid #23ECA4",
                        // borderTop: "none",
                        // borderBottom: "none",
                    },
                },
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Salaire de base
                        </TextData>
                    ),
                    name: "baseSalary",
                    style: {
                        padding: "10px 0",
                        width: "12%",
                        textAlign: "center" as "center",
                        backgroundColor: "#8A55FF1a",

                        borderRight: "1px solid #8A55FF",
                        borderLeft: "1px solid #8A55FF",
                        // border: "1px solid #8A55FF",
                        // borderTop: "none",
                        // borderBottom: "none",
                    },
                },
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                        >
                            Salaire net
                        </TextData>
                    ),
                    name: "netSalary",
                    style: {
                        padding: "10px 0",
                        width: "12%",
                        textAlign: "center" as "center",
                        backgroundColor: "#8A55FF1a",

                        borderRight: "1px solid #8A55FF",
                        borderLeft: "1px solid #8A55FF",
                        // border: "1px solid #8A55FF",
                        // borderTop: "none",
                        // borderBottom: "none",
                    },
                },
                {
                    title: (
                        <TextData
                            variant="label"
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                            style={{ height: "40px" }}
                        >
                            Net à payer
                        </TextData>
                    ),
                    name: "salary",
                    style: {
                        padding: "10px 0",
                        width: "12%",
                        textAlign: "center" as "center",
                        backgroundColor: "#8A55FF1a",
                        borderLeft: "1px solid #8A55FF",
                    },
                },
            ],
            onClickRow: () => {},
            style: {
                border: "1px solid #EDEFF5",
                borderTop: "none",
                position: "sticky" as "sticky",
                top: 0,
                backgroundColor: "white",
            },
            className: [],
        },
        data: [
            ...loadPayrollData(),
            {
                dataRow: [
                    {
                        value: "Total",
                        name: "phone",
                        style: {
                            textAlign: "right" as "right",
                            paddingRight: "20px",
                            color: "#101844",
                        },
                    },
                    ...totalAcomptesValues(),
                    {
                        // value: report.totalWorkedDays,
                        value: "",
                        name: "totalWorkedDays",
                        style: {
                            color: "#101844",
                            textAlign: "center" as "center",
                            backgroundColor: "#23ECA41a",
                            borderRight: "1px solid #23ECA4",
                            borderLeft: "1px solid #23ECA4",
                            // border: "1px solid #23ECA4",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    },
                    {
                        value: report.totalBaseSalary,
                        name: "baseSalary",
                        style: {
                            color: "#101844",
                            textAlign: "center" as "center",
                            backgroundColor: "#8A55FF1a",

                            borderRight: "1px solid #651EFF",
                            borderLeft: "1px solid #651EFF",
                            // border: "1px solid #651EFF",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    },
                    {
                        value: report.totalNetSalary.toFixed(2),
                        name: "netSalary",
                        style: {
                            color: "#101844",
                            fontSize: 14,
                            textAlign: "center" as "center",
                            backgroundColor: "#8A55FF1a",

                            borderRight: "1px solid #651EFF",
                            borderLeft: "1px solid #651EFF",
                            // border: "1px solid #651EFF",
                            // borderTop: "none",
                            // borderBottom: "none",
                        },
                    },
                    {
                        value: report.totalSalary.toFixed(2),
                        name: "salary",
                        style: {
                            color: "#101844",
                            fontSize: 14,
                            textAlign: "center" as "center",
                            borderLeft: "1px solid #651EFF",
                            backgroundColor: "#8A55FF1a",
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                    position: "sticky" as "sticky",
                    bottom: 0,
                    backgroundColor: "white",
                },
                className: ["hover:bg-slate-100"],
            },
        ],
    };

    if (report.year && report.month) {
        return (
            <div className="payroll-table-container">
                <Table {...PayrollTableProps} />
            </div>
        );
    } else {
        return (
            <div style={{ position: "relative" }}>
                <WarningPanel
                    title="Attention"
                    description="Veuillez sélectionner le mois et l'année pour afficher les données correspondantes."
                />
            </div>
        );
    }
}

function PayrollReportSidebar() {
    const { users, getUsersList } = useUser();
    const {
        setMonth,
        setYear,
        setSelectedEmployee,
        report,
        selectedBranchs,
        setReportRange,
        getMonthlyWorkHours,
        setHideRemainingContractHours,
        setHideExtraContractHours,
        setShowOnlyCurrentMonthHours,
    } = useReport();
    const { selectedBranchSettings } = useBranch();
    const formatEmployeeDisplay = (user: any) =>
        `${user.fname} ${user.lname}`.trim();
    const [selectedEmp, setSelectedEmp] = React.useState(() => {
        if (report.selectedEmployee) {
            return {
                label: formatEmployeeDisplay(report.selectedEmployee),
                value: formatEmployeeDisplay(report.selectedEmployee),
            };
        } else {
            return {
                label: "Selectionner un employé",
                value: "Selectionner un employé",
            };
        }
    });

    const currentYear = moment().year();
    const currentBranchSettings = JSON.parse(
        sessionStorage.getItem("selectedBranch") || `{"created_at":"2018"}`,
    );
    const startYear = moment(currentBranchSettings.created_at).year();

    const years = [{ label: "Année", value: "Année" }];
    for (let year = currentYear; year >= startYear; year--) {
        years.push({ label: String(year), value: String(year) });
    }

    const months = [
        { label: "Mois", value: "Mois" },
        ...moment.months().map((month) => ({
            label: capitalize(month),
            value: capitalize(month),
        })),
    ];

    const listUsers = users.map((user: any) => formatEmployeeDisplay(user));
    const employees = [
        { label: "Selectionner un employé", value: "Selectionner un employé" },
        ...listUsers.map((user: any) => ({ label: user, value: user })),
    ];

    const handleSelectYear = (option: any) => {
        const selectedYear = option.value;
        if (extractNumber(selectedYear) === 0) {
            setYear(null);
            return;
        }
        setYear(selectedYear);
    };

    const handleSelectMonth = (selectedOption: any) => {
        // const selectedOption = e.options[e.selectedIndex];
        const selectedMonth = capitalize(
            moment
                .months()
                .find((month) => capitalize(month) === selectedOption.value) ??
                "",
        );
        if (selectedMonth === undefined) {
            setMonth(null);
            return;
        }
        //TODO: fix this
        const monthIndex = months.findIndex(
            (month: any) => month.value === selectedMonth,
        );
        const formattedMonth = String(monthIndex).padStart(2, "0");
        setMonth(formattedMonth);
    };

    const handleSelectEmployee = (selectedOption: any) => {
        // const selectedOption = e.options[e.selectedIndex];
        const selectedUser = users.find((user: any) => {
            return formatEmployeeDisplay(user) === selectedOption.value;
        });
        if (selectedUser === undefined) {
            setSelectedEmployee(null);
            return;
        }
        setSelectedEmployee(selectedUser);
    };
    const [reportRangeType, setReportRangeType] = React.useState("month");
    const handleGetPayrollData = ({
        start,
        end,
    }: {
        start?: any;
        end?: any;
    }) => {
        let monthInNum = moment().month() - 1;
        let yearInNum = moment().year();
        if (report.month) {
            monthInNum = Number(report.month) - 1;
        }
        if (report.year) {
            yearInNum = Number(report.year);
        }

        let startDate =
            start ??
            report.workHoursDateRange.startDate ??
            moment()
                .year(yearInNum)
                .month(monthInNum)
                .startOf("month")
                .format("YYYY-MM-DD");
        let endDate =
            end ??
            report.workHoursDateRange.endDate ??
            moment()
                .year(yearInNum)
                .month(monthInNum)
                .endOf("month")
                .format("YYYY-MM-DD");

        if (
            startDate !== report.workHoursDateRange.startDate ||
            endDate !== report.workHoursDateRange.endDate
        ) {
            setReportRange({
                startDate,
                endDate,
            });
        }
        getMonthlyWorkHours({
            startDate,
            endDate,
            showOnlyThisMonthRapport: true,
        });
    };
    const handleGetWorkedHoursDataInMonth = () => {
        let monthInNum = moment().month() - 1;
        let yearInNum = moment().year();
        if (report.month) {
            monthInNum = Number(report.month) - 1;
        }
        if (report.year) {
            yearInNum = Number(report.year);
        }
        let startDate, endDate;

        startDate = moment()
            .year(yearInNum)
            .month(monthInNum)
            .startOf("month")
            .format("YYYY-MM-DD");
        endDate = moment()
            .year(yearInNum)
            .month(monthInNum)
            .endOf("month")
            .format("YYYY-MM-DD");

        if (
            startDate !== report.workHoursDateRange.startDate ||
            endDate !== report.workHoursDateRange.endDate
        ) {
            setReportRange({
                startDate,
                endDate,
            });
        }
        getMonthlyWorkHours({
            startDate,
            endDate,
            showOnlyThisMonthRapport: true,
        });
        //send req 7asb l option
    };
    const handleGetWorkedHoursDataInPeriod = ({
        start,
        end,
    }: {
        start?: any;
        end?: any;
    }) => {
        let monthInNum = moment().month() - 1;
        let yearInNum = moment().year();
        if (report.month) {
            monthInNum = Number(report.month) - 1;
        }
        if (report.year) {
            yearInNum = Number(report.year);
        }

        let startDate =
            start ??
            report.workHoursDateRange.startDate ??
            moment()
                .year(yearInNum)
                .month(monthInNum)
                .startOf("month")
                .format("YYYY-MM-DD");
        let endDate =
            end ??
            report.workHoursDateRange.endDate ??
            moment()
                .year(yearInNum)
                .month(monthInNum)
                .endOf("month")
                .format("YYYY-MM-DD");

        if (
            startDate !== report.workHoursDateRange.startDate ||
            endDate !== report.workHoursDateRange.endDate
        ) {
            setReportRange({
                startDate,
                endDate,
            });
        }
        getMonthlyWorkHours({
            startDate,
            endDate,
            showOnlyThisMonthRapport: true,
        });
    };
    React.useEffect(() => {
        if (
            (report.month &&
                report.year &&
                selectedBranchSettings?._id &&
                report.showOnlyCurrentMonthHours !== undefined &&
                report.showWorkedHoursEverywhere !== undefined) ||
            (report.month && report.year && selectedBranchs?.length > 0)
        ) {
            if (reportRangeType === "month") {
                handleGetWorkedHoursDataInMonth();
            } else {
                handleGetWorkedHoursDataInPeriod({});
            }
        }
    }, [
        selectedBranchSettings?._id,
        report.month,
        report.year,
        report.showOnlyCurrentMonthHours,
        report.showWorkedHoursEverywhere,
        reportRangeType,
        selectedBranchs,
    ]);
    // React.useEffect(() => {
    //     if (
    //         selectedBranchSettings?._id &&
    //         report.month &&
    //         report.year &&
    //         // selectedBranchs?.length > 0 &&
    //         report.showOnlyCurrentMonthHours !== undefined &&
    //         report.showWorkedHoursEverywhere !== undefined
    //     ) {
    //         handleGetPayrollData({});
    //     }
    // }, [
    //     selectedBranchSettings?._id,
    //     report.month,
    //     report.year,
    //     report.showOnlyCurrentMonthHours,
    //     report.showWorkedHoursEverywhere,
    //     reportRangeType,
    //     selectedBranchs,
    // ]);

    const startDateField = {
        name: "startDate",
        value: report.workHoursDateRange.startDate,
    };
    const endDateField = {
        name: "endDate",
        value: report.workHoursDateRange.endDate,
    };
    const handleStartDateChange = (value: any) => {
        const startDate = new Date(value);

        // Check if endDate is after startDate
        if (startDate <= new Date(report.workHoursDateRange.endDate)) {
            setReportRange({ startDate: formatDate(startDate) });
            handleGetPayrollData({ start: formatDate(startDate) });
        } else {
            Swal.fire({
                title: "Erreur!",
                text: "La date de début doit être antérieure à la date de fin!",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };
    const handleEndDateChange = (value: any) => {
        const endDate = new Date(value);

        // Check if endDate is after startDate
        if (endDate >= new Date(report.workHoursDateRange.startDate)) {
            setReportRange({ endDate: formatDate(endDate) });
            handleGetPayrollData({ end: formatDate(endDate) });
        } else {
            Swal.fire({
                title: "Erreur!",
                text: "La date de fin doit être postérieure à la date de début!",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "445px",
                gap: "10px",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {reportRangeType === "month" ? (
                    <>
                        <SelectInput
                            title=""
                            options={years}
                            selectedValue={{
                                label: report.year,
                                value: report.year,
                            }}
                            onSelectOption={handleSelectYear}
                            style={{
                                // padding: "0 10px",
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        ></SelectInput>
                        <SelectInput
                            title=""
                            options={months}
                            selectedValue={months[extractNumber(report.month)]}
                            onSelectOption={handleSelectMonth}
                            style={{
                                // padding: "0 10px",
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        ></SelectInput>
                    </>
                ) : (
                    <>
                        <DatepickerInput
                            required={false}
                            field={startDateField}
                            onUpdate={handleStartDateChange}
                            // side="left"
                            style={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        />
                        <DatepickerInput
                            required={false}
                            field={endDateField}
                            onUpdate={handleEndDateChange}
                            // side="left"
                            style={{
                                height: "40px",
                                width: "130px",
                                borderRadius: "9px",
                                fontSize: "12px",
                            }}
                        />
                    </>
                )}
            </div>
            <div
                className="view-buttons"
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Button
                    padding="8px 16px"
                    width="80px"
                    textColor={
                        reportRangeType === "period" ? "white" : "#6f78ab"
                    }
                    backgroundColor={
                        reportRangeType === "period" ? "#6f78ab" : "white"
                    }
                    height="32px"
                    style={{
                        border: "0.5px solid #6f78ab",
                        borderRight: "0px none",
                        borderRadius: " 4px 0px 0px 4px",
                    }}
                    onClick={() => setReportRangeType("period")}
                >
                    <TextData
                        color={
                            reportRangeType === "period" ? "white" : "#6f78ab"
                        }
                        size="12px"
                        fontWeight="500"
                        width="auto"
                    >
                        Période
                    </TextData>
                </Button>
                <Button
                    padding="8px 16px"
                    width="80px"
                    textColor={
                        reportRangeType === "month" ? "white" : "#6f78ab"
                    }
                    backgroundColor={
                        reportRangeType === "month" ? "#6f78ab" : "white"
                    }
                    height="32px"
                    style={{
                        border: "0.5px solid #6f78ab",
                        borderRight: "1ps solid red",
                        borderRadius: "0px 4px 4px 0px",
                    }}
                    onClick={() => setReportRangeType("month")}
                >
                    <TextData
                        color={
                            reportRangeType === "month" ? "white" : "#6f78ab"
                        }
                        size="12px"
                        fontWeight="500"
                        width="auto"
                    >
                        Mois
                    </TextData>
                </Button>
            </div>
            {/* <SelectInput
        title=""
        options={employees}
        selectedValue={selectedEmp}
        onSelectOption={handleSelectEmployee}
        style={{
          width: "auto",
          height: "30px",
          marginRight: "10px",
          borderRadius: "9px",
          fontSize: "12px",
          padding: "0 10px",
        }}
      ></SelectInput> */}
        </div>
    );
}
function PayrollTableHeader() {
    const {
        getMonthlyWorkHours,
        report,
        branchs,
        handleSetBranchs,
        selectedBranchs,
        handleSetSelectedBranchs,
    } = useReport();
    const { selectedBranchSettings } = useBranch();
    React.useEffect(() => {
        handleSetBranchs(handleBranchs());
    }, []);
    React.useEffect(() => {
        branchs?.map((item: any) => {
            if (selectedBranchSettings?._id === item.value) {
                handleSetSelectedBranchs([
                    {
                        ...item,
                    },
                ]);
            }
        });

        if (!selectedBranchSettings?._id) {
            handleSetSelectedBranchs(branchs);
        }
    }, [selectedBranchSettings, branchs]);

    function handleBranchs() {
        const sessionBranchs = sessionStorage.getItem("branchs");
        const sessionUser = sessionStorage.getItem("user");
        let sessionLicense: any[] = [];
        if (sessionUser) {
            sessionLicense = JSON.parse(sessionUser).realLicense;
        }
        if (sessionBranchs && sessionBranchs !== "[]") {
            let listOfBranchs = JSON.parse(sessionBranchs);
            if (listOfBranchs.length > 0) {
                if (sessionLicense.length > 1) {
                    return [
                        ...listOfBranchs.map((item: any) => {
                            const LicenseEnseigne = sessionLicense.find(
                                (license: any) => {
                                    return license._id === item.license;
                                },
                            );
                            let enseigneName = "";
                            if (LicenseEnseigne) {
                                enseigneName = LicenseEnseigne.enseigne;
                            }

                            return {
                                label: `${enseigneName} - ${item.name}`,
                                value: item._id,
                            };
                        }),
                    ];
                } else {
                    return [
                        ...listOfBranchs.map((item: any) => {
                            return { label: item.name, value: item._id };
                        }),
                    ];
                }
            }
            return [];
        }
        return [];
    }

    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                height: "80px",
                margin: "0 1.25rem",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                }}
            >
                <TextData color="#11106E" size="34" fontWeight="bold">
                    Rapports
                </TextData>
                {/* <button className="fullscreen-btn" onClick={toggleFullScreen}>
                    <div className="fullscreen-icon">
                        {isFullScreen ? (
                            <ExitFullScreenSVG
                                height={18}
                                width={18}
                                color="white"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        ) : (
                            <FullScreenSVG
                                height={18}
                                width={18}
                                color="white"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        )}
                    </div>
                    <div className="fullscreen-title">
                    <div>
                        {!isFullScreen
                            ? "Mode plein écran"
                            : "Quitter le mode plein écran"}
                    </div>
                </div>
                </button> */}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                }}
            >
                {branchs?.length > 1 && (
                    <SelectInput
                        title=""
                        placeholder="Sélectionner les sites"
                        options={branchs}
                        selectedValue={selectedBranchs}
                        isMultiple
                        style={{
                            fontSize: "16px",
                            gap: "0.25rem",
                            marginTop: "1rem",
                            // alignSelf: "flex-end",
                            marginBottom: "0.5rem",
                            borderRadius: "10px",
                        }}
                        onSelectOption={(selectedBranchs) => {
                            handleSetSelectedBranchs(selectedBranchs);
                        }}
                    />
                )}
                <Button
                    textColor="white"
                    backgroundColor="#2A8BAB"
                    style={{
                        fontSize: "16px",
                        gap: "0.25rem",
                        marginTop: "1rem",
                        // alignSelf: "flex-end",
                        marginBottom: "0.5rem",
                    }}
                    width="auto"
                    padding="0.75rem 20px"
                    height="auto"
                    onClick={() =>
                        getMonthlyWorkHours({
                            isExport: true,
                            month: moment()
                                .month(parseInt(report.month) - 1)
                                .locale("fr")
                                .format("MMMM"),
                        })
                    }
                >
                    Télécharger le rapport
                </Button>
            </div>
        </div>
    );
}
const PayrollTable = PayrollReport;
const PayrollTableRightMenu = PayrollReportSidebar;
export { PayrollTable, PayrollTableRightMenu, PayrollTableHeader };
