import * as React from "react";
import DashboardTemplate from "../../components/templates/Dashboard";
import Input from "../../components/atoms/Input";
import ToggleButton from "../../components/atoms/Toggle";
import Radio from "../../components/atoms/Radio";
import CommingSoon from "./Elements/CommingSoon";
import NoAccess from "./Elements/NoAccess";
import { useBranch } from "../../hooks/useBranch";

import SettingSection from "../../components/organism/SettingSection";
import Button from "../../components/atoms/Button";
import { useVacation } from "../../hooks/useVacation";

export default function Vacation(props: VacationProps) {
    const {
        settings,
        commingSoon,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const { vacations, getAllVacations } = useVacation();
    const { selectedBranchSettings } = useBranch();

    React.useEffect(() => {
        getAllVacations();
    }, []);

    return (
        <div className="vacation-container">
            {!selectedBranchSettings._id && <NoAccess />}
            {commingSoon && <CommingSoon />}
            <div
                style={{
                    height: "calc(100vh - 290px)",
                    overflowY: "auto",
                }}
            >
                {settings?.map((elem, index) => {
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                        />
                    );
                })}
            </div>

            {includeButtons && (
                <div className="settings-footer">
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor="#EDEFF5"
                        textColor="#2A346D"
                        onClick={handleCancelButton}
                        width="120px"
                        height="40px"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2A8BAB",
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor="#2A8BAB"
                        textColor="white"
                        onClick={handleSubmitButton}
                        width="120px"
                        height="40px"
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}
interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface VacationProps {
    settings?: {
        headerTitle?: string;
        headerDescription?: string;
        configOptions?: React.ReactNode | ConfigOptionTemplate[];
    }[];
    commingSoon?: boolean;
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
Vacation.defaultProps = {
    settings: [
        {
            headerTitle: "Heure de travail",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: [
                {
                    optionTitle: "Heure d'ouverture",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",

                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="auto"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="09:05"
                            type="time"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Heure de fermeture",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="auto"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="09:05"
                            type="time"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Pause par défaut (en minutes)",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value="30 min"
                            type="text"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
            ],
        },
    ],
    commingSoon: true,
    includeButtons: false,
    submitButtonText: "Modifier",
    handleSubmitButton: () => {},
    cancelButtonText: "Annuler",
    handleCancelButton: () => {},
};
