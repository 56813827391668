import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { HolidayState } from "./type";
import {
    acceptHoliday,
    getAllHolidays,
    getAllHolidaysService,
    rejectHoliday,
    createHoliday,
    deleteHoliday,
} from "./service";
const initialHolidayState: HolidayState = {
    loading: false,
    error: false,
    success: false,
    userHolidays: {
        enAttente: [],
        valide: [],
        refuse: [],
    },
};

const holidayState = createSlice({
    name: "holiday",
    initialState: initialHolidayState,
    reducers: {
        resetAllHolidays: (state) => {
            state = initialHolidayState;
        },
    },
    extraReducers: (builder) => {
        // CASE : GETALL VACATIONS
        builder.addCase(
            getAllHolidaysService.pending,
            (state: HolidayState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            },
        );
        builder.addCase(
            getAllHolidaysService.fulfilled,
            (state: HolidayState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload !== undefined) {
                    if (state.userHolidays) {
                        state.userHolidays.enAttente = action.payload.enAttente;
                        state.userHolidays.valide = action.payload.valide;
                        state.userHolidays.refuse = action.payload.refuse;
                    }
                }
            },
        );
        builder.addCase(
            getAllHolidaysService.rejected,
            (state: HolidayState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            },
        );
        // CASE 2 : GET ALL
        builder.addCase(getAllHolidays.pending, (state: HolidayState) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(
            getAllHolidays.fulfilled,
            (state: HolidayState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload !== undefined) {
                    const { data, status } = action.payload;
                    if (status === "1,2" && data) {
                        if (state.userHolidays) {
                            state.userHolidays.refuse = data;
                        }
                    } else {
                        if (state.userHolidays) {
                            state.userHolidays.valide = data;
                        }
                    }

                    // if(status == "0" && data){
                    //     if (state.userHolidays) {
                    //         state.userHolidays.valide = data;
                    //     }
                    // }else{
                    //     if (state.userHolidays) {
                    //     state.userHolidays.refuse=data
                    // }
                    // }
                }
            },
        );
        builder.addCase(getAllHolidays.rejected, (state: HolidayState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
        // CASE 3 : ACCEPT HOLIDAY
        builder.addCase(acceptHoliday.pending, (state: HolidayState) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(acceptHoliday.fulfilled, (state: HolidayState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(acceptHoliday.rejected, (state: HolidayState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
        // CASE 3 : REJECT HOLIDAY
        builder.addCase(rejectHoliday.pending, (state: HolidayState) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(rejectHoliday.fulfilled, (state: HolidayState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(rejectHoliday.rejected, (state: HolidayState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
        // CASE 3 : Create HOLIDAY
        builder.addCase(createHoliday.pending, (state: HolidayState) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(createHoliday.fulfilled, (state: HolidayState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(createHoliday.rejected, (state: HolidayState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
        // CASE 5 : Delete HOLIDAY
        builder.addCase(deleteHoliday.pending, (state: HolidayState) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(deleteHoliday.fulfilled, (state: HolidayState) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        });
        builder.addCase(deleteHoliday.rejected, (state: HolidayState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const { resetAllHolidays } = holidayState.actions;
export default holidayState.reducer;
