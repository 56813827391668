import React from "react";
import VacationRequestCard from "../../../components/organism/VacationRequestCard";
import { useHoliday } from "../../../hooks/useHolidays";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface VacationRequestInterface {
    display: boolean;
    onClose?: () => void;
    employee: any;
    licenseId: string;
    siteId:any;
}

function VacationRequest({
    display,
    onClose,
    employee,
    licenseId,
    siteId,
}: VacationRequestInterface) {
    const { acceptConge, rejectConge, getHolidays, deleteConge, loading } =
        useHoliday();

    const ValidateHoliday = (congeId: any, cause: any, userId: any) => {
        try {
            acceptConge(congeId, cause, userId)
                .then(() => {
                    return getHolidays(null, "0", null, null, licenseId,  siteId ? [siteId]: null);})
                .then(() => {
                    return getHolidays(
                        null,
                        "1,2",
                        null,
                        null,
                        licenseId,
                        siteId ? [siteId]: null,
                    );
                })
                .then(() => {
                    if (onClose) {
                        onClose();
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: "Erreur!",
                        text: "Une erreur s'est produite lors de la soumission.",
                        icon: "error",
                        timer: 1500,
                        timerProgressBar: true,
                    });
                });
        } catch (error) {
            Swal.fire({
                title: "Erreur!",
                text: "Une erreur s'est produite lors de la soumission.",
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
            });
        }
    };
    const rejectHoliday = (congeId: any, cause: any, userId: any) => {
        try {
            rejectConge(congeId, cause, userId)
                .then(() => {
                    return getHolidays(null, "0", null, null, licenseId, siteId ? [siteId]: null);
                })
                .then(() => {
                    return getHolidays(
                        null,
                        "1,2",
                        null,
                        null,
                        licenseId,
                        siteId ? [siteId]: null,
                    );
                })
                .then(() => {
                    if (onClose) {
                        onClose();
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: "Erreur!",
                        text: "Une erreur s'est produite lors de la soumission.",
                        icon: "error",
                        timer: 1500,
                        timerProgressBar: true,
                    });
                });
        } catch (error) {
            Swal.fire({
                title: "Erreur!",
                text: "Une erreur s'est produite lors de la soumission.",
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
            });
        }
    };

    return (
        <VacationRequestCard
            display={display}
            onclose={onClose}
            employee={employee}
            onValid={ValidateHoliday}
            onReject={rejectHoliday}
            onDelete={deleteConge}
            loading={loading}
        />
    );
}

export default VacationRequest;
