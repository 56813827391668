import React from "react";
import "./style.css";
import { ReactComponent as DashboardSVG } from "./dashboard.svg";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";

export default function SideBarItem({
    title,
    Icon,
    active,
    expanded,
    showSubRoutes,
    onClick,
    subItem,
    variant = "default",
}: SidebarItemProps) {
    // const expanded = false;
    // const getDivClassname = () => {
    //     let baseClass = `px-4  relative flex ${
    //         expanded ? "" : "flex-col !text-md"
    //     } items-center cursor-pointer text-xl font-semibold group`;
    //     baseClass += expanded ? "" : " justify-center";
    //     switch (variant) {
    //         case "default": {
    //             if (active) {
    //                 baseClass += " text-default-active is-active";
    //             } else {
    //                 baseClass +=
    //                     " text-default is-not-active hover:text-default-active";
    //             }
    //             break;
    //         }
    //         case "primary": {
    //             if (active) {
    //                 baseClass += " text-primary-active is-active";
    //             } else {
    //                 baseClass +=
    //                     " text-primary is-not-active hover:text-primary-active";
    //             }
    //             break;
    //         }
    //         case "secondary": {
    //             if (active) {
    //                 baseClass += " text-secondary-active is-active";
    //             } else {
    //                 baseClass +=
    //                     " text-secondary is-not-active hover:text-secondary-active";
    //             }
    //         }
    //     }
    //     return baseClass;
    // };

    return (
        <div
            className="sidebar-item"
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                // marginBottom: "35px",
                position: "relative",
                backgroundColor: active ? "#eee" : "",
            }}
            onClick={onClick}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "4px",
                    backgroundColor: "#2a8bab",
                    opacity: active ? "1" : "0",
                    top: 0,
                    left: 0,
                }}
            ></div>
            <div
                className="sidebar-item-text"
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: subItem ? "8px 0" : "15px 0",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        cursor: "pointer",
                        color: active
                            ? "#2a8bab"
                            : subItem
                              ? "#6f78ab"
                              : "inherit",
                    }}
                >
                    {Icon}
                </div>
                <div
                    className="sidebar-item-title"
                    style={{
                        height: "100%",
                        textAlign: "left",
                        fontSize: subItem ? "14px" : "16px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        color: active
                            ? "#2a8bab"
                            : subItem
                              ? "#6f78ab"
                              : "inherit",
                    }}
                >
                    <div
                        style={{
                            cursor: "pointer",
                            color: "inherit",
                            width: "max-content",
                        }}
                    >
                        {title}
                    </div>
                </div>
                {showSubRoutes !== undefined && (
                    <div
                        className="expand-arrow"
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: "-30px",
                        }}
                    >
                        <ChevronSVG
                            style={{
                                cursor: "pointer",
                                transition: "0.5s ease, transform 0.5s ease",
                                transform: showSubRoutes
                                    ? "rotate(90deg)"
                                    : "rotate(-90deg)",
                            }}
                            height={22}
                            width={22}
                        />
                    </div>
                )}
            </div>
            {/* <div
                style={{
                    opacity: active ? "1" : "0",
                    backgroundColor: "#2a8bab",
                    height: "12px",
                    width: "12px",
                    position: "absolute",
                    right: "0",
                    borderRadius: "50%",
                    // border: "5px solid red",
                    // borderRightColor: "transparent",
                    border: "2px solid #f7f8fa",
                    transform: "translateX(50%)",
                    transition: "0.3s ease-in-out",
                }}
            ></div> */}
        </div>
    );
}

interface SidebarItemProps {
    title: string;
    Icon: React.ReactNode;
    active: boolean;
    expanded: boolean;
    onClick: React.MouseEventHandler;
    variant?: "default" | "primary" | "secondary";
    showSubRoutes?: boolean;
    subItem?: boolean;
}

{
    /* {expanded ? (
                <div
                    className='ml-2'
                   
                >
                    {title}
                </div>
            ) : (
                <span>{title}</span>
            )} */
}

{
    /* <div className='_rightBlock absolute right-0 top-0 bg-border hidden group-[.is-active]:block'>
                <div>
                    <img
                        className='h-70px'
                        src='/assets/imgs/sidebar-menu-active-bg.png'
                        alt='active background'
                    />
                    <span className='block w-14px h-14px absolute right-[-7px] top-26px bg-default-active rounded-full' />
                </div>
            </div> */
}
