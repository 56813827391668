import React from "react";
import Input from "../../atoms/Input";
import Clock from "./clock.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as TrashSVG } from "./trash.svg";
import { ReactComponent as CheckSVG } from "./check.svg";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import "./style.scss";
import Photo from "./photo1.png";
import Picture from "../../atoms/Picture";
import DotTicket from "../../atoms/DotTicket";
import SelectInput from "../../atoms/SelectInput";
import { useBranch } from "../../../hooks/useBranch";

export default function ShiftTimeInputs(props: any) {
    const {
        shiftData,
        setShiftData,
        pauseDisabled,
        setPauseDisabled,
        isShiftEditing,
        planningDefaultPause,
    } = props;
    const { branchSettings } = useBranch();
    const [shiftCount, setShiftCount] = React.useState(1);
    const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);
    const tabPressed = React.useRef(false);
    const handleTabPress = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        if (e.key === "Tab") {
            e.preventDefault();
            tabPressed.current = true;
            const nextIndex = index + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                inputRefs.current[0]?.focus();
            }
        }
    };

    const shiftInputs = [];
    for (let i = 0; i < shiftCount; i++) {
        shiftInputs.push(
            <div
                key={i}
                // className='add-shift-time-container'
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    marginBottom: 5,
                    gap: 10,
                }}
            >
                <Input
                    reference={(el: any) => (inputRefs.current[0] = el)}
                    onKeyDown={(e: any) => handleTabPress(e, 0)}
                    type="time"
                    captionText={"Début"}
                    defaultValue={
                        i === 0 ? shiftData?.from : shiftData?.secondStart
                    }
                    onChange={(value: any) => {
                        if (tabPressed.current) {
                            tabPressed.current = false;
                        } else {
                            if (i === 0) {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    from: value,
                                }));
                            } else {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    secondStart: value,
                                }));
                            }
                        }
                    }}
                    inputWidth="185px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    // iconImg={Clock}
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
                <Input
                    reference={(el: any) => (inputRefs.current[1] = el)}
                    onKeyDown={(e: any) => handleTabPress(e, 1)}
                    type="time"
                    captionText={"Fin"}
                    defaultValue={
                        i === 0 ? shiftData?.to : shiftData?.secondEnd
                    }
                    onChange={(value: any) => {
                        if (tabPressed.current) {
                            tabPressed.current = false;
                        } else {
                            if (i == 0) {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    to: value,
                                }));
                            } else {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    secondEnd: value,
                                }));
                            }
                        }
                    }}
                    inputWidth="185px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />

                {i === 1 && (
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            backgroundColor="#fff"
                            width="auto"
                            padding="3px 1px"
                            title="Supprimer le shift"
                            style={{
                                border: "1px solid #FB3640",
                                boxShadow:
                                    "1px 1px 4px 0px rgba(17, 16, 110, 0.10)",
                            }}
                            onClick={() => {
                                setShiftCount((prev) =>
                                    prev === 2 ? prev - 1 : prev,
                                );
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    secondStart: "",
                                    secondEnd: "",
                                }));
                            }}
                        >
                            <TrashSVG width={17} height={17} />
                        </Button>
                    </div>
                )}
                {i === 0 && shiftCount === 1 && !isShiftEditing && (
                    <div
                        style={{
                            width: "300px",
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            width="auto"
                            backgroundColor="transparent"
                            textColor="#2A8BAB"
                            style={{
                                gap: 10,
                            }}
                            onClick={() => {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    secondStart: "14:00",
                                    secondEnd: "18:00",
                                }));
                                setShiftCount((prev) =>
                                    prev === 1 ? prev + 1 : prev,
                                );
                            }}
                        >
                            <Plus />
                            <TextData width="auto" fontWeight="600" size="14px">
                                Ajouter un shift
                            </TextData>
                        </Button>
                    </div>
                )}
            </div>,
        );
    }
    shiftInputs.push(
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                width: "100%",
            }}
        >
            {branchSettings.usePause && (
                <Input
                    captionText={"Pause(min)"}
                    placeholder={"Pause(min)"}
                    inputWidth="150px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    inputRadius="4px"
                    textColor={"#4C5690"}
                    textSize={12}
                    type="number"
                    defaultValue={undefined}
                    value={shiftData?.pause}
                    disabled={pauseDisabled}
                    onChange={(value: any) =>
                        setShiftData((prev: any) => ({
                            ...prev,
                            pause: parseFloat(value),
                        }))
                    }
                    maxLength={3}
                    min="0"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                            opacity: pauseDisabled ? "0.2" : "1",
                        },
                        captionStyle: {
                            fontWeight: "500",
                            opacity: pauseDisabled ? "0.2" : "1",
                        },
                    }}
                />
            )}
            {planningDefaultPause !== undefined && branchSettings.usePause && (
                <label className="custom-checkbox">
                    <input
                        type="checkbox"
                        checked={pauseDisabled}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    pause: planningDefaultPause,
                                }));
                            } else {
                                setShiftData((prev: any) => ({
                                    ...prev,
                                    pause: 0,
                                }));
                            }
                            setPauseDisabled(e.target.checked);
                        }}
                    />
                    <div className="checkmark">
                        <span>
                            <CheckSVG />
                        </span>
                    </div>
                    <div
                        style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#2a346d",
                        }}
                    >
                        Pause par défaut ({planningDefaultPause}
                        min)
                    </div>
                </label>
            )}
            {branchSettings.useRepas && (
                <SelectInput
                    title="Repas"
                    options={[
                        { label: "0 repas", value: "0" },
                        { label: "1 repas", value: "1" },
                        { label: "2 repas", value: "2" },
                    ]}
                    selectedValue={{
                        label: `${shiftData?.repas} repas`,
                        value: parseInt(shiftData?.repas),
                    }}
                    onSelectOption={(option) =>
                        setShiftData((prev: any) => ({
                            ...prev,
                            repas: option.value,
                        }))
                    }
                    style={{
                        height: "48px",
                        width: "150px",
                        color: "#C7CBE2",
                    }}
                    captionColor="#2a346d"
                    captionSize="12px"
                    optionColor="#4C5690"
                    optionSize="14px"
                />
            )}
        </div>,
    );
    return (
        <div
            style={{
                width: "100%",
            }}
        >
            {shiftInputs}
        </div>
    );
}
