import Swal from "sweetalert2";
import { API } from "../constants/env";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    assignUsersToRuleService,
    createNewRuleService,
    deleteRuleService,
    getAllRulesService,
    getUsersByRuleService,
    updateRuleService,
} from "../redux/features/vacationRules/service";
import {
    loadRuleToOperation,
    resetVacationRule,
    resetVacationRuleOperation,
} from "../redux/features/vacationRules/vacationRuleSlice";

export const useVacationRule = () => {
    const dispatch = useAppDispatch();
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;

    const vacationRule = useAppSelector((state: any) => state.vacationRule);

    const rules = useAppSelector((state: any) => state.vacationRule).list;

    let ruleOperation = useAppSelector(
        (state: any) => state.vacationRule,
    ).operation;

    const loadVacationRules = () => {
        dispatch(getAllRulesService());
    };

    const loadRule = (ruleID: any) => {
        const selectedRule = rules.find((rule: any) => rule._id === ruleID);
        if (selectedRule) {
            dispatch(loadRuleToOperation(selectedRule));
        }
    };

    const resetRuleOperation = () => {
        dispatch(resetVacationRuleOperation());
    };

    const setRuleDesignation = (designation: string) => {
        dispatch(loadRuleToOperation({ designation }));
    };
    const setRuleBaseWorkDays = (baseWorkDays: number) => {
        dispatch(loadRuleToOperation({ baseWorkDays }));
    };
    const setRuleBaseEntitlement = (baseEntitlement: number) => {
        dispatch(loadRuleToOperation({ baseEntitlement }));
    };
    const setRuleUsers = (users: any) => {
        dispatch(loadRuleToOperation({ users }));
    };
    const setRuleRestrictedBypassLimitDays = (
        restrictedBypassLimitDays: number,
    ) => {
        dispatch(loadRuleToOperation({ restrictedBypassLimitDays }));
    };

    const updateRule = () => {
        dispatch(
            updateRuleService({
                idRule: ruleOperation._id,
                designation: ruleOperation.designation,
                baseWorkDays: ruleOperation.baseWorkDays,
                baseEntitlement: ruleOperation.baseEntitlement,
                restrictedBypassLimitDays:
                    ruleOperation.restrictedBypassLimitDays,
            }),
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({ icon: "success" });
                resetRuleOperation();
                loadVacationRules();
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    const createRule = () => {
        console.log("ruleOperation.users", ruleOperation.users);
        dispatch(
            createNewRuleService({
                designation: ruleOperation.designation,
                baseWorkDays: ruleOperation.baseWorkDays,
                baseEntitlement: ruleOperation.baseEntitlement,
                restrictedBypassLimitDays:
                    ruleOperation.restrictedBypassLimitDays,
                users: ruleOperation.users,
            }),
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({ icon: "success" });
                resetRuleOperation();
                loadVacationRules();
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    const deleteRule = (ruleID: string) => {
        dispatch(
            deleteRuleService({
                idRule: ruleID,
            }),
        ).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({ icon: "success" });
                resetRuleOperation();
                loadVacationRules();
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    const loadAffectationRule = (ruleID: any) => {
        loadRule(ruleID);
        dispatch(getUsersByRuleService({ idRule: ruleID })).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                setRuleUsers(res.payload.map((user: any) => user._id));
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    const assignUsersToRule = async () => {
        await dispatch(
            assignUsersToRuleService({
                idRule: ruleOperation._id,
                users: ruleOperation.users,
            }),
        ).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
                // setRuleUsers(
                //     res.payload.updatedUsers.map((user: any) => user._id)
                // );
                // resetRuleOperation();
                await loadVacationRules();
                await loadRule(ruleOperation._id);
                Swal.fire({ icon: "success" });
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    return {
        vacationRule,
        ruleOperation,
        rules,
        loadVacationRules,
        loadRule,
        resetRuleOperation,

        setRuleDesignation,
        setRuleBaseWorkDays,
        setRuleBaseEntitlement,
        setRuleRestrictedBypassLimitDays,
        setRuleUsers,
        updateRule,
        createRule,
        deleteRule,
        loadAffectationRule,
        assignUsersToRule,
    };
};
