import * as React from "react";
import "./style.scss";
import PopupCard from "../../molecules/PopUpCard";
import ListOfColors from "../../molecules/ListOfColors";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";
import SVGIcon from "../../../utils/img/icons/close.svg";
export default function NewTicketCard(props: NewTicketCardProps) {
    //input field
    const {
        header,
        onClose,
        inputValue,
        inputOnChange,
        inputLabel,
        inputLabelColor,
        inputLabelSize,
        inputBorderColor,
        inputTextSize,
        inputTextColor,
        inputRadius,
        inputPlaceholder,
        inputWidth,
    } = props;
    //selection color field
    const { selectionText, selectionColor, selectionSize } = props;
    const { colors, onSelectionColor, displayColorsCount } = props;
    const { onCancelButton, onSubmitButton } = props;
    const [inputVal, setinputValue] = React.useState("");
    const [selectedColor, setselectedColor] = React.useState(selectionColor);
    const [tickets, settickets] = React.useState(() => {
        if (inputValue === "") {
            return [];
        }
        return [inputValue];
    });
    const handleTicketChange = (data: any) => {
        const inputData = data.trim();
        if (inputData === "") {
            settickets([]);
        }
        if (inputData.endsWith(".")) {
            settickets(inputData.split(".").filter(Boolean));
            setinputValue("");
        } else {
            setinputValue(data);
        }
    };
    React.useEffect(() => {
        if (inputVal !== "") {
            inputOnChange?.(inputVal);
        }
    }, [inputVal]);
    const handleSelectedColor = (color: string) => {
        if (inputVal !== "") {
            settickets(inputVal.split(".").filter(Boolean));
            setinputValue("");
        }
        setselectedColor(color);
        onSelectionColor?.(color);
    };
    const handleDoubleClickTag = (e: any) => {
        setinputValue(String(tickets));
        settickets([]);
    };
    return (
        <PopupCard
            header={header}
            onClose={onClose}
            onClickButton1={onCancelButton}
            onClickButton2={onSubmitButton}
        >
            <div className="new-ticket-card-container">
                <Input
                    captionText={inputLabel}
                    captionColor={inputLabelColor}
                    captionSize={inputLabelSize}
                    borderColor={inputBorderColor}
                    borderWidth={1}
                    textSize={inputTextSize}
                    textColor={inputTextColor}
                    inputRadius={inputRadius}
                    placeholder={inputPlaceholder}
                    inputWidth={inputWidth}
                    onChange={handleTicketChange}
                    value={inputVal}
                    // iconImg={SVGIcon}
                    tags={tickets.map((ticket) => {
                        return (
                            <div
                                className="new-ticket"
                                style={{
                                    backgroundColor: selectedColor,
                                }}
                                onClick={handleDoubleClickTag}
                            >
                                <div></div>
                                <label>{ticket}</label>
                            </div>
                        );
                    })}
                />
                <div>
                    <TextData color={selectionColor} size={selectionSize}>
                        {selectionText}
                    </TextData>
                    <ListOfColors
                        getSelectedColor={handleSelectedColor}
                        colors={colors}
                        width="auto"
                        displayCount={displayColorsCount}
                    />
                </div>
            </div>
        </PopupCard>
    );
}
interface NewTicketCardProps {
    header?: string;
    onClose?: () => void;
    //input field
    inputLabel?: string;
    inputLabelColor?: string;
    inputLabelSize?: string;
    inputBorderColor?: string;
    inputTextSize?: number;
    inputTextColor?: string;
    inputRadius?: string;
    inputPlaceholder?: string;
    inputWidth?: string;
    inputValue?: string;
    inputOnChange?: (text: string) => void;
    //selection color field
    selectionText?: string;
    selectionColor?: string;
    selectionSize?: string;
    //list of colors field
    onSelectionColor?: (color: string) => void;
    colors?: string[];
    displayColorsCount?: number;
    // submit
    onCancelButton?: () => void;
    onSubmitButton?: () => void;
}
NewTicketCard.defaultProps = {
    header: "Ajouter une étiquette",
    //input field
    inputLabel: "Nom de l'étiquette",
    inputValue: "",
    inputLabelColor: "#8E96C1",
    inputLabelSize: "12px",
    inputBorderColor: "#C7CBE2",
    inputTextSize: 14,
    inputTextColor: "#2A346D",
    inputRadius: "4px",
    inputPlaceholder: "",
    inputWidth: "100%",
    inputOnChange: (data: string) => {},
    //selection color field
    selectionText: "Choisir la couleur",
    selectionColor: "#8E96C1",
    selectionSize: "12px",
    //list of colors field
    onSelectionColor: (color: string) => {},
    colors: ["#5677FD", "#304FFD"],
    displayColorsCount: 8,
    // submit
    onCancelButton: () => {},
    onSubmitButton: () => {},
};
