import Swal from "sweetalert2";
import { API } from "../constants/env";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    acceptHoliday,
    createHoliday,
    deleteHoliday,
    getAllHolidays,
    getAllHolidaysService,
    rejectHoliday,
} from "../redux/features/holiday/service";
import { HolidayItem } from "../redux/features/holiday/type";

export const useHoliday = () => {
    const dispatch = useAppDispatch();
    const { error, loading, userHolidays } = useAppSelector(
        (state: any) => state.holiday,
    );
    const holidays = useAppSelector((state: any) => state.holiday);
    const validHolidays = useAppSelector((state: any) => state.holiday)
        .userHolidays.valide;
    const processedHolidays = useAppSelector((state: any) => state.holiday)
        .userHolidays.refuse;
    const getUserHistory = (userId: any, startDay: any, endDay: any) => {
        dispatch(getAllHolidaysService({ userId, startDay, endDay }));
    };
    const getHolidays = (
        userId: any,
        status: any,
        month: any,
        year: any,
        licenseId: any,
        siteId: any,
    ) => {
        dispatch(
            getAllHolidays({ userId, status, month, year, licenseId, siteId }),
        );
    };
    const acceptConge = (congeId: any, cause: any, userId: any) => {
        return dispatch(acceptHoliday({ congeId, cause, userId }));
    };
    const rejectConge = (congeId: any, cause: any, userId: any) => {
        return dispatch(rejectHoliday({ congeId, cause, userId }));
    };
    const createConge = (values: HolidayItem) => {
        return dispatch(createHoliday(values));
    };
    const deleteConge = (congeId: string) => {
        return dispatch(deleteHoliday(congeId));
    };

    return {
        holidays,
        loading,
        error,
        userHolidays,
        validHolidays,
        processedHolidays,
        getUserHistory,
        getHolidays,
        acceptConge,
        rejectConge,
        createConge,
        deleteConge,
    };
};
