import moment from "moment";
import { calculateDays } from "../../../../_helpers/Functions";

interface RowData {
  fromDay: string;
  fromHour: string;
  toDay: string;
  toHour: string;
}

export const DaysNumberColumn = ( row: any) => {


  const days = calculateDays(row?.fromDay, row?.fromHour, row?.toDay, row?.toHour);
  return (
    <span className="text-v-800 text-md font-light">
      { days >1 ?`${days} jours`:`${days} jour`}
    </span>
  );
};
