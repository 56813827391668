import { ROUTER } from "../../constants/env";
import "./style.css";
import { useNavigate } from "react-router-dom";
export default function Error404() {
    const navigate = useNavigate();
    return (
        <div
            className={
                "flex flex-1 justify-center items-center flex-col h-full gap-2"
            }
            style={{
                height: "100vh",
            }}
        >
            <div className={"items-center justify-center text-center"}>
                <h1 className={"error-404-title "}>Erreur 404</h1>
                <p className={"text-2xl font-regular"}>
                    Ooops, page introuvable!
                </p>
            </div>
            <img
                src={"/assets/imgs/404.png"}
                className={"w-1/3 w-max-[240px]"}
            />
            <button
                className={"error-404-button rounded-xl pt-5 pb-5 pl-10 pr-10"}
                onClick={() => navigate(ROUTER.PLANNING)}
            >
                Retour à la page d'accueil
            </button>
        </div>
    );
}
