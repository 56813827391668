import React from "react";
import DashboardTemplate from "../Dashboard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import ReportTabNavigation from "../../molecules/TabNavigationV2/ReportTabNavigation";
import Table from "../../organism/Table";
import SelectInput from "../../atoms/SelectInput";
import ReportDisplayOption from "../../molecules/ReportDisplayOption";
import CommingSoon from "../../../pages/Settings/Elements/CommingSoon";

import {
    WorkedHoursTable,
    WorkedHoursTableRightMenu,
    WorkedHoursTableHeader,
    ActivityTable,
    ActivityTableRightMenu,
    ActivityTableHeader,
    PayrollTable,
    PayrollTableRightMenu,
    PayrollTableHeader,
    ProductivityContent,
    ProductivityTableRightMenu,
    ProductivityTableHeader,
    DelayTableRightMenu,
    DelayTable,
    DelayTableHeader,
    VacationTableRightMenu,
    VacationTable,
    VacationTableHeader,
} from "../../organism/ReportManagement";
import CalendarInput from "../../molecules/CalendarInput";
import { ROUTER } from "../../../constants/env";
import { useBranch } from "../../../hooks/useBranch";
import { useReport } from "../../../hooks/useReport";
import { useEmployees } from "../../../hooks/useEmployees";
import { useTimeTracker } from "../../../hooks/useTimeTracker";
import { useUser } from "../../../hooks/useUsers";

function ReportTemplate() {
    const {
        report,
        getReportFromLocalStorage,
        getShowOnlyCurrentMonthHours,
        getMonthlyWorkHours,
    } = useReport();
    const {
        selectedBranchSettings,
        getBranchSettings,
        Settings,
        branchSettings,
        getSiteSettings,
    } = useBranch();
    const {
        getEmployees,
        employees,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
    } = useEmployees();
    const { getTimeTracker } = useTimeTracker();
    const { getUsersList } = useUser();
    React.useEffect(() => {
        getReportFromLocalStorage();
        getShowOnlyCurrentMonthHours();
    }, []);
    React.useEffect(() => {
        if (selectedBranchSettings?._id) {
            getBranchSettings();
            getSiteSettings();
            getTimeTracker();
        }
    }, [selectedBranchSettings?._id]);
    React.useEffect(() => {
        if (!employees?.length) {
            getEmployees({ archived: false });
        }
        if (selectedBranchSettings && selectedBranchSettings._id) {
            getPrimesTypes({ siteId: selectedBranchSettings._id });
        }
        onUpdateFilterBy({
            ...filterBy,
            name: "",
            niveau: [],
            role: [],
            site: selectedBranchSettings._id
                ? selectedBranchSettings._id.toString()
                : undefined,
        });
    }, [selectedBranchSettings]);
    React.useEffect(() => {
        getUsersList();
    }, []);

    const tabs = branchSettings.paie
        ? [
              {
                  title: "Heures travaillées",
                  headerComponent: <WorkedHoursTableHeader />,
                  component: <WorkedHoursTable />,
                  rightTabs: <WorkedHoursTableRightMenu />,
                  link: ROUTER.REPORT.WORKED_HOURS,
                  active: false,
                  onClick: () => {},
              },
              {
                  title: "Retard",
                  headerComponent: <DelayTableHeader />,
                  component: <DelayTable />,
                  rightTabs: <DelayTableRightMenu />,
                  link: ROUTER.REPORT.DELAY,
                  active: false,
                  onClick: () => {},
              },
              // {
              //     title: "Congé",
              //     headerComponent: <VacationTableHeader />,
              //     component: <VacationTable />,
              //     rightTabs: <VacationTableRightMenu />,
              //     link: ROUTER.REPORT.VACATION,
              //     active: false,
              //     onClick: () => {},
              // },
              {
                  title: "Rapport paie",
                  headerComponent: <PayrollTableHeader />,
                  component: <PayrollTable />,
                  rightTabs: <PayrollTableRightMenu />,
                  link: ROUTER.REPORT.PAYROLL,
                  active: false,
                  onClick: () => {},
              },
              // {
              //     title: "Productivité",
              //     headerComponent: <ProductivityTableHeader />,
              //     component: <ProductivityContent />,
              //     rightTabs: <ProductivityTableRightMenu />,
              //     link: ROUTER.REPORT.PRODUCTIVITY,
              //     active: false,
              //     onClick: () => {},
              // },
              // {
              //     title: "Activité",
              //     headerComponent: <ActivityTableHeader />,
              //     component: <ActivityTable />,
              //     rightTabs: <ActivityTableRightMenu />,
              //     link: ROUTER.REPORT.ACTIVITY,
              //     active: false,
              //     onClick: () => {},
              // },
          ]
        : [
              {
                  title: "Heures travaillées",
                  headerComponent: <WorkedHoursTableHeader />,
                  component: <WorkedHoursTable />,
                  rightTabs: <WorkedHoursTableRightMenu />,
                  link: ROUTER.REPORT.WORKED_HOURS,
                  active: false,
                  onClick: () => {},
              },
              {
                  title: "Retard",
                  headerComponent: <DelayTableHeader />,
                  component: <DelayTable />,
                  rightTabs: <DelayTableRightMenu />,
                  link: ROUTER.REPORT.DELAY,
                  active: false,
                  onClick: () => {},
              },
              // {
              //     title: "Congé",
              //     headerComponent: <VacationTableHeader />,
              //     component: <VacationTable />,
              //     rightTabs: <VacationTableRightMenu />,
              //     link: ROUTER.REPORT.VACATION,
              //     active: false,
              //     onClick: () => {},
              // },
              // {
              //     title: "Productivité",
              //     headerComponent: <ProductivityTableHeader />,
              //     component: <ProductivityContent />,
              //     rightTabs: <ProductivityTableRightMenu />,
              //     link: ROUTER.REPORT.PRODUCTIVITY,
              //     active: false,
              //     onClick: () => {},
              // },
              // {
              //     title: "Activité",
              //     headerComponent: <ActivityTableHeader />,
              //     component: <ActivityTable />,
              //     rightTabs: <ActivityTableRightMenu />,
              //     link: ROUTER.REPORT.ACTIVITY,
              //     active: false,
              //     onClick: () => {},
              // },
          ];
    return <ReportTabNavigation tabs={tabs} />;
}

export default ReportTemplate;
