import React from "react";
import { useIntl } from "react-intl";
import { Polygon } from "../../../Icons";
import Theme from "../../../tailwind-theme";
import InputInfos from "../../atoms/InputInfos";

export default function Echelon({
    echelon = ["A", "B", "C", "D", "E"],
    value,
    onChange,
}: EchelonInterface) {
    const intl = useIntl();

    const handleChangeEchelon = (val: string) => {
        if (value === val) {
            onChange("");
        } else {
            onChange(val);
        }
    };
    return (
        <div className="py-2 px-6 rounded border border-n-100 h-[150px]">
            <div
                style={{
                    display: "flex",
                }}
            >
                <span className="text-n-400 font-bold text-lg">
                    {intl.formatMessage({ id: "CONTRACT.ECHELON" })}
                </span>
                <InputInfos rup={true} />
            </div>
            <div className="flex py-3 gap-3 border-b border-n-0 relative">
                {echelon.map((ech, key) => (
                    <div className="flex justify-center">
                        <a
                            key={key}
                            className={`group flex cursor-pointer items-center justify-center w-[48px] h-[48px] rounded-full text-lg font-bold hover:text-white hover:bg-v-500 ${
                                ech === value
                                    ? "text-white bg-v-500"
                                    : "text-n-700 bg-n-0"
                            }`}
                            onClick={() => handleChangeEchelon(ech)}
                        >
                            {ech}
                            <div
                                className={`absolute bottom-0 ${
                                    value === ech ? "block" : "hidden"
                                } group-hover:block`}
                            >
                                <Polygon
                                    color={Theme.colors["v-500"]}
                                    height={10}
                                    width={10}
                                />
                            </div>

                            <span
                                className={`absolute top-[80px] w-max text-v-500 font-medium text-base _noWordBreak  ${
                                    value === ech ? "block" : "hidden"
                                } group-hover:block `}
                            >
                                {intl.formatMessage(
                                    { id: "CONTRACT.ECHELON_WITH_VALUE" },
                                    { value: ech },
                                )}
                            </span>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

interface EchelonInterface {
    echelon?: string[];
    value: string;
    onChange: (value: string) => void;
}
