import * as Sentry from "@sentry/react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { ROUTER } from "../constants/env";

import RequireAuth from "./RequireAuth";

import DashboardTemplate from "../components/templates/Dashboard";

import {
    Login,
    ResetPassword,
    ForgetPassword,
    CheckEmail,
    ChangePassword,
} from "../pages/Authentification";

import EmployesPage from "../pages/Employees/EmployeesPage";
import EmployeesEditPage from "../pages/Employees/EmployeesEditPage";
import RegisterPage from "../pages/Employees/Register";

import {
    Branch,
    Schedule,
    TimeTracker,
    Vacation,
    Integration,
    Administrative,
} from "../pages/Settings";
import SettingsPage from "../pages/Settings";
import SampleTest from "../pages/SampleTest";
import Footer from "../components/molecules/Footer";
import ProgressSteps from "../components/molecules/ProgressSteps";
import Startup from "../pages/Startup";
import Table from "../components/organism/Table";
import TabNavigatorReport from "../components/molecules/TabNavigationV2/ReportTabNavigation";
import Report from "../pages/Report";
import StartupPage from "../pages/Startup";
import BasePage from "./Basepage";
import Mobile from "../pages/Settings/Mobile";
import Button from "../components/atoms/Button";
import Error404 from "../pages/Error404";

const AuthentificationPages = [
    {
        path: ROUTER.AUTH.LOGIN,
        element: <Login />,
    },
    {
        path: ROUTER.AUTH.FORGET_PASS,
        element: <ForgetPassword />,
    },
    {
        path: ROUTER.AUTH.CHANGE_PASS,
        element: (
            <RequireAuth>
                <ChangePassword />
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.AUTH.RESET_PASS,
        element: <ResetPassword />,
    },
    {
        path: ROUTER.AUTH.CHECK_EMAIL,
        element: <CheckEmail />,
    },
];

const SettingsPages = [
    {
        path: ROUTER.SETTINGS.BRANCH,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Branch />
                </SettingsPage>
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.SETTINGS.SCHEDULE,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Schedule />
                </SettingsPage>
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.SETTINGS.TIME_TRACKER,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <TimeTracker />
                </SettingsPage>
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.SETTINGS.MOBILE,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Mobile />
                </SettingsPage>
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.SETTINGS.VACATION,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Vacation />
                </SettingsPage>
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.SETTINGS.INTEGRATION,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Integration />
                </SettingsPage>
            </RequireAuth>
        ),
    },
    {
        path: ROUTER.SETTINGS.ADMINISTRATIVE,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Administrative />
                </SettingsPage>
            </RequireAuth>
        ),
    },
];

const StartupPages = [
    {
        path: ROUTER.STARTUP.STARTUP,
        element: <StartupPage />,
    },
];

const ReportPages = [
    {
        path: ROUTER.REPORT.WORKED_HOURS,
        element: (
            <DashboardTemplate>
                <Report />
            </DashboardTemplate>
        ),
    },
    {
        path: ROUTER.REPORT.PRODUCTIVITY,
        element: (
            <DashboardTemplate>
                <Report />
            </DashboardTemplate>
        ),
    },
    {
        path: ROUTER.REPORT.DELAY,
        element: (
            <DashboardTemplate>
                <Report />
            </DashboardTemplate>
        ),
    },
    {
        path: ROUTER.REPORT.VACATION,
        element: (
            <DashboardTemplate>
                <Report />
            </DashboardTemplate>
        ),
    },
    {
        path: ROUTER.REPORT.PAYROLL,
        element: (
            <DashboardTemplate>
                <Report />
            </DashboardTemplate>
        ),
    },
    {
        path: ROUTER.REPORT.ACTIVITY,
        element: (
            <DashboardTemplate>
                <Report />
            </DashboardTemplate>
        ),
    },
];

const BasePageRoutes = [
    {
        path: "/*",
        element: (
            <RequireAuth>
                <BasePage />
            </RequireAuth>
        ),
    },
];
const SentryError = () => {
    const handleClick = () => {
        Sentry.captureException(new Error("Test Error", { cause: "/sentry" }));
        alert("Throwing is DONE");
    };
    return (
        <div>
            <Button height="150px" width="150px" onClick={handleClick}>
                Click Here To Throw Error
            </Button>
        </div>
    );
};

export const router = createBrowserRouter([
    /*{
        path: ROUTER.HOME,
        element: (
            <RequireAuth>
                <Navigate to={ROUTER.SETTINGS.BRANCH} />
            </RequireAuth>
        ),
    },*/
    // {
    //     path: "/sentry",
    //     element: (
    //         <RequireAuth>
    //             <SentryError />
    //         </RequireAuth>
    //     ),
    // },
    ...BasePageRoutes,
    ...StartupPages,
    ...AuthentificationPages,
    {
        path: "/404",
        element: <Error404 />,
    },
]);
