import * as React from "react";
import moment from "moment";
import ReportDisplayOption from "../../../molecules/ReportDisplayOption";
import SelectInput from "../../../atoms/SelectInput";
import PayrollPerformanceCard from "../../../molecules/PayrollPerformanceCard";
import TextData from "../../../atoms/TextData";
import Button from "../../../atoms/Button";
import Table from "../../Table";

import "./style.scss";
import HeaderTable from "./HeaderTable";
import ContentTable from "./ContentTable";
import CommingSoon from "../../../../pages/Settings/Elements/CommingSoon";

function ProductivityTable() {
    return {
        fields: {
            fieldRow: HeaderTable(),
            onClickRow: () => {},
            style: {
                border: "1px solid #EDEFF5",
                borderTop: "none",
                width: "100%",
                backgroundColor: "#EDEFF5",
            },
            className: [],
        },
        data: ContentTable(),
    };
}

function WeekRatio() {
    return (
        <div className='week-ratio-container'>
            <TextData
                width='auto'
                color='#2A346D'
                size='14px'
                fontWeight='bold'
            >
                Ratios de la semaine
            </TextData>
            <div className='clickable-button'>
                <Button
                    textColor='#fff'
                    backgroundColor='#2D2AAD'
                    width='70px'
                    padding='6px 16px'
                    style={{
                        borderRadius: "4px 0 0 4px",
                        fontSize: "12px",
                    }}
                >
                    Budget
                </Button>
                <Button
                    textColor='#C7CBE2'
                    backgroundColor='#fff'
                    width='70px'
                    padding='6px 16px'
                    style={{
                        borderRadius: "0 4px 4px 0",
                        fontSize: "12px",
                    }}
                >
                    Réel
                </Button>
            </div>
        </div>
    );
}
function InfoCard() {
    const data = [
        { label: "Vente HT", value: ["0Є"] },
        { label: "Masse salariale", value: ["3573.6Є"] },
        { label: "Heure planifiées", value: ["210 h"] },
        { label: "Productivité", value: ["0Є/h"] },
        { label: "Ratio masse salariale", value: ["0.0%"] },
        { label: "Heures Supp.", value: ["0h", "0.00Є"] },
    ];
    return (
        <div className='productivity-data-cards'>
            <WeekRatio />
            {data.map((item, index) => (
                <div className='info-card-container'>
                    <TextData width='auto' color='#D1D1E8' size='14px'>
                        {item.label}
                    </TextData>
                    {item.value.map((value, i) => (
                        <TextData
                            fontWeight='bold'
                            width='auto'
                            color='#fff'
                            size='20px'
                        >
                            {value}
                        </TextData>
                    ))}
                </div>
            ))}
        </div>
    );
}

function ProductivityContent(props: ProductivityContentProps) {
    const { activateCommingSoon } = props;

    return (
        <div className='productivity-container'>
            {activateCommingSoon && <CommingSoon page={"productivity"} />}
            <div className='payroll-stats-container'>
                <PayrollPerformanceCard />
            </div>
            <div className='productivity-data-container'>
                <InfoCard />
                <Table {...ProductivityTable()} />
            </div>
        </div>
    );
}

function ProductivitySidebar() {
    return (
        <div
        // style={{
        //     display: "flex",
        //     flexDirection: "row",
        // }}
        >
            {/* <ReportDisplayOption />
            <SelectInput
                title=''
                options={["Année"]}
                style={{
                    width: "auto",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput>
            <SelectInput
                title=''
                options={["Mois"]}
                style={{
                    width: "auto",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput>
            <SelectInput
                title=''
                options={["Selectionner un employé"]}
                style={{
                    width: "210px",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput> */}
        </div>
    );
}
function ProductivityTableHeader() {
    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                height: "80px",
                margin: "0 1.25rem",
            }}
        >
            <TextData color='#11106E' size='34' fontWeight='bold'>
                Rapports
            </TextData>
        </div>
    );
}

const ProductivityTableRightMenu = ProductivitySidebar;
interface ProductivityContentProps {
    activateCommingSoon?: boolean;
}
ProductivityContent.defaultProps = {
    activateCommingSoon: true,
};
export {
    ProductivityContent,
    ProductivityTableRightMenu,
    ProductivityTableHeader,
};
