import React from "react";

export default function ZToggleButton({
    background = "default",
    isChecked,
    width = 28,
    height = 16,
    onPress,
    leftComponent,
    sliderStyles = {},
}: ZToggleButtonInterface) {
    const getCssContainerClass = () => {
        let baseClass: string = "rounded-full relative cursor-pointer";
        switch (background) {
            case "default": {
                baseClass += isChecked == true ? " bg-t-500" : " bg-n-300";
                break;
            }
        }
        return baseClass;
    };

    return (
        <div className="flex gap-2 items-center justify-between w-full">
            {leftComponent && leftComponent}
            <div
                className={getCssContainerClass()}
                style={{
                    ...sliderStyles,
                    width: `${width}px`,
                    height: `${height}px`,
                }}
                onClick={onPress}
            >
                <span
                    className="inline-block bg-white rounded-full absolute top-[1px] left-[1px]  duration-100"
                    style={{
                        width: `${height - 2}px`,
                        height: `${height - 2}px`,
                        translate: `${isChecked == true ? width - height + "px" : "0px"}`,
                    }}
                />
            </div>
        </div>
    );
}

interface ZToggleButtonInterface {
    sliderStyles?: React.CSSProperties;
    isChecked: boolean;
    width?: number;
    height?: number;
    onPress: () => void;
    background?: "default";
    leftComponent?: React.ReactNode;
}
