import React from "react";
import TimingComponent from "../../../../components/molecules/TimingComponent";
import moment from "moment";

export const VacationDateColumn = (row: any) => {
    const fromDay = moment(row.fromDay)?.format("DD/MM/YYYY");
    const toDay = moment(row.toDay)?.format("DD/MM/YYYY");
    return (
        <div>
            <TimingComponent startTime={fromDay} endTime={toDay} />
        </div>
    );
};
