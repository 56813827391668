import React from "react";
import AttendanceCard from "../../organism/AttendanceCard";
import "./style.css";
import Shift from "./Shift";

export default function PlanningDayCell({
    data,
    planningEmployees,
    defaultEmployee,
    published,
    department,
    setIsShift,
    setIsClicked,
    setIsShiftEditing,
    setShiftToEdit,
}: any) {
    return (
        <Shift
            // key={key}
            data={data}
            setIsShiftEditing={setIsShiftEditing}
            setShiftToEdit={setShiftToEdit}
            setIsShift={setIsShift}
            setIsClicked={setIsClicked}
            extern={
                (department && department?._id !== data.department) ||
                (!department && data.department !== null)
            }
        />
    );
}
