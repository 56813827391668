import * as React from "react";
import "./styles/schedule.style.scss";

import { ReactComponent as NoAccessSVG } from "./icons/no-access.svg";

import SettingSection from "../../components/organism/SettingSection";

import Button from "../../components/atoms/Button";
import NoAccess from "./Elements/NoAccess";
import CommingSoon from "./Elements/CommingSoon";
import { useBranch } from "../../hooks/useBranch";

export default function Notifications(props: NotificationsProps) {
    const { selectedBranchSettings } = useBranch();
    const {
        commingSoon,
        noAccessParams,
        settings,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const { ImageComponent, title, description } = noAccessParams ?? {};
    const [scheduleSettings, setScheduleSettings] = React.useState(settings);

    React.useEffect(() => {}, [selectedBranchSettings]);
    React.useEffect(() => {
        setScheduleSettings(settings);
    }, [settings]);

    return (
        <div className="administrative-container">
            {!selectedBranchSettings._id && <NoAccess />}
            {commingSoon && <CommingSoon />}
            <div
                style={{
                    height: "calc(100vh - 290px)",
                    overflow: "auto",
                }}
            >
                {scheduleSettings?.map((elem, index) => {
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                        />
                    );
                })}
            </div>
            {includeButtons && (
                <div className="settings-footer">
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor="#EDEFF5"
                        textColor="#2A346D"
                        onClick={handleCancelButton}
                        width="120px"
                        height="40px"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2A8BAB",
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor="#2A8BAB"
                        textColor="white"
                        onClick={handleSubmitButton}
                        width="120px"
                        height="40px"
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface NotificationsProps {
    commingSoon?: boolean;
    noAccessParams?: {
        ImageComponent?: React.ReactNode;
        title?: string;
        description?: string;
    };
    settings?: {
        headerTitle?: string;
        headerDescription?: string;
        configOptions?: React.ReactNode | ConfigOptionTemplate[];
    }[];
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
Notifications.defaultProps = {
    commingSoon: false,
    noAccessParams: {
        ImageComponent: NoAccessSVG,
        title: "Paramètres",
        description:
            "Veuillez sélectionner un établissement pour accéder aux paramètres !",
    },
    settings: [],
    includeButtons: true,
    submitButtonText: "Modifier",
    handleSubmitButton: () => {
        console.log("Modifier");
    },
    cancelButtonText: "Annuler",
    handleCancelButton: () => {
        console.log("Annuler");
    },
};
