import moment, { Moment } from "moment";
type CalendarType = Array<Array<Moment | null>>;

export const MONTHS = [
    { value: 0, label: "Janvier" },
    { value: 1, label: "Février" },
    { value: 2, label: "Mars" },
    { value: 3, label: "Avril" },
    { value: 4, label: "Mai" },
    { value: 5, label: "Juin" },
    { value: 6, label: "Juillet" },
    { value: 7, label: "Août" },
    { value: 8, label: "Septembre" },
    { value: 9, label: "Octobre" },
    { value: 10, label: "Novembre" },
    { value: 11, label: "Décembre" },
];

const startYear = 1900;
const currentYear = moment().year();

export const YEARS: Array<{ label: string; value: string | number }> = [];

for (let year = currentYear + 1; year >= startYear; year--) {
    YEARS.push({ label: year.toString(), value: year.toString() });
}
export const DAYS = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

export function buildCalendar(value: Moment): CalendarType {
    let startDate = moment(value).startOf("month").startOf("week"); //.add(1, 'day');
    let endDate = moment(value).endOf("month").add(1, "day");
    let calendar = [];
    let day = startDate.clone().subtract(1, "day");
    while (day.isBefore(endDate, "day")) {
        calendar.push(
            Array(7)
                .fill(0)
                .map(() => {
                    let newDate = day.add(1, "day");
                    if (
                        day.isBefore(endDate, "day") &&
                        day.isSame(value, "month")
                    )
                        return newDate.clone();
                    return null;
                }),
        );
    }
    return calendar;
}
