import * as React from "react";
import { extractNumber } from "../../../_helpers/Functions";
import Button from "../../../components/atoms/Button";
import ColoredCircle from "../../../components/atoms/ColoredCircle";
import Input from "../../../components/atoms/Input";
import Ticket from "../../../components/atoms/Ticket";
import ToggleButton from "../../../components/atoms/Toggle";
import CustomOrderCard from "../../../components/organism/CustomOrderCard";
import NewTicketCard from "../../../components/organism/NewTicketCard";
import { useBranch } from "../../../hooks/useBranch";
import { useMobile } from "../../../hooks/useMobile";
import TicketsList from "../Elements/ScheduleElements/TicketsList";
import SearchSVG from "./search.svg";
import img1 from "./pictures/1.png";
import img2 from "./pictures/2.png";
import img3 from "./pictures/3.png";
import img4 from "./pictures/4.png";
import img6 from "./pictures/6.png";

import "../styles/vacation.style.scss";
import TextData from "../../../components/atoms/TextData";
import DraggableOrderContainer from "../../../components/molecules/DraggableOrderContainer";
import Modal from "../../../components/atoms/Modal";
import PopUpCard from "../../../components/molecules/PopUpCard";
import Checkbox from "../../../components/atoms/Checkbox";
import Picture from "../../../components/atoms/Picture";
import { useVacation } from "../../../hooks/useVacation";
import { useVacationRule } from "../../../hooks/useVacationRule";
import { useEmployees } from "../../../hooks/useEmployees";
import { ROUTER } from "../../../constants/env";

function VacationList(props: any) {
    const { vacations, selectedVacation } = props;
    const {
        getAllVacations,
        loadVacationToUpdate,
        setDesignationToUpdate,
        setColorToUpdate,
        updateSelectedVacation,
        deleteSelectedVacation,
        setDesignationToCreate,
        setColorToCreate,
        submitNewVacation,
    } = useVacation();
    const data = vacations.map((vacation: any) => {
        return {
            id: vacation._id,
            name: vacation.designation,
            backgroundColor: vacation.color,
        };
    });
    const [selectedConge, setSelectedConge] = React.useState(selectedVacation);
    const listOfColors = () => {
        let colors = [
            "#1abc9c",
            "#16a085",
            "#2ecc71",
            "#27ae60",
            "#3498db",
            "#2980b9",
            "#9b59b6",
            "#8e44ad",
            "#34495e",
            "#2c3e50",
            "#f1c40f",
            "#f39c12",
            "#e67e22",
            "#d35400",
            "#e74c3c",
            "#c0392b",
            "#ecf0f1",
            "#bdc3c7",
            "#95a5a6",
            "#7f8c8d",
        ];
        colors = [
            ...colors,
            ...vacations.map((vacation: any) => vacation.color),
        ];
        return colors;
    };
    React.useEffect(() => {
        if (selectedVacation) {
            setSelectedConge(selectedVacation);
        }
    }, [selectedVacation]);

    const [updateCongeTypeStatus, setUpdateCongeTypeStatus] =
        React.useState(false);
    const handleUpdateConge = (id: any) => {
        console.log("🚀 handleUpdateTicket ~ id:", id);
        loadVacationToUpdate(id);
        setUpdateCongeTypeStatus(true);
    };
    const handleUpdateCongeDesignation = (designation: any) => {
        setDesignationToUpdate(designation);
    };
    const handleUpdateCongeColor = (color: any) => {
        setColorToUpdate(color);
    };

    const handleSubmitUpdatedCongeType = () => {
        updateSelectedVacation();
        setUpdateCongeTypeStatus(false);
    };
    const handleCancelUpdatedCongeType = () => {
        setUpdateCongeTypeStatus(false);
    };

    const handleDeleteConge = async (id: any) => {
        console.log("before loading", id);
        await loadVacationToUpdate(id);
        console.log("after loading", id);
        await deleteSelectedVacation();
        console.log("after deleting", id);
    };

    const [createCongeTypeStatus, setCreateCongeTypeStatus] =
        React.useState(false);
    const handleCreateCongeDesignation = (designation: any) => {
        setDesignationToCreate(designation);
    };
    const handleCreateCongeColor = (color: any) => {
        setColorToCreate(color);
    };
    const handleCreateNewCongeType = () => {
        submitNewVacation();
        setCreateCongeTypeStatus(false);
    };
    const handleCancelNewCongeType = () => {
        setCreateCongeTypeStatus(false);
    };

    return (
        <div className="vacation-types-container">
            <TicketsList
                tickets={data}
                handleTicketEdit={handleUpdateConge}
                handleTicketDelete={handleDeleteConge}
                isCreatePopup={createCongeTypeStatus}
                isUpdatePopup={updateCongeTypeStatus}
                setIsCreatePopup={setCreateCongeTypeStatus}
                setIsUpdatePopup={setUpdateCongeTypeStatus}
                createPopupCard={
                    <NewTicketCard
                        header="Ajouter une étiquette"
                        inputOnChange={handleCreateCongeDesignation}
                        onSelectionColor={handleCreateCongeColor}
                        onSubmitButton={handleCreateNewCongeType}
                        onClose={handleCancelNewCongeType}
                        onCancelButton={handleCancelNewCongeType}
                        colors={listOfColors()}
                        displayColorsCount={listOfColors().length}
                    />
                }
                UpdatePopupCard={
                    <NewTicketCard
                        header="Editer une étiquette"
                        inputValue={selectedConge.designation}
                        selectionColor={selectedConge.color}
                        inputOnChange={handleUpdateCongeDesignation}
                        onSelectionColor={handleUpdateCongeColor}
                        onSubmitButton={handleSubmitUpdatedCongeType}
                        onClose={handleCancelUpdatedCongeType}
                        onCancelButton={handleCancelUpdatedCongeType}
                        colors={listOfColors()}
                        displayColorsCount={listOfColors().length}
                    />
                }
            />
        </div>
    );
}

function VacationPolitics(props: any) {
    const {
        rules,
        loadVacationRules,
        loadRule,
        ruleOperation,
        setRuleDesignation,
        setRuleBaseWorkDays,
        setRuleBaseEntitlement,
        setRuleRestrictedBypassLimitDays,
        updateRule,
        deleteRule,
        loadAffectationRule,
        setRuleUsers,
        assignUsersToRule,
        resetRuleOperation,
    } = useVacationRule();

    const { getEmployees, employees, onUpdateFilterBy, filterBy } =
        useEmployees();

    const [users, setUsers] = React.useState<any>([]);
    const [byPassEnabled, setByPassEnabled] = React.useState(false);

    const [displayAffectation, setDisplayAffectation] = React.useState(false);
    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [isAllSelected, setIsAllSelected] = React.useState(false);

    React.useEffect(() => {
        console.log("React.useEffect");
        loadVacationRules();
        getEmployees({ archived: false });
    }, []);

    React.useEffect(() => {
        if (employees && ruleOperation) {
            setUsers(
                employees
                    .map((emp) => {
                        if (
                            ruleOperation.users &&
                            ruleOperation.users.includes(emp._id)
                        ) {
                            return {
                                _id: emp._id,
                                fname: emp.fname,
                                lname: emp.lname,
                                picture: ROUTER.STATIC_IMG(emp.picture),
                                checked: true,
                            };
                        } else {
                            return {
                                _id: emp._id,
                                fname: emp.fname,
                                lname: emp.lname,
                                picture: ROUTER.STATIC_IMG(emp.picture),
                                checked: false,
                            };
                        }
                    })
                    .sort((a, b) => {
                        // Sort by "checked" property (true values come first)
                        if (a.checked && !b.checked) return -1;
                        if (!a.checked && b.checked) return 1;

                        // If "checked" values are the same, sort alphabetically by "fname" and "lname"
                        const aName = `${a.fname} ${a.lname}`;
                        const bName = `${b.fname} ${b.lname}`;

                        return aName.localeCompare(bName);
                    }) ?? [],
            );
        }
    }, [employees, ruleOperation]);

    React.useEffect(() => {
        if (ruleOperation) {
            setByPassEnabled(ruleOperation.restrictedBypassLimitDays > 0);
        }
    }, [ruleOperation]);

    React.useEffect(() => {
        if (isAllSelected) {
            setUsers(
                users.map((user: any) => {
                    return {
                        ...user,
                        checked: isAllSelected,
                    };
                }),
            );
        }
    }, [isAllSelected]);

    React.useEffect(() => {
        if (ruleOperation) {
            if (byPassEnabled === false) {
                setRuleRestrictedBypassLimitDays(-1);
            } else {
                const selectedRule = rules.find(
                    (rule: any) => rule._id === ruleOperation._id,
                );
                if (selectedRule) {
                    console.log("selectedRule ", selectedRule);
                    setRuleRestrictedBypassLimitDays(
                        selectedRule.restrictedBypassLimitDays,
                    );
                }
            }
        }
    }, [byPassEnabled]);

    const handleSelectAllAffectationUser = () => {
        if (!isAllSelected) {
            setRuleUsers(users.map((user: any) => user._id));
        } else {
            setRuleUsers([]);
        }
        setIsAllSelected(!isAllSelected);
    };
    const handleSelectAffectationUser = (user: any) => {
        if (!user.checked) {
            setRuleUsers([...ruleOperation.users, user._id]);
        } else {
            setRuleUsers(
                ruleOperation.users.filter(
                    (userID: string) => userID !== user._id,
                ),
            );
        }
        console.log("handle ", user);
    };
    const handleSubmitAffectationComponent = async () => {
        await assignUsersToRule();
        await setDisplayAffectation(false);
    };
    const handleCloseAffectationComponent = () => {
        setDisplayAffectation(false);
        setIsAllSelected(false);
    };
    const handleSearchUsers = (text: string) => {
        onUpdateFilterBy({
            ...filterBy,
            name: text,
        });
    };
    const AffectationComponent = () => {
        console.log("affectation users ", users);
        return (
            <Modal
                modalEnabled={displayAffectation}
                onClose={handleCloseAffectationComponent}
            >
                <PopUpCard
                    display={displayAffectation}
                    onClose={handleCloseAffectationComponent}
                    header="Selectionner les employees"
                    widthCloseIcon="24px"
                    containerWidth="480px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleCloseAffectationComponent}
                    button2Text="valider"
                    onClickButton2={handleSubmitAffectationComponent}
                >
                    <div className="employees-section">
                        <div className="new-dept-search-bar">
                            <Input
                                captionText={null}
                                borderWidth={0}
                                iconImg={SearchSVG}
                                iconLocation="left"
                                placeholder="Rechercher"
                                inputWidth="70%"
                                style={{
                                    inputStyle: {
                                        backgroundColor: "transparent",
                                        outline: "none",
                                        width: "100%",
                                        padding: "7px 3px 7px 25px",
                                        color: "#8E96C1",
                                    },
                                }}
                                onChange={handleSearchUsers}
                            />
                            <TextData
                                style={{
                                    marginRight: 5,
                                }}
                                width="auto"
                                size="12"
                                variant="regular"
                                fontWeight="600"
                            >
                                Tous
                            </TextData>
                            <Checkbox
                                onClick={handleSelectAllAffectationUser}
                                id="all"
                                text={null}
                                rounded={true}
                                checked={isAllSelected}
                            />
                        </div>
                        <div className="new-dept-list-of-employees">
                            {users &&
                                users.map((user: any) => {
                                    return (
                                        <div
                                            key={user._id}
                                            className="employee"
                                        >
                                            <div className="information">
                                                <Picture
                                                    imgPath={user.picture}
                                                    className={["rounded-full"]}
                                                    width="40px"
                                                    height="40px"
                                                />
                                                <TextData
                                                    variant="label"
                                                    width="auto"
                                                    size="14px"
                                                    fontWeight="bolder"
                                                    color="#101844"
                                                >
                                                    {`${user.fname} ${user.lname}`}
                                                </TextData>
                                            </div>
                                            <div className="employee-selection">
                                                <Checkbox
                                                    id={user._id}
                                                    text={null}
                                                    rounded={true}
                                                    checked={user.checked}
                                                    onClick={() =>
                                                        handleSelectAffectationUser(
                                                            user,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </PopUpCard>
            </Modal>
        );
    };

    const handleSubmitEdit = () => {
        updateRule();
        loadVacationRules();
        setDisplayEdit(false);
    };

    const handleCancelEdit = () => {
        setDisplayEdit(false);
    };

    const handleActivationByPasslimit = () => {
        setByPassEnabled(!byPassEnabled);
    };
    const MonthlyLimitActivation = () => {
        if (byPassEnabled) {
            return (
                <>
                    <Checkbox
                        text="Activer la limite mensuelle"
                        checked={byPassEnabled}
                        onClick={handleActivationByPasslimit}
                        textSize="14px"
                        textColor="#2A346D"
                    />
                    <p>
                        Le nombre maximal de jours de travail par mois est{" "}
                        <input
                            type="number"
                            min={0}
                            style={{
                                display: "inline",
                                width: "40px",
                                border: "1px solid #ecf0f1",
                                borderRadius: "5px",
                                color: "#2A346D",
                            }}
                            value={ruleOperation.restrictedBypassLimitDays}
                            onChange={(e) => {
                                setRuleRestrictedBypassLimitDays(
                                    parseFloat(e.target.value),
                                );
                            }}
                        />{" "}
                        jour(s)
                    </p>
                </>
            );
        } else {
            return (
                <Checkbox
                    text="Activer la limite mensuelle"
                    checked={byPassEnabled}
                    onClick={handleActivationByPasslimit}
                    textSize="14px"
                    textColor="#2A346D"
                />
            );
        }
    };
    const EditComponent = () => {
        return (
            <Modal
                modalEnabled={displayEdit}
                onClose={() => setDisplayEdit(false)}
            >
                <PopUpCard
                    display={displayEdit}
                    onClose={() => setDisplayEdit(false)}
                    header="Modifier la régle"
                    widthCloseIcon="24px"
                    containerWidth="480px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuller"
                    onClickButton1={handleCancelEdit}
                    button2Text="valider"
                    onClickButton2={handleSubmitEdit}
                >
                    <div className="new-conge-rule-container">
                        <Input
                            captionText="Designation"
                            captionColor="#8E96C1"
                            captionSize="16px"
                            borderColor="#C7CBE2"
                            borderWidth={1}
                            textSize={14}
                            textColor="#2A346D"
                            inputRadius="4px"
                            placeholder=""
                            inputWidth="100%"
                            onChange={setRuleDesignation}
                            value={ruleOperation.designation}
                        />
                        <p>
                            L'employé a droit à{" "}
                            <input
                                type="number"
                                style={{
                                    display: "inline",
                                    width: "50px",
                                    border: "1px solid #ecf0f1",
                                    borderRadius: "5px",
                                    color: "#2A346D",
                                }}
                                step={0.25}
                                value={ruleOperation.baseEntitlement}
                                onChange={(e: any) =>
                                    setRuleBaseEntitlement(
                                        parseFloat(e.target.value),
                                    )
                                }
                            />{" "}
                            jour(s) de congé pour chaque période de travail de{" "}
                            <input
                                type="number"
                                style={{
                                    display: "inline",
                                    width: "50px",
                                    border: "1px solid #ecf0f1",
                                    borderRadius: "5px",
                                    color: "#2A346D",
                                }}
                                step={0.25}
                                value={ruleOperation.baseWorkDays}
                                onChange={(e: any) =>
                                    setRuleBaseWorkDays(
                                        parseFloat(e.target.value),
                                    )
                                }
                            />{" "}
                            jour(s)
                        </p>
                    </div>
                    <div className="new-conge-rule-container">
                        {MonthlyLimitActivation()}
                    </div>
                </PopUpCard>
            </Modal>
        );
    };
    const handleDeleteRule = async (ruleID: any) => {
        deleteRule(ruleID);
        console.log("🚀 handleDeleteRule ~ rule:", ruleID);
    };
    const handleUpdateRule = (ruleID: any) => {
        loadRule(ruleID);
        setDisplayEdit(true);
    };
    const handleAffectRule = (ruleID: any) => {
        console.log("handleAffectRule");
        loadAffectationRule(ruleID);
        setDisplayAffectation(true);
    };
    if (rules && rules.length > 0) {
        return (
            <div className="vacation-politic-container">
                {displayAffectation && AffectationComponent()}
                {displayEdit && EditComponent()}
                {rules.map((rule: any) => {
                    const {
                        _id,
                        designation,
                        baseEntitlement,
                        baseWorkDays,
                        restrictedBypassLimitDays,
                    } = rule;
                    return (
                        <div className="politic-box-container">
                            <div className="left">
                                <ColoredCircle
                                    name="test"
                                    color="#2A8BAB"
                                    outlineColor="#2A8BAB99"
                                    size={10}
                                    outlineSize={4}
                                />
                                <p>
                                    Politique <b>{designation}</b> : L'employé a
                                    droit à <b>{baseEntitlement}</b> jour(s) de
                                    congé pour chaque période de travail de{" "}
                                    <b>{baseWorkDays}</b> jour(s).
                                    {restrictedBypassLimitDays &&
                                    restrictedBypassLimitDays > 0
                                        ? `[Max. ${restrictedBypassLimitDays} jour(s)]`
                                        : ""}
                                </p>
                            </div>
                            <div className="right">
                                <Button
                                    style={{
                                        backgroundColor: "#2A8BAB",
                                        color: "white",
                                        fontSize: "14px",
                                    }}
                                    backgroundColor="#2A8BAB"
                                    textColor="white"
                                    width="100px"
                                    height="30px"
                                    onClick={() => handleUpdateRule(_id)}
                                >
                                    edit
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#EDEFF5",
                                        color: "#2A346D",
                                        fontSize: "14px",
                                    }}
                                    backgroundColor="#EDEFF5"
                                    textColor="#2A346D"
                                    width="100px"
                                    height="30px"
                                    onClick={() => handleDeleteRule(_id)}
                                >
                                    supprimer
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#2A8BAB",
                                        color: "white",
                                        fontSize: "14px",
                                    }}
                                    backgroundColor="#5BB3D0"
                                    textColor="white"
                                    width="100px"
                                    height="30px"
                                    onClick={() => handleAffectRule(_id)}
                                >
                                    affectation
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    textAlign: "center",
                }}
            >
                <h2 style={{ color: "#2a346d", fontWeight: "500" }}>
                    Aucune politique spécifique n'a été définie pour
                    l'affectation des soldes de congés
                </h2>
            </div>
        );
    }
}

export const VacationProps = () => {
    const {
        vacations,
        selectedVacation,
        vacationSetting,
        getVacationSettings,
        updateVacationSettings,
    } = useVacation();
    const { selectedBranchSettings } = useBranch();

    const {
        ruleOperation,
        createRule,
        setRuleDesignation,
        setRuleBaseEntitlement,
        setRuleBaseWorkDays,
        setRuleUsers,
        setRuleRestrictedBypassLimitDays,
        resetRuleOperation,
        loadVacationRules,
    } = useVacationRule();
    const { getEmployees, employees, onUpdateFilterBy, filterBy } =
        useEmployees();
    const [users, setUsers] = React.useState<any>([]);
    const [isAllSelected, setIsAllSelected] = React.useState(false);
    React.useEffect(() => {
        getEmployees({ archived: false });
    }, []);
    React.useEffect(() => {
        if (employees) {
            setUsers(
                employees
                    .map((emp) => {
                        return {
                            _id: emp._id,
                            fname: emp.fname,
                            lname: emp.lname,
                            picture: ROUTER.STATIC_IMG(emp.picture),
                            checked: false,
                        };
                    })
                    .sort((a, b) => {
                        const aName = `${a.fname} ${a.lname}`;
                        const bName = `${b.fname} ${b.lname}`;
                        return aName.localeCompare(bName);
                    }) ?? [],
            );
        }
    }, [employees]);
    React.useEffect(() => {
        if (isAllSelected != undefined) {
            setUsers(
                users.map((user: any) => {
                    return {
                        ...user,
                        checked: isAllSelected,
                    };
                }),
            );
        }
    }, [isAllSelected]);
    const [displayAddNewRule, setDisplayAddNewRule] = React.useState(false);
    const [activateMonthlyBypass, setActivateMonthlyBypass] =
        React.useState(false);
    React.useEffect(() => {
        if (displayAddNewRule && activateMonthlyBypass === false) {
            setRuleRestrictedBypassLimitDays(-1);
        }
    }, [activateMonthlyBypass]);

    React.useEffect(() => {
        if (selectedBranchSettings._id) {
            getVacationSettings();
        }
    }, [selectedBranchSettings]);

    const handleAddNewRule = () => {
        console.log("🚀 handleAddNewRule:");
        setDisplayAddNewRule(true);
    };
    const handleSubmitNewRule = () => {
        createRule();
        resetRuleOperation();
        loadVacationRules();
        setDisplayAddNewRule(false);
    };
    const handleCancelNewRule = () => {
        setDisplayAddNewRule(false);
    };
    const AddNewRuleComponent = () => {
        const Activation = () => {
            if (activateMonthlyBypass) {
                return (
                    <>
                        <Checkbox
                            text="Activer la limite mensuelle"
                            checked={activateMonthlyBypass}
                            onClick={() => setActivateMonthlyBypass(false)}
                            textSize="14px"
                            textColor="#2A346D"
                        />
                        <p>
                            Le nombre maximal de jours de travail par mois est{" "}
                            <input
                                type="number"
                                min={0}
                                style={{
                                    display: "inline",
                                    width: "40px",
                                    border: "1px solid #ecf0f1",
                                    borderRadius: "5px",
                                    color: "#2A346D",
                                }}
                                onChange={(e) => {
                                    setRuleRestrictedBypassLimitDays(
                                        parseFloat(e.target.value),
                                    );
                                }}
                            />{" "}
                            jour(s)
                        </p>
                    </>
                );
            } else {
                return (
                    <Checkbox
                        text="Activer la limite mensuelle"
                        checked={activateMonthlyBypass}
                        onClick={() => setActivateMonthlyBypass(true)}
                        textSize="14px"
                        textColor="#2A346D"
                    />
                );
            }
        };

        const handleSearchUsers = (text: string) => {
            onUpdateFilterBy({
                ...filterBy,
                name: text,
            });
        };
        const handleSelectAllAffectationUser = () => {
            if (!isAllSelected) {
                setRuleUsers(users.map((user: any) => user._id));
            } else {
                setRuleUsers([]);
            }
            setIsAllSelected(!isAllSelected);
        };
        const handleSelectAffectationUser = (user: any) => {
            if (!user.checked) {
                setRuleUsers([...ruleOperation.users, user._id]);
            } else {
                setRuleUsers(
                    ruleOperation.users.filter(
                        (userID: string) => userID !== user._id,
                    ),
                );
            }
        };
        return (
            <Modal
                modalEnabled={displayAddNewRule}
                onClose={() => setDisplayAddNewRule(false)}
            >
                <PopUpCard
                    display={displayAddNewRule}
                    onClose={() => setDisplayAddNewRule(false)}
                    header="Ajouter une régle"
                    widthCloseIcon="24px"
                    containerWidth="480px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuller"
                    onClickButton1={handleCancelNewRule}
                    button2Text="valider"
                    onClickButton2={handleSubmitNewRule}
                >
                    <div className="new-conge-rule-container">
                        <Input
                            captionText="Designation"
                            captionColor="#8E96C1"
                            captionSize="16px"
                            borderColor="#C7CBE2"
                            borderWidth={1}
                            textSize={14}
                            textColor="#2A346D"
                            inputRadius="4px"
                            placeholder=""
                            inputWidth="100%"
                            onChange={setRuleDesignation}
                        />
                        <p>
                            L'employé a droit à{" "}
                            <input
                                type="number"
                                style={{
                                    display: "inline",
                                    width: "40px",
                                    border: "1px solid #ecf0f1",
                                    borderRadius: "5px",
                                    color: "#2A346D",
                                }}
                                step={0.5}
                                onChange={(e) =>
                                    setRuleBaseEntitlement(
                                        parseFloat(e.target.value),
                                    )
                                }
                            />{" "}
                            jour(s) de congé pour chaque période de travail de{" "}
                            <input
                                type="number"
                                style={{
                                    display: "inline",
                                    width: "40px",
                                    border: "1px solid #ecf0f1",
                                    borderRadius: "5px",
                                    color: "#2A346D",
                                }}
                                step={0.5}
                                onChange={(e) =>
                                    setRuleBaseWorkDays(
                                        parseFloat(e.target.value),
                                    )
                                }
                            />{" "}
                            jour(s)
                        </p>
                    </div>
                    <div className="new-conge-rule-container">
                        {Activation()}
                    </div>
                    <div className="employees-section">
                        <div className="new-dept-search-bar">
                            <Input
                                captionText={null}
                                borderWidth={0}
                                iconImg={SearchSVG}
                                iconLocation="left"
                                placeholder="Rechercher"
                                inputWidth="70%"
                                style={{
                                    inputStyle: {
                                        backgroundColor: "transparent",
                                        outline: "none",
                                        width: "100%",
                                        padding: "7px 3px 7px 25px",
                                        color: "#8E96C1",
                                    },
                                }}
                                onChange={handleSearchUsers}
                            />
                            <TextData
                                style={{
                                    marginRight: 5,
                                }}
                                width="auto"
                                size="12"
                                variant="regular"
                                fontWeight="600"
                            >
                                Tous
                            </TextData>
                            <Checkbox
                                onClick={handleSelectAllAffectationUser}
                                id="all"
                                text={null}
                                rounded={true}
                                checked={isAllSelected}
                            />
                        </div>
                        <div className="new-dept-list-of-employees">
                            {users &&
                                users.map((user: any) => {
                                    return (
                                        <div
                                            key={user._id}
                                            className="employee"
                                        >
                                            <div className="information">
                                                <Picture
                                                    imgPath={user.picture}
                                                    className={["rounded-full"]}
                                                    width="40px"
                                                    height="40px"
                                                />
                                                <TextData
                                                    variant="label"
                                                    width="auto"
                                                    size="14px"
                                                    fontWeight="bolder"
                                                    color="#101844"
                                                >
                                                    {`${user.fname} ${user.lname}`}
                                                </TextData>
                                            </div>
                                            <div className="employee-selection">
                                                <Checkbox
                                                    id={user._id}
                                                    text={null}
                                                    rounded={true}
                                                    checked={user.checked}
                                                    onClick={() =>
                                                        handleSelectAffectationUser(
                                                            user,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </PopUpCard>
            </Modal>
        );
    };
    const VacationIsActivated = () => {
        if (vacationSetting && vacationSetting.enableLeaveRuleEntitlement) {
            return [
                {
                    optionTitle: "Activer le module congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Activez les demandes de congés via l'application pour les employés, facilitant ainsi le processus de demande et de gestion des congés. ",

                    optionInput: (
                        <ToggleButton
                            isChecked={
                                vacationSetting.enableLeaveRuleEntitlement
                            }
                            onClick={(e: any) => {
                                console.log("checked ", e.target.checked);
                                updateVacationSettings({
                                    enableLeaveRuleEntitlement:
                                        e.target.checked,
                                });
                            }}
                        />
                    ),
                },
                {
                    optionTitle: "Le jour de calcul de solde de conges",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: (
                        <p
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                color: "#2a346d",
                            }}
                        >
                            Le jour
                            <Input
                                value={
                                    vacationSetting.entitlementCalculationDate
                                }
                                type="number"
                                captionText={null}
                                inputWidth="50px"
                                onBlur={(data: any) => {
                                    if (
                                        data !=
                                            vacationSetting.entitlementCalculationDate &&
                                        data > 0 &&
                                        data <= 31
                                    ) {
                                        updateVacationSettings({
                                            entitlementCalculationDate: data,
                                        });
                                    }
                                }}
                            />
                            du chaque mois
                        </p>
                    ),
                },
                {
                    optionTitle: "Les types de conges",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",

                    optionInput: null,
                    optionInput2: (
                        <VacationList
                            vacations={vacations}
                            selectedVacation={selectedVacation}
                        />
                    ),
                },
                {
                    optionTitle: "Administration des politiques de congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor",
                    optionInput: (
                        <>
                            {displayAddNewRule && AddNewRuleComponent()}
                            <Button
                                style={{
                                    backgroundColor: "#2A8BAB",
                                    color: "white",
                                    fontSize: "14px",
                                }}
                                backgroundColor="#2A8BAB"
                                textColor="white"
                                width="150px"
                                height="30px"
                                onClick={handleAddNewRule}
                            >
                                Ajouter une régle
                            </Button>
                        </>
                    ),
                    optionInput2: <VacationPolitics />,
                },
            ];
        } else {
            return [
                {
                    optionTitle: "Activer le module congés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Activez les demandes de congés via l'application pour les employés, facilitant ainsi le processus de demande et de gestion des congés. ",

                    optionInput: (
                        <ToggleButton
                            isChecked={
                                vacationSetting &&
                                vacationSetting.enableLeaveRuleEntitlement ===
                                    true
                            }
                            onClick={(e: any) => {
                                console.log("checked ", e.target.checked);
                                updateVacationSettings({
                                    enableLeaveRuleEntitlement:
                                        e.target.checked,
                                });
                            }}
                        />
                    ),
                },
            ];
        }
    };
    return [
        {
            headerTitle: "Les paramètres de conges",
            headerDescription:
                "Configurez les règles et les options liées aux congés pour les employés. ",
            configOptions: VacationIsActivated(),
        },
    ];
};
