import React from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import Button from "../../atoms/ZButton";
import ContractTypes from "../../organism/ContractTypes";
import DatepickerInput from "../../atoms/DatepickerInput";
import ZInput from "../../atoms/ZInput";
import ZCheckBox from "../../atoms/ZCheckbox";
import EmployeLevel from "../../molecules/EmployeeLevel";
import Echelon from "../../molecules/Echelon";
import { Trash } from "../../../Icons";
import { qualifications } from "../../../Options";
import Modal from "../../atoms/Modal";
import TextData from "../../atoms/TextData";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { useBranch } from "../../../hooks/useBranch";
import Swal from "sweetalert2";
import { API, ROUTER } from "../../../constants/env";
import Radio from "../../atoms/Radio";
import moment from "moment";
import { useLogin } from "../../../hooks/useLogin";
import { useAdministrative } from "../../../hooks/useAdministrative";
import Input from "../../atoms/Input";
import SelectInput from "../../atoms/SelectInput";
import { useEmployees } from "../../../hooks/useEmployees";

export default function ContractEdit({
    contract,
    onChange,
    onBlur,
    openDeleteContractDialog,
    onSubmit,
    actionLoading,
}: ContractEditInterface) {
    const { selectedBranchSettings } = useBranch();
    const { administrative, getAdministrative } = useAdministrative();
    const { user } = useLogin();
    const { employeeForEdit } = useEmployees();
    const intl = useIntl();
    const checkError = () => {
        let error = false;
        Object.keys(contract).forEach((key) => {
            if (contract[key].hasError) {
                error = true;
            }
        });
        return error;
    };
    React.useEffect(() => {
        getAdministrative();
    }, []);

    const [contractModelDisplay, setContractModelDisplay] =
        React.useState(false);
    // prettier-ignore
    const [contractTemplates, setContractTemplates] =
        React.useState<any>(null);

    const generateContract = async (contract: any) => {
        if (selectedBranchSettings._id) {
            let result = await fetch(
                API.client.settings.administrative.getContractTemplates(
                    selectedBranchSettings._id,
                ),
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                },
            );
            if (result && result.status === 200) {
                let data = await result.json();
                setContractTemplates(data);
                setContractModelDisplay(true);
            }
        } else {
            Swal.fire({
                title: "Error,Veuillez selectionner un établissement",
                icon: "error",
            });
        }
    };
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const navigate = useNavigate();
    const navigateToSettings = () => {
        navigate(ROUTER.SETTINGS.ADMINISTRATIVE);
    };
    const SelectContractTemplate = () => {
        const handleSelectTemplate = (templateID: any) => {
            setSelectedTemplate(templateID);
        };
        const handleSubmitTemplate = async () => {
            if (selectedTemplate && selectedBranchSettings._id) {
                let result = await fetch(
                    API.client.contrat.generate(employeeForEdit._id),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            contractTemplateID: selectedTemplate,
                            site: selectedBranchSettings._id,
                        }),
                    },
                );

                if (result && result.status === 200) {
                    let data = await result.json();
                    const contractPath = data.contractDocument;
                    window.open(ROUTER.STATIC_FILE2(contractPath), "_blank");
                } else {
                    Swal.fire({
                        text: "Error occurred",
                        icon: "error",
                    });
                }
            } else {
                Swal.fire({
                    title: "Vous devez sélectionner le modéle de contrat",
                    icon: "error",
                });
            }
        };
        const handleCancelTemplate = () => {
            setSelectedTemplate(null);
            setContractModelDisplay(false);
        };
        return (
            <Modal
                modalEnabled={contractModelDisplay}
                onClose={handleCancelTemplate}
            >
                <div
                    style={{
                        backgroundColor: "white",
                        minWidth: "40vw",
                        height: "auto",
                        borderRadius: "5px",
                        padding: "15px",
                    }}
                >
                    <TextData
                        width="auto"
                        color="#090844"
                        size="22"
                        fontWeight="bold"
                    >
                        Choisir un modèle de contrat
                    </TextData>
                    <div
                        style={{
                            marginTop: "15px",
                        }}
                    >
                        {contractTemplates && contractTemplates?.length > 0 ? (
                            <>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <td>
                                            <div>
                                                <TextData
                                                    variant="label"
                                                    fontWeight="700"
                                                >
                                                    Nom du modèle
                                                </TextData>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <TextData
                                                    variant="label"
                                                    fontWeight="700"
                                                >
                                                    Type
                                                </TextData>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <TextData
                                                    variant="label"
                                                    width="100%"
                                                    fontWeight="700"
                                                >
                                                    Date d'ajout
                                                </TextData>
                                            </div>
                                        </td>
                                    </tr>
                                    {contractTemplates.map(
                                        (template: any, index: number) => {
                                            return (
                                                <tr
                                                    style={{
                                                        backgroundColor:
                                                            index % 2
                                                                ? "#dfe6e9"
                                                                : "white",
                                                    }}
                                                >
                                                    <td itemID="name">
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "normal",
                                                                gap: "7px",
                                                                paddingLeft:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <input
                                                                id={`template_${template._id}`}
                                                                type="radio"
                                                                name="contractTemplate"
                                                                value={
                                                                    template._id
                                                                }
                                                                onClick={() =>
                                                                    handleSelectTemplate(
                                                                        template._id,
                                                                    )
                                                                }
                                                            />
                                                            <TextData
                                                                variant="label"
                                                                forId={`template_${template._id}`}
                                                                size="17px"
                                                                color="#4c5690"
                                                            >
                                                                {template.name}
                                                            </TextData>
                                                        </div>
                                                    </td>

                                                    <td itemID="type">
                                                        <TextData
                                                            variant="div"
                                                            width="100%"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                            size="17px"
                                                            color="#4c5690"
                                                        >
                                                            {template.type}
                                                        </TextData>
                                                    </td>

                                                    <td itemID="date">
                                                        <TextData
                                                            variant="div"
                                                            width="100%"
                                                            size="17px"
                                                            color="#4c5690"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            {moment(
                                                                template.created_at,
                                                            ).format(
                                                                "DD-MM-YYYY",
                                                            )}
                                                        </TextData>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                    <tr>
                                        <div
                                            style={{
                                                marginBottom: "15px",
                                            }}
                                        ></div>
                                    </tr>
                                </table>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        gap: "10px",
                                        borderTop: "1px solid #ecf0f1",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <Button
                                        title="Annuler"
                                        onClick={handleCancelTemplate}
                                    />
                                    <Button
                                        variant={"primary"}
                                        title="Générer"
                                        onClick={handleSubmitTemplate}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        backgroundColor: "#2980b919",
                                        padding: "10px 15px",

                                        maxWidth: "40vw",
                                    }}
                                >
                                    <TextData variant="div" color="#2980b9">
                                        Il est nécessaire d'ajouter des modèles
                                        de contrat via la page des paramètres.
                                    </TextData>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: "#2980b9",
                                                color: "white",
                                            }}
                                            onClick={navigateToSettings}
                                            title="Accéder aux paramètres"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        );
    };
    React.useEffect(() => {
        if (contract.type.value === "CDI") {
            onChange("dateFinContract", "");
        }
    }, [contract.type]);
    return (
        <div>
            {/* Header */}
            <div className="flex justify-between py-5 border-b">
                <h2 className="text-v-800 font-bold text-9xl">
                    {intl.formatMessage({
                        id: "EMPLOYEE.EDIT.CONTRACTS.TITLE",
                    })}
                </h2>
                {contract._id ? (
                    <div style={{ display: "flex", gap: 12 }}>
                        {administrative?.enableContractTemplates && (
                            <>
                                {SelectContractTemplate()}
                                <Button
                                    title="Générer un contrat"
                                    // leftComponent={<Trash />}
                                    size={"sm"}
                                    variant={"primary"}
                                    onClick={() => generateContract(contract)}
                                />
                            </>
                        )}
                        {/* <Button
                            title={intl.formatMessage({
                                id: "EMPLOYEE.EDIT.CONTRACTS.END_CONTRACT",
                            })}
                            leftComponent={<Trash />}
                            size={"sm"}
                            variant={"danger"}
                            onClick={() => openDeleteContractDialog(contract)}
                        /> */}
                    </div>
                ) : (
                    <></>
                )}
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3">
                <div className="col-span-2 lg:border-r">
                    <div className="lg:pr-8 pt-8">
                        <h3 className="text-3xl text-n-700 font-bold">
                            {intl.formatMessage({
                                id: "EMPLOYEE.EDIT.CONTRACTS.CONTRACTS_AND_PAIMENTS",
                            })}
                        </h3>
                        <ContractTypes
                            value={contract.type.value}
                            onChange={(value) => onChange("type", value)}
                        />
                        {contract.type.value === "Intérim" && (
                            <div>
                                <ZInput
                                    required={false}
                                    field={contract.plusInterim}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.EDIT.INTERIM.LABEL",
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: "CONTRACT.EDIT.INTERIM.PLACEHOLDER",
                                    })}
                                    onUpdate={(value) =>
                                        onChange("plusInterim", value)
                                    }
                                />
                            </div>
                        )}
                        {contract.type.value === "Stagiaire" && (
                            <div>
                                <ZInput
                                    required={false}
                                    field={contract.plusStagiaire}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.EDIT.STAGIERE.LABEL",
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: "CONTRACT.EDIT.STAGIERE.PLACEHOLDER",
                                    })}
                                    onUpdate={(value) =>
                                        onChange("plusStagiaire", value)
                                    }
                                />
                            </div>
                        )}
                        <div className="flex gap-4 my-5">
                            <div className="basis-[100px] grow">
                                <DatepickerInput
                                    label={intl.formatMessage({
                                        id: "CONTRACT.START_DATE",
                                    })}
                                    required={false}
                                    field={contract.dateDebutContract}
                                    onBlur={() => {
                                        onBlur("dateDebutContract");
                                    }}
                                    onUpdate={(value) => {
                                        onChange("dateDebutContract", value);
                                    }}
                                    rup={true}
                                />
                            </div>
                            <div className="basis-[100px] grow">
                                <Input
                                    type="time"
                                    inputWidth="100%"
                                    inputHeight="100%"
                                    style={{
                                        captionStyle: {
                                            marginBottom: "4px",
                                        },
                                        inputStyle: {
                                            height: "100%",
                                        },
                                    }}
                                    captionColor="#8e96c1"
                                    borderColor="#c7cbe2"
                                    borderWidth={1}
                                    captionSize="12px"
                                    captionText="Heure de début de contract"
                                    value={contract.heureDebutContract.value}
                                    onBlur={() => {
                                        onBlur("heureDebutContract");
                                    }}
                                    onChange={(value: any) => {
                                        onChange("heureDebutContract", value);
                                    }}
                                />
                                {/* <DatepickerInput
                                    label='Heure de début de contract'
                                    required={false}
                                    field={contract.heureDebutContract}
                                    onBlur={() => {
                                        onBlur("heureDebutContract");
                                    }}
                                    onUpdate={(value) => {
                                        onChange("heureDebutContract", value);
                                    }}
                                    rup={true}
                                /> */}
                            </div>
                            <div className="basis-[100px] grow">
                                <DatepickerInput
                                    desactivated={
                                        contract.type.value === null ||
                                        contract.type.value === undefined ||
                                        contract.type.value === "" ||
                                        (contract.type.value !== "CDI" &&
                                            contract.type.value !== "CDD")
                                    }
                                    label={intl.formatMessage({
                                        id: "CONTRACT.TRIAL.END_DATE",
                                    })}
                                    required={false}
                                    field={contract.dateFinPerideEssai}
                                    onBlur={() => {
                                        onBlur("dateFinPerideEssai");
                                    }}
                                    onUpdate={(value) => {
                                        onChange("dateFinPerideEssai", value);
                                    }}
                                />
                            </div>
                            <div className="basis-[100px] grow">
                                <DatepickerInput
                                    label={intl.formatMessage({
                                        id: "CONTRACT.END_DATE",
                                    })}
                                    // desactivated={contract.type.value === "CDI"}
                                    required={false}
                                    field={contract.dateFinContract}
                                    onBlur={() => {
                                        onBlur("dateFinContract");
                                    }}
                                    onUpdate={(value) => {
                                        onChange("dateFinContract", value);
                                    }}
                                    rup={true}
                                />
                            </div>
                        </div>
                        <div className="flex gap-4 my-5">
                            <div className="basis-[100px] grow">
                                <ZInput
                                    required={false}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.GROSS_SALARY",
                                    })}
                                    field={contract.salaireBrutMensuel}
                                    onBlur={onBlur}
                                    onUpdate={(value) => {
                                        onChange("salaireBrutMensuel", value);
                                    }}
                                />
                            </div>
                            <div className="basis-[100px] grow">
                                <ZInput
                                    required={false}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.GROSS_HOURS",
                                    })}
                                    field={contract.tauxHoraireBrut}
                                    onBlur={onBlur}
                                    onUpdate={(value) => {
                                        onChange("tauxHoraireBrut", value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-4 my-5">
                            <div className="basis-[100px] grow">
                                <ZInput
                                    required={false}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.DAYS_PER_WEEK",
                                    })}
                                    field={contract.joursParSemaine}
                                    onBlur={onBlur}
                                    onUpdate={(value) => {
                                        onChange("joursParSemaine", value);
                                    }}
                                />
                            </div>
                            <div className="basis-[100px] grow">
                                <ZInput
                                    required={false}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.EXTRA_HOURS",
                                    })}
                                    field={contract.heureSupplementaire}
                                    onBlur={onBlur}
                                    onUpdate={(value) => {
                                        onChange("heureSupplementaire", value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-4 my-5">
                            <div className="basis-[100px] grow">
                                <DatepickerInput
                                    label={intl.formatMessage({
                                        id: "CONTRACT.LAST_MEDICAL_VISIT",
                                    })}
                                    required={false}
                                    field={contract.derniereVisiteMedicale}
                                    onBlur={onBlur}
                                    onUpdate={(value) => {
                                        onChange(
                                            "derniereVisiteMedicale",
                                            value,
                                        );
                                    }}
                                />
                            </div>
                            <div className="basis-[100px] grow">
                                <ZInput
                                    required={false}
                                    label={intl.formatMessage({
                                        id: "CONTRACT.TRANSPORTATION_ALLOWANCE",
                                    })}
                                    field={contract.indemniteDeTransport}
                                    onBlur={onBlur}
                                    onUpdate={(value) => {
                                        onChange("indemniteDeTransport", value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-2 items-center my-5">
                            <ZCheckBox
                                isChecked={
                                    contract.statusTravailleurEtranger.value
                                }
                                onPress={() => {
                                    onChange(
                                        "statusTravailleurEtranger",
                                        !contract.statusTravailleurEtranger
                                            .value,
                                    );
                                }}
                            />
                            <label className="text-n-500 font-bold text-lg">
                                {intl.formatMessage({
                                    id: "CONTRACT.FOREIGN_WORKER",
                                })}
                            </label>
                        </div>
                        {contract.statusTravailleurEtranger.value ? (
                            <div className="flex gap-4 my-5">
                                <div className="basis-[100px] grow">
                                    <ZInput
                                        required={false}
                                        label={intl.formatMessage({
                                            id: "CONTRACT.FOREIGN_DOCUMENT_TYPE.LABEL",
                                        })}
                                        field={
                                            contract.typeDocumentTravailleurEtranger
                                        }
                                        onBlur={onBlur}
                                        onUpdate={(value) => {
                                            onChange(
                                                "typeDocumentTravailleurEtranger",
                                                value,
                                            );
                                        }}
                                    />
                                </div>
                                <div className="basis-[100px] grow">
                                    <ZInput
                                        required={false}
                                        label={intl.formatMessage({
                                            id: "CONTRACT.FOREIGN_DOCUMENT_NUMBER",
                                        })}
                                        field={
                                            contract.numDocumentTravailleurEtranger
                                        }
                                        onBlur={onBlur}
                                        onUpdate={(value) => {
                                            onChange(
                                                "numDocumentTravailleurEtranger",
                                                value,
                                            );
                                        }}
                                    />
                                </div>
                                <div className="basis-[100px] grow">
                                    <DatepickerInput
                                        label={intl.formatMessage({
                                            id: "CONTRACT.FOREIGN_EXPIRECY_DATE",
                                        })}
                                        required={false}
                                        field={
                                            contract.dateExpireTravailleurEtranger
                                        }
                                        onBlur={onBlur}
                                        onUpdate={(value) => {
                                            onChange(
                                                "dateExpireTravailleurEtranger",
                                                value,
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div className="col-span-1 lg:pl-8 pt-8">
                    <h3 className="text-3xl text-n-700 font-bold">
                        {intl.formatMessage({
                            id: "EMPLOYEE.EDIT.CONTRACTS.POST_AND_QUALIFICATION",
                        })}
                    </h3>
                    <div className="my-5">
                        <ZInput
                            required={false}
                            label={intl.formatMessage({
                                id: "CONTRACT.JOB_TITLE.LABEL",
                            })}
                            placeholder={intl.formatMessage({
                                id: "CONTRACT.JOB_TITLE.PLACE_HOLDER",
                            })}
                            field={contract.emploi}
                            onBlur={onBlur}
                            onUpdate={(value) => {
                                onChange("emploi", value);
                            }}
                            rup={true}
                        />
                    </div>
                    <div className="my-5">
                        <label className="text-base font-semibold text-n-400 mb-1">
                            {intl.formatMessage({
                                id: "CONTRACT.JOB_QUALIFICATION",
                            })}
                        </label>
                        <SelectInput
                            title=""
                            style={{
                                height: "48px",
                                borderRadius: "0.375rem",
                            }}
                            placeholder=""
                            options={qualifications}
                            selectedValue={contract.qulification.value}
                            onSelectOption={(value) => {
                                onChange("qulification", value);
                            }}
                        />
                        {/* <SelectInput
                            controlCssStyles={{ height: "48px" }}
                            options={qualifications}
                            value={contract.qulification.value}
                            onChange={(value) => {
                                onChange("qulification", value);
                            }}
                        /> */}
                    </div>
                    <div className="my-5">
                        <EmployeLevel
                            value={contract.niveau.value}
                            onChange={(value) => {
                                onChange("niveau", value);
                            }}
                        />
                    </div>
                    <div className="my-5">
                        <Echelon
                            value={contract.echelon.value}
                            onChange={(value) => {
                                onChange("echelon", value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="py-10 border-t border-n-100 flex justify-end">
                <Button
                    title={
                        contract._id
                            ? intl.formatMessage({ id: "MENU.UPDATE" })
                            : intl.formatMessage({ id: "MENU.ADD" })
                    }
                    variant="primary"
                    size="lg"
                    loading={actionLoading}
                    onClick={onSubmit}
                    disabled={checkError()}
                />
            </div>
        </div>
    );
}

interface ContractEditInterface {
    contract: any;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
    openDeleteContractDialog(contract: any): void;
    onSubmit(): void;
    actionLoading: boolean;
}
