import React from "react";
import TimingComponent from "../../../../components/molecules/TimingComponent";
import moment from "moment";

export const VacationDateColumn = (row: any) => {
    const fromDay = moment(row?.fromDay)?.format("DD/MM/YY");
    const toDay = moment(row?.toDay)?.format("DD/MM/YY");
    return (
        <div>
            <TimingComponent startTime={fromDay} endTime={toDay} />
        </div>
    );
};
