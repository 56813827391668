import { FormattedMessage } from "react-intl";
import { Male, Female } from "../Icons";
import ReactCountryFlag from "react-country-flag";
export const absences = [
    { label: "Repos hebdomadaire", value: "Repos hebdomadaire" },
    { label: "Absence injustifiée", value: "Absence injustifiée" },
    { label: "Absence justifiée", value: "Absence justifiée" },
    { label: "Accident du travail", value: "Accident du travail" },
    { label: "Arrêt maladie", value: "Arrêt maladie" },
    { label: "Chômage technique", value: "Chômage technique" },
    { label: "Congé maternité", value: "Congé maternité" },
    { label: "Congé parental", value: "Congé parental" },
    { label: "Congé paternité", value: "Congé paternité" },
    { label: "Congé payé", value: "Congé payé" },
    { label: "Congé sans solde", value: "Congé sans solde" },
    { label: "Évènement familial", value: "Évènement familial" },
    { label: "Formation", value: "Formation" },
    {
        label: "Indisponibilité ponctuelle",
        value: "Indisponibilité ponctuelle",
    },
    { label: "Jour férié", value: "Jour férié" },
    { label: "Maladie professionnelle", value: "Maladie professionnelle" },
    {
        label: "Maladie non professionnelle",
        value: "Maladie non professionnelle",
    },
    { label: "Mise à pied conservatoire", value: "Mise à pied conservatoire" },
    { label: "Mise à pied disciplinaire", value: "Mise à pied disciplinaire" },
    {
        label: "Repos compensateur d'habillement",
        value: "Repos compensateur d'habillement",
    },
    {
        label: "Repos compensateur de nuit",
        value: "Repos compensateur de nuit",
    },
    { label: "Visite médicale", value: "Visite médicale" },
];

export const genderOptions = [
    {
        label: <FormattedMessage id={"MENU.MALE"} />,
        value: "male",
        icon: <Male />,
    },
    {
        label: <FormattedMessage id={"MENU.FEMALE"} />,
        value: "female",
        icon: <Female />,
    },
];

interface CountryProps {
    name: string;
    code: string;
}

export const getCountriesOptions = (countries: CountryProps[]) => {
    const countriesOptions = countries
        .filter((country) => country.code !== "IL") // exclude I*s*r*a*i*l => LOVE PALESTINE <3
        .map((country) => ({
            label: country.code === "PS" ? "Palestine" : country.name, //write "Palestine" like every country not "Territoire palestinien"
            value: country.code,
            icon: (
                <ReactCountryFlag
                    countryCode={country.code}
                    svg
                    style={{ height: "16px", width: "16px" }}
                />
            ),
        }));
    return [{ label: "", value: undefined }, ...countriesOptions];
};

export const qualifications = [
    {
        label: <FormattedMessage id="QUALIFICATION.EMPLOYEE" />,
        value: "Employé",
    },
    {
        label: <FormattedMessage id="QUALIFICATION.AGENT_CONTROL" />,
        value: "Agent de maitrise",
    },
    { label: <FormattedMessage id="QUALIFICATION.CADRE" />, value: "Cadre" },
];

export const timeOptions = [
    { label: "00:00", value: "00:00" },
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
    { label: "08:30", value: "08:30" },
    { label: "09:00", value: "09:00" },
    { label: "09:30", value: "09:30" },
    { label: "10:00", value: "10:00" },
    { label: "10:30", value: "10:30" },
    { label: "11:00", value: "11:00" },
    { label: "11:30", value: "11:30" },
    { label: "12:00", value: "12:00" },
    { label: "12:30", value: "12:30" },
    { label: "13:00", value: "13:00" },
    { label: "13:30", value: "13:30" },
    { label: "14:00", value: "14:00" },
    { label: "14:30", value: "14:30" },
    { label: "15:00", value: "15:00" },
    { label: "15:30", value: "15:30" },
    { label: "16:00", value: "16:00" },
    { label: "16:30", value: "16:30" },
    { label: "17:00", value: "17:00" },
    { label: "17:30", value: "17:30" },
    { label: "18:00", value: "18:00" },
    { label: "18:30", value: "18:30" },
    { label: "19:00", value: "19:00" },
    { label: "19:30", value: "19:30" },
    { label: "20:00", value: "20:00" },
    { label: "20:30", value: "20:30" },
    { label: "21:00", value: "21:00" },
    { label: "21:30", value: "21:30" },
    { label: "22:00", value: "22:00" },
    { label: "22:30", value: "22:30" },
    { label: "23:00", value: "23:00" },
    { label: "23:30", value: "23:30" },
];

export const extraDaysOptions = [
    { label: "-0.5", value: "-0.5" },
    { label: "0", value: "0" },
    { label: "0.5", value: "0.5" },
    { label: "1", value: "1" },
    { label: "1.5", value: "1.5" },
    { label: "2", value: "2" },
    { label: "2.5", value: "2.5" },
    { label: "3", value: "3" },
];
