import React from "react";
import moment, { Moment } from "moment";
import { DAYS } from "../../../_helpers/CalendarHelpers";

type CalendarType = Array<Array<Moment | null>>;
type RangeType = { from: Moment | null; to: Moment | null };

export default function CalendarBody({
    showWeekCount,
    calendar,
    value,
    from,
    to,
    disabledFrom,
    disabledTo,
    ranged,
    onChange,
}: CalendarBodyInterface) {
    const [range, setRange] = React.useState<RangeType>({
        from: null,
        to: null,
    });

    const isDisabled = (day: Moment) => {
        if (disabledFrom && disabledTo) {
            if (day.isAfter(disabledFrom) && day.isBefore(disabledTo))
                return true;
        } else if (disabledFrom && !disabledTo) {
            if (day.isAfter(disabledFrom)) return true;
        } else if (!disabledFrom && disabledTo) {
            if (day.isBefore(disabledTo)) return true;
        }
        return false;
    };

    const isInRange = (day: Moment) => {
        if (range.from) {
            if (day.isSame(range.from, "day") || day.isSame(range.to, "day")) {
                return " bg-default-active text-white";
            } else if (
                (day.isAfter(range.from) && day.isBefore(range.to)) ||
                (day.isAfter(range.to) && day.isBefore(range.from))
            ) {
                return " bg-border font-semibold";
            } else {
                return "";
            }
        } else if (day.isSame(from, "day") || day.isSame(to, "day")) {
            return " bg-default-active text-white";
        } else if (
            (day.isAfter(from) && day.isBefore(to)) ||
            (day.isAfter(to) && day.isBefore(from))
        ) {
            return " bg-border font-semibold";
        } else {
            return "";
        }
    };

    const getDayClassName = (day: Moment) => {
        let baseClass =
            "cursor-pointer text-sm text-n-700 w-40px h-40px flex justify-center items-center hover:bg-default-active hover:text-white";

        if (ranged) {
            baseClass += isInRange(day);
        } else if (day.isSame(value, "D")) {
            baseClass += " bg-default-active text-white";
        } else if (day.day() == 0) {
            baseClass += " text-error";
        }
        if (isDisabled(day)) {
            baseClass += " opacity-20";
        }
        return baseClass;
    };

    function handleDaySelect(day: Moment | null) {
        if (day && isDisabled(day)) return;
        if (ranged && !range.from) {
            setRange({ from: day, to: null });
            return;
        } else if (ranged && range.from) {
            setRange({ from: range.from, to: day });
            if (onChange) {
                onChange({
                    from: range.from.isBefore(day) ? range.from : day,
                    to: range.from.isAfter(day) ? range.from : day,
                });
                setRange({ from: null, to: null });
            }
            return;
        }
        if (onChange) {
            onChange(day!);
        }
    }

    return (
        <div className="p-3">
            <div className="flex mb-2">
                {showWeekCount && (
                    <span className="w-40px pl-0.5 text-left text-n-700 font-semibold">
                        S
                    </span>
                )}
                {DAYS.map((day, keyD) => (
                    <span
                        key={keyD}
                        className="w-40px text-center text-n-700 font-semibold last:text-error"
                    >
                        {day}
                    </span>
                ))}
            </div>
            {calendar.map((week, keyW) => (
                <div key={keyW} className="flex items-center">
                    {showWeekCount && (
                        <span className="text-sm text-n-700  w-40px h-40px flex  items-center">
                            {moment(week.filter((d) => d !== null)[0]).week()}
                        </span>
                    )}
                    {week.map((day, keyD) => (
                        <div
                            onClick={() => handleDaySelect(day)}
                            key={keyD}
                            className={
                                day ? getDayClassName(day) : "w-40px h-40px "
                            }
                            onMouseEnter={() => {
                                if (range && range.from)
                                    setRange((prev) => ({ ...prev, to: day }));
                            }}
                            onMouseLeave={() => {
                                if (range && range.from)
                                    setRange((prev) => ({ ...prev, to: day }));
                            }}
                        >
                            {day?.format("DD")}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

interface CalendarBodyInterface {
    value?: Moment;
    showWeekCount?: boolean;
    open?: boolean;
    onChange?(value: Moment | RangeType): void;
    from?: Moment | null;
    to?: Moment | null;
    disabledFrom?: Moment | null;
    disabledTo?: Moment | null;
    ranged?: boolean;
    calendar: CalendarType;
}
