import * as React from "react";
import "./style.scss";
import TextData from "../../atoms/TextData";
import ProfilePicture from "../../molecules/ProfilePicture";
import { ROUTER } from "../../../constants/env";
import { ReactComponent as CongeSVG } from "./conge.svg";
import { ReactComponent as IndispoSVG } from "./no-work.svg";

import Profile from "./photo.jpg";
import { employeeRoleColor, formatTime } from "../../../_helpers/Functions";
import { format } from "path";
export default function PlanningEmployeeCell(
    props: any /*PlanningEmployeeCellProps*/,
) {
    const { employee, workedTime, hasIndispo, hasConge } = props;
    const [tempsDeTravailHebdomadaire, setTempsDeTravailHebdomadaire] =
        React.useState(0);

    React.useEffect(() => {
        if (employee?.contract?.tempsDeTravailHebdomadaire) {
            setTempsDeTravailHebdomadaire(
                employee.contract.tempsDeTravailHebdomadaire,
            );
        }
    }, [employee]);

    const [timeDiff, setTimeDiff] = React.useState(
        tempsDeTravailHebdomadaire * 60 - workedTime,
    );
    React.useEffect(() => {
        setTimeDiff(tempsDeTravailHebdomadaire * 60 - workedTime);
    }, [tempsDeTravailHebdomadaire, workedTime]);
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee.picture),
        width: "50px",
        height: "50px",
        style: { borderRadius: "50%" },
    };
    const congeTypes = [
        { label: "Arrêt maladie", value: "arret_maladie" },
        { label: "Congé maternité", value: "conge_maternite" },
        { label: "Congé paternité", value: "conge_paternite" },
        { label: "Congé payé", value: "paid_leave" },
        { label: "Congé sans solde", value: "unpaid_leave" },
        { label: "Évènement familial", value: "family_event" },
    ];
    if (!employee) {
        return null;
    }
    return (
        <div className="planning-employee-cell">
            <div className="employee-photo-container">
                <ProfilePicture
                    profileImage={employee.picture}
                    firstName={employee.fname}
                    lastName={employee.lname}
                    imageWidth="50px"
                    imageHeight="50px"
                    imageRadius="50%"
                    pictureProps={pictureProps}
                    altColor={employeeRoleColor(employee.role)}
                />
                {/* 
                <Picture
                    imgPath={Profile}
                    height='50px'
                    width='50px'
                    style={{ borderRadius: "100%" }}
                /> */}
            </div>
            <div className="employee-data-container">
                <TextData
                    variant="label"
                    width="auto"
                    fontWeight="600"
                    color="#101844"
                    size="12px"
                    style={{
                        textAlign: "left",
                    }}
                >
                    {employee.fname} {employee.lname}
                </TextData>
                <div className="employee-position">
                    <div
                        className="dot"
                        style={{
                            backgroundColor: employeeRoleColor(employee.role),
                        }}
                    ></div>
                    <TextData
                        variant="label"
                        width="auto"
                        fontWeight="400"
                        size="9px"
                        color="#4C5690"
                    >
                        {employee.role === "Administrator"
                            ? "Administrateur"
                            : employee.role == "Manager"
                              ? "Manager"
                              : "Employé"}
                    </TextData>
                </div>
                <div
                    className="employee-hours"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "fit-content",
                        gap: "10px",
                    }}
                >
                    <TextData
                        width="auto"
                        variant="label"
                        size="10px"
                        color="#2A346D"
                        fontWeight="400"
                        className={["employee-time"]}
                    >
                        {tempsDeTravailHebdomadaire}h
                    </TextData>
                    <TextData
                        width="auto"
                        variant="label"
                        size="10px"
                        color="#4C5690"
                        fontWeight="500"
                    >
                        {formatTime(workedTime, false, true)}
                    </TextData>
                    {timeDiff !== 0 && (
                        <TextData
                            width="auto"
                            variant="label"
                            size="10px"
                            color={`${timeDiff >= 0 ? "#00A42E" : "#FB3640"}`}
                            fontWeight="500"
                        >
                            {/* ({timeDiff >= 0 ? "+" : "-"} */}(
                            {formatTime(timeDiff)}){/* ) */}
                        </TextData>
                    )}
                </div>
            </div>
            {(hasConge || hasIndispo) && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 3,
                    }}
                >
                    {hasIndispo && (
                        <div
                            className="absence-icon-cell"
                            style={{
                                borderRadius: "5px",
                                position: "relative",
                            }}
                        >
                            <IndispoSVG
                                color="#0F556D"
                                width={20}
                                height={20}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    right: "-5px",
                                    backgroundColor: "#101844",
                                    color: "white",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    fontSize: "x-small",
                                    width: "max-content",
                                    zIndex: "10",
                                    transform: "translateX(100%)",
                                }}
                            >
                                Indisponible de {hasIndispo?.startHour} à{" "}
                                {hasIndispo?.endHour}
                            </div>
                        </div>
                    )}
                    {hasConge && (
                        <div
                            className="absence-icon-cell"
                            style={{
                                borderRadius: "5px",
                                position: "relative",
                            }}
                        >
                            <CongeSVG color="#0F556D" width={20} height={20} />
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    right: "-5px",
                                    backgroundColor: "#101844",
                                    color: "white",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    fontSize: "x-small",
                                    width: "max-content",
                                    zIndex: "10",
                                    transform: "translateX(100%)",
                                }}
                            >
                                {
                                    congeTypes?.find(
                                        (congeType: any) =>
                                            congeType.value === hasConge?.type,
                                    )?.label
                                }
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
interface PlanningEmployeeCellProps {
    employee: any;
}

PlanningEmployeeCell.defaultProps = {};
