import * as React from "react";
import "./style.scss";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import LevelProgressBar from "../../molecules/LevelProgressBar";
import DraggableOrderContainer from "../../molecules/DraggableOrderContainer";
import { usePlanning } from "../../../hooks/usePlanning";
import { useEmployees } from "../../../hooks/useEmployees";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";
import { DndContext, closestCorners, closestCenter } from "@dnd-kit/core";
import { PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
    arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
export default function CustomOrderCard(props: any /*CustomOrderCardProps*/) {
    const { display, onClose, modalEnabled, employees } = props;
    const { updateCustomSort, departments, planningCustomSort } = usePlanning();
    const { onUpdateFilterBy, filterBy } = useEmployees();
    const [levels, setLevels] = React.useState([]);

    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "25px auto 25px 40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    Tri et ordre personnalisé
                </TextData>
            </div>
        );
    };
    // const [data, setData] = React.useState<any>({});
    // React.useEffect(() => {
    // let employeesList: any = [];
    // employees.map((employee: any) => {
    //     planning.employeesData[employee] &&
    //         employeesList.push(planning.employeesData[employee]);
    // });
    //     let allData: any = {};
    //     if (
    //         planning.toSearchPlanningEmployees &&
    //         planning.departments !== null
    //     ) {
    //         for (let department of planning.departments) {
    //             let empList: any = [];
    //             department?.employees?.map((id: string) => {
    //                 if (planning.toSearchPlanningEmployees[id]) {
    //                     empList.push(planning.toSearchPlanningEmployees[id]);
    //                 }
    //             });
    //             allData[department._id] = empList;
    //         }
    //     }
    //     setData(allData);
    // }, [planning.toSearchPlanningEmployees]);
    // React.useEffect(() => {
    //     let employeesList: any = [];
    //     employees?.map((employee: any) => {
    //         planning.employeesData[employee] &&
    //             employeesList.push(planning.employeesData[employee]);
    //     });
    //     let updatedData = [...employeesList];
    //     setData(updatedData);
    // }, [levels, employees]);
    const handleUpdateEmployeesData = async () => {
        let newCustomSort: any = {};
        departments.map((department: any) => {
            let updatedData: any = [];
            customSortVar[department._id]?.data?.map(
                (item: any, index: number) => {
                    updatedData.push({
                        order: index,
                        employee: item.employee,
                        name: item.name,
                    });
                },
            );
            // customSortVar
            if (
                newCustomSort[department._id] === null ||
                newCustomSort[department._id] === undefined
            ) {
                newCustomSort[department._id] = {};
            }
            newCustomSort[department._id] = {
                ...newCustomSort[department._id],
                data: updatedData,
            };
        });
        let updatedData: any = [];
        customSortVar["withoutDepartment"]?.data?.map(
            (item: any, index: number) => {
                updatedData.push({
                    order: index,
                    employee: item.employee,
                    name: item.name,
                });
            },
        );
        // customSortVar
        if (
            newCustomSort["withoutDepartment"] === null ||
            newCustomSort["withoutDepartment"] === undefined
        ) {
            newCustomSort["withoutDepartment"] = {};
        }
        newCustomSort["withoutDepartment"] = {
            ...newCustomSort["withoutDepartment"],
            data: updatedData,
        };
        await updateCustomSort({ ...newCustomSort });
        // setPlanningEmployeesData(data);
        let updatedFilterBy: any = { ...filterBy };
        if (levels.length === 0) {
            delete updatedFilterBy.niveau;
        } else {
            updatedFilterBy.niveau = levels;
        }
        console.log(" updatedFilterBy = ", updatedFilterBy);
        onUpdateFilterBy({
            ...updatedFilterBy,
        });
        onClose && onClose();
    };
    const colors = [
        "#ff6633",
        "#8833ff",
        "#33bfff",
        "#15b905",
        "#ff9600",
        "#083544",
        "#673ab7",
        "#ea1e62",
        "#0ab199",
        "#5677fd",
    ];
    function OrdersDepartmentBanner(props: any) {
        const { index, color, name, depId, opened } = props;
        return (
            <div
                style={{
                    backgroundColor: color,
                    width: "100%",
                    height: "30px",
                    color: "white",
                    padding: "5px 8px",
                    textAlign: "left",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "5px",
                }}
            >
                {name}
                <ChevronSVG
                    color="white"
                    height={20}
                    width={20}
                    style={{
                        transform: opened === index ? "rotate(180deg)" : "",
                        transition: "0.3s ease-in-out",
                    }}
                    onClick={() =>
                        setOpenedBanner((prev: number) =>
                            prev === index ? -1 : index,
                        )
                    }
                />
            </div>
        );
    }
    const [openedBanner, setOpenedBanner] = React.useState<number>(0);
    // const [tasks, setTasks] = React.useState([
    //     { id: 1, title: " one " },
    //     { id: 2, title: " two " },
    //     { id: 3, title: " three " },
    //     { id: 4, title: " four " },
    // ]);
    // const Column = ({ tasks }: any) => {
    //     return (
    //         <div
    //             style={{
    //                 backgroundColor: "gray",
    //                 padding: "5px",
    //                 margin: "10px",
    //             }}
    //         >
    //             <SortableContext
    //                 items={tasks}
    //                 strategy={verticalListSortingStrategy}
    //             >
    //                 {tasks.map((task: any) => (
    //                     <Task id={task.id} title={task.title} key={task.id} />
    //                 ))}
    //             </SortableContext>
    //         </div>
    //     );
    // };
    // const Task = ({ title, id }: any) => {
    //     const { attributes, listeners, setNodeRef, transform, transition } =
    //         useSortable({ id });
    //     const style = {
    //         transition,
    //         transform: CSS.Transform.toString(transform),
    //     };
    //     return (
    //         <div
    //             ref={setNodeRef}
    //             {...attributes}
    //             {...listeners}
    //             style={{
    //                 ...style,
    //                 backgroundColor: "white",
    //                 padding: "5px",
    //             }}
    //         >
    //             {title}
    //         </div>
    //     );
    // };

    // React.useEffect(() => {
    //     console.log(" tsks  = ", tasks);
    // }, [tasks]);
    const [customSortVar, setCustomSortVar] = React.useState(
        planningCustomSort ?? {},
    );

    React.useEffect(() => {
        setCustomSortVar(planningCustomSort ?? {});
    }, [planningCustomSort]);
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            {/* <div className='custom-order-container'> */}
            <PopUpCard
                display={display}
                onClose={onClose}
                header={<HeaderTitle />}
                widthCloseIcon="24px"
                containerWidth="480px"
                styleCloseIcon={{
                    height: "auto",
                    marginRight: "37px",
                    width: "24px",
                    border: "1px solid #edeff533",
                    borderTopRightRadius: "20px",
                }}
                headerStyle={{
                    fontSize: "22px",
                    fontWeight: "700",
                    color: "#2A346D",
                }}
                popupHeaderStyle={{
                    height: "80px",
                    backgroundColor: "#edeff54c",
                    borderBottom: "1px solid #C7CBE2",
                }}
                button1Text="Annuler"
                onClickButton1={onClose}
                button2Text="Valider"
                onClickButton2={handleUpdateEmployeesData}
            >
                <div className="custom-order-box-container">
                    <div
                        className="level-container"
                        style={{
                            paddingBottom: "15px",
                            borderBottom: "1px solid #e3e3e3",
                        }}
                    >
                        <TextData
                            variant="label"
                            width="auto"
                            size="16px"
                            fontWeight="700"
                            color="#6F78AB"
                            style={{
                                marginLeft: "11px",
                                textAlign: "left",
                            }}
                        >
                            Choisissez le niveau des employés
                        </TextData>
                        <LevelProgressBar
                            setLevels={setLevels}
                            levels={levels}
                        />
                    </div>
                    {/* <div style={{ color: "red" }}>
                        Le problème des ordres est en train d'être résolu ...
                    </div> */}
                    <div className="order-container">
                        <TextData
                            variant="div"
                            width="100%"
                            color="#6F78AB"
                            style={{
                                // backgroundColor: "#EDEFF5",
                                padding: "11px 80px 8px 11px",
                                textAlign: "start",
                            }}
                            fontWeight="700"
                            size="16px"
                        >
                            Ordre personnalisé des employés
                        </TextData>

                        <div
                            style={{
                                height: "40vh",
                                overflowY: "auto",
                                overflowX: "hidden",
                                backgroundColor: "#EDEFF5",
                                borderRadius: "5px",
                                width: "100%",
                            }}
                        >
                            {departments?.map(
                                (department: any, index: number) => {
                                    if (
                                        customSortVar?.[department?._id]?.data
                                            ?.length > 0 &&
                                        customSortVar?.[department._id]
                                            ?.listOfEmp
                                    ) {
                                        return (
                                            <div key={index}>
                                                <OrdersDepartmentBanner
                                                    index={index}
                                                    name={department.name}
                                                    depId={department._id}
                                                    color={colors[index % 10]}
                                                    opened={openedBanner}
                                                    onclick={setOpenedBanner}
                                                />
                                                <Container
                                                    data={
                                                        customSortVar[
                                                            department._id
                                                        ]?.data
                                                    }
                                                    setData={setCustomSortVar}
                                                    employees={
                                                        customSortVar[
                                                            department._id
                                                        ]?.listOfEmp
                                                    }
                                                    open={openedBanner}
                                                    index={index}
                                                    depId={department._id}
                                                />
                                            </div>
                                        );
                                    }
                                },
                            )}
                            {!departments?.length && (
                                <div>
                                    {/* <OrdersDepartmentBanner
                                        index={departments?.length ?? 0}
                                        name={
                                            departments?.length > 0
                                                ? "Sans département"
                                                : ""
                                        }
                                        depId={null}
                                        color="#6f78ab"
                                        opened={openedBanner}
                                        onclick={setOpenedBanner}
                                    /> */}
                                    <Container
                                        data={
                                            customSortVar["withoutDepartment"]
                                                ?.data || []
                                        }
                                        setData={setCustomSortVar}
                                        employees={
                                            customSortVar["withoutDepartment"]
                                                ?.listOfEmp
                                        }
                                        open={openedBanner}
                                        index={departments?.length ?? 0}
                                        depId={"withoutDepartment"}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </PopUpCard>
            {/* </div> */}
        </Modal>
    );
}
const Container = ({ data, setData, employees, open, index, depId }: any) => {
    const getTaskPos = (id: number) => {
        return data?.findIndex((item: any) => item.id === id);
    };
    const handleOnDragStart = () => {
        // console.log("    dragg ");
        // setNodeRef.current.classList.add('dragging');
    };
    const handleOnDragEnd = (e: any) => {
        const { active, over } = e;
        console.log(" active , ", active);
        console.log(" over , ", over);
        if (active.id === over.id) return;
        // setData((prev: any) => {
        //     const originalPos = getTaskPos(active.id);
        //     const newPos = getTaskPos(over.id);
        //     // console.log(" originalPos = ", originalPos);
        //     // console.log(" newPos = ", newPos);
        //     return arrayMove(prev, originalPos, newPos);
        // });
        setData((prev: any) => {
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);
            // console.log(" originalPos = ", originalPos);
            // console.log(" newPos = ", newPos);
            let newData = { ...prev };
            // console.log(" newData[depId].data ", newData[depId].data);
            let arr = [
                ...arrayMove([...prev[depId].data], originalPos, newPos),
            ];
            newData[depId] = {
                ...prev[depId],
                data: [...arr],
            };
            return newData;
        });
    };
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
    );
    return (
        <DndContext
            // onDragStart={handleOnDragStart}
            onDragEnd={handleOnDragEnd}
            // collisionDetection={closestCorners}
            collisionDetection={closestCenter}
            sensors={sensors}
        >
            <DraggableOrderContainer
                index={index}
                opened={open}
                data={data}
                employees={employees}
                depId={depId}
            />
            {/* <Column tasks={tasks} /> */}
        </DndContext>
    );
};
interface CustomOrderCardProps {
    display?: boolean;
    onClose?: (event?: Event) => void;
    modalEnabled?: boolean;
}
CustomOrderCard.defaultProps = {
    display: true,
    onClose: () => {
        console.log("onClose");
    },
    modalEnabled: false,
};
