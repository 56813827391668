import * as React from "react";
import "./style.scss";
import TextData from "../../atoms/TextData";

import { ReactComponent as MsgSVG } from "./Msg.svg";
import { ReactComponent as SpoonSVG } from "./spoon.svg";

import { fomatStartEndTime, formatTime } from "../../../_helpers/Functions";

import { useBranch } from "../../../hooks/useBranch";
import ShiftDetails from "./ShiftDetails";
const getColorRgba = (color: string, outlineOpacity: string) => {
    const rgbaValue = `rgba(${parseInt(color.slice(-6, -4), 16)}, ${parseInt(
        color.slice(-4, -2),
        16,
    )}, ${parseInt(color.slice(-2), 16)}, ${outlineOpacity})`;
    return rgbaValue;
};
export default function ShiftSummary(props: any) {
    const { from, to, steaker, pause, comment, repas, type, typeAbsence } =
        props.data;
    const { data, absenceColor, published, extern } = props;

    const color = React.useMemo(() => {
        if (type === 1) {
            return steaker?.color || absenceColor;
        } else {
            return absenceColor;
        }
    }, [type, absenceColor, steaker?.color]);
    const { branchSettings } = useBranch();
    const workedTime = React.useMemo(() => {
        if (branchSettings.usePause) {
            return data.to > data.from
                ? data.to - data.from - data.pause
                : 1440 - data.from + data.to - data.pause;
        } else {
            return data.to > data.from
                ? data.to - data.from
                : 1440 - data.from + data.to;
        }
    }, [props]);
    if (!color) return <div></div>;
    return (
        <div
            className="shift-container summary-container"
            // ref={props.reference}
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent:
                    typeAbsence || type === 0 ? "center" : "space-between",
                backgroundColor: getColorRgba(color, "0.1"),
                borderRadius: "5px",
                border: `1px solid ${color}`,
                width: "100%",
                // minHeight: "60px",
                // height: "fit-content",
                // maxHeight: "72px",
                height: "70px",
                position: "relative",
                cursor: "inherit",
            }}
        >
            {data.type === 1 && (
                <ShiftDetails
                    workedTime={formatTime(workedTime, false, true)}
                    color={color}
                    published={published}
                    data={data}
                    extern={extern}
                />
            )}
            {from !== 0 || to !== 1439 ? (
                <div
                    style={{
                        width: "calc(100%)",
                        height: "calc(100%)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        margin: "0px",
                        padding: "3px",
                        gap: "3px",
                    }}
                >
                    <div
                        className="shift-hours-container"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            // minHeight: "32%",
                        }}
                    >
                        <div
                            className="hours-adjustment"
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                gap: "5px",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "2px",
                                }}
                            >
                                <TextData
                                    className={["hours-adjustment-text"]}
                                    variant="label"
                                    width="auto"
                                    style={{
                                        padding: "2px 3px",
                                        backgroundColor: `${color}`,
                                        borderRadius: "5px",
                                        // height: "22px",
                                    }}
                                    color="white"
                                    size="14px"
                                >
                                    {fomatStartEndTime(from)}
                                </TextData>
                                <TextData
                                    className={["hours-adjustment-text"]}
                                    variant="label"
                                    width="auto"
                                    style={{
                                        padding: "2px 3px",
                                        backgroundColor: `${color}`,
                                        borderRadius: "5px",
                                        // height: "2px",
                                    }}
                                    color="white"
                                    size="14px"
                                >
                                    {fomatStartEndTime(to)}
                                </TextData>
                            </div>

                            {pause && branchSettings.usePause ? (
                                <TextData
                                    className={["hours-adjustment-text"]}
                                    variant="div"
                                    width="auto"
                                    size="11px"
                                    color={color}
                                    style={{
                                        marginLeft: "auto",
                                        justifySelf: "flex-end",
                                        fontWeight: "500",
                                    }}
                                >
                                    {pause === 0
                                        ? ""
                                        : pause < 60
                                          ? `${pause}min`
                                          : formatTime(pause)}
                                </TextData>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // maxHeight: "68%",
                            minHeight: "30px",
                        }}
                    >
                        <div
                            className="shift-role-container"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "max-content",
                                maxWidth: "100%",
                                height: "55%",
                                alignSelf: "flex-end",
                            }}
                        >
                            <div
                                style={{
                                    justifySelf: "flex-start",
                                    padding: "0",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {type === 1 && steaker?.name ? (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        fontWeight="600"
                                        color="#2A346D"
                                        size="14px"
                                    >
                                        {steaker?.name.slice(0, 14)}
                                        {steaker?.name.length > 14 && "..."}
                                    </TextData>
                                ) : type === 1 && !steaker?.name ? (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        fontWeight="600"
                                        color="#2A346D50"
                                        size="12px"
                                    >
                                        {"(Pas d'étiquette)"}
                                    </TextData>
                                ) : (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        fontWeight="600"
                                        color={color}
                                        size="12px"
                                    >
                                        {typeAbsence || "Repos hebdomadaire"}
                                    </TextData>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "5px",
                                alignSelf: "flex-start",
                                height: "45%",
                            }}
                        >
                            {repas >= 1 && branchSettings.useRepas && (
                                <div>
                                    <SpoonSVG
                                        height={15}
                                        width={15}
                                        style={{ minHeight: 15, minWidth: 15 }}
                                    />
                                </div>
                            )}
                            {data.comment && /\S/.test(data.comment) && (
                                <div
                                    style={{
                                        padding: "1px",
                                    }}
                                >
                                    <MsgSVG
                                        height={15}
                                        width={15}
                                        style={{ minHeight: 15, minWidth: 15 }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <TextData
                        variant="div"
                        width="auto"
                        color={absenceColor}
                        size="12px"
                    >
                        <div>{typeAbsence || "Repos hebdomadaire"}</div>
                        <div
                            style={{
                                color: "#2a346d",
                                fontWeight: "normal",
                            }}
                        >
                            Toute la journée
                        </div>
                    </TextData>
                </div>
            )}
        </div>
    );
}
