import React from "react";
import TabNavigator from "../../molecules/TabNavigator";
import TabContent from "../../atoms/TabContent";
import Button from "../../atoms/ZButton";
import TextData from "../../atoms/TextData";
import * as Container from "../../atoms/Container";
import { EmployeesFilterProps, SortByProps } from "../../../hooks/useEmployees";
import * as Icons from "../../../Icons";

export default function Conges({
    processedCount,
    ValidCount,
    selectedBranch,
    FirstTabCompoenent,
    SecondTabCompoenent,
    openAddConge,
}: CongesInterface) {
    const [activeTab, setActiveTab] = React.useState(0);
    const changeTab = (index: number) => {
        if (index !== activeTab) {
            setActiveTab(index);
        }
    };

    const tabs = [
        {
            title: "À traiter",
            data: ValidCount,
            formatter: () => paginationDivNo,
        },
        {
            title: "Demandes traitées",
            data: processedCount,
            formatter: () => paginationDivValid,
        },
    ];

    const paginationDivNo = (
        <div
            className={`flex items-center gap-2 text-lg font-bold group-hover:text-t-600 ${
                activeTab == 0 ? "text-t-600" : "text-t-300"
            }`}
        >
            <Icons.UserGroup width={18} height={18} />
            <span>À traiter</span>
            <span
                className={`rounded font-bold text-base p-1 group-hover:text-t-700 group-hover:bg-n-0 ${
                    activeTab == 0 ? "text-t-700 bg-n-0" : "text-white bg-t-200"
                }`}
            >
                {ValidCount}
            </span>
        </div>
    );

    const paginationDivValid = (
        <div
            className={`flex items-center gap-2 text-lg font-bold   group-hover:text-t-600  ${
                activeTab == 1 ? "text-t-600" : "text-t-300"
            }`}
        >
            <Icons.Archive width={18} height={18} />
            <span>Demandes traitées</span>
            <span
                className={`rounded font-bold text-base p-1 group-hover:text-t-700 group-hover:bg-n-0 ${
                    activeTab == 1 ? "text-t-700 bg-n-0" : "text-white bg-t-200"
                }`}
            >
                {processedCount}
            </span>
        </div>
    );

    return (
        <div>
            <Container.Header>
                <div
                    style={{
                        display: "flex",
                        width: "auto",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "80px",
                        paddingLeft: "20px",
                    }}
                >
                    <TextData color="#11106E" size="34" fontWeight="bold">
                        Congés
                    </TextData>

                    {selectedBranch && (
                        <Button
                            onClick={() => openAddConge()}
                            variant={"primary"}
                            title="Ajouter un congé"
                            leftComponent={<Icons.AddSquare />}
                            size={"lg"}
                            style={{
                                fontSize: "16px",
                                width: "auto",
                                padding: "0.75rem 20px",
                                height: "auto",
                            }}
                        />
                    )}
                </div>
            </Container.Header>
            <Container.Body>
                <>
                    <TabNavigator
                        containerClasses="!bg-n-0-op-2"
                        headerClasses=" flex justify-center group"
                        tabs={tabs.map((tab, index) => ({
                            ...tab,
                            active: index == activeTab,
                            onClick: (idx) => changeTab(idx),
                        }))}
                        style={{
                            position: "relative",
                        }}
                        tabsStyle={{
                            position: "sticky",
                            top: "0",
                            backgroundColor: "#fbfcfd",
                            zIndex: "99",
                            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <TabContent
                            containerClasses=""
                            value={activeTab}
                            index={0}
                            component={FirstTabCompoenent}
                            containerCss={
                                {
                                    // overflowX: "auto",
                                }
                            }
                        />
                        <TabContent
                            containerClasses=""
                            value={activeTab}
                            index={1}
                            component={SecondTabCompoenent}
                        />
                    </TabNavigator>

                   
                </>
            </Container.Body>
        </div>
    );
}

interface CongesInterface {
    processedCount: number;
    ValidCount: number;
    FirstTabCompoenent: React.ReactNode;
    SecondTabCompoenent: React.ReactNode;
    openAddConge: () => void;
    onUpdateFilterBy?: (props: EmployeesFilterProps) => void;
    filterBy?: EmployeesFilterProps;
    selectedSite?: any;
    sortBy?: SortByProps;
    onUpdateSortBy?: (props: SortByProps) => void;
    selectedBranch?: string;
}
