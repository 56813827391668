import * as React from "react";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import "./style.css";
import DotTicket from "../../atoms/DotTicket";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";
import { ReactComponent as CheckSVG } from "./check.svg";
import { useBranch } from "../../../hooks/useBranch";

export default function ShiftTimeInputs(props: any) {
    const {
        shiftFixedData,
        setShiftFixedData,
        pauseDisabled,
        setPauseDisabled,
        planningDefaultPause,
    } = props;
    const { branchSettings } = useBranch();
    const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);
    const tabPressed = React.useRef(false);
    const handleTabPress = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        if (e.key === "Tab") {
            e.preventDefault();
            tabPressed.current = true;
            const nextIndex = index + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                inputRefs.current[0]?.focus();
            }
        }
    };
    return (
        <div
            className="add-shift-time-container"
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 16,
                marginBottom: 18,
            }}
        >
            <Input
                reference={(el: any) => (inputRefs.current[0] = el)}
                onKeyDown={(e: any) => handleTabPress(e, 0)}
                type="time"
                captionText={"Début"}
                defaultValue={shiftFixedData.from}
                onBlur={(value: any) => {
                    if (tabPressed.current) {
                        tabPressed.current = false;
                    } else {
                        setShiftFixedData((prev: any) => ({
                            ...prev,
                            from: value,
                        }));
                    }
                }}
                inputWidth="200px"
                borderColor="#C7CBE2"
                captionColor="#2a346d"
                borderWidth={1}
                captionSize="12px"
                // iconImg={Clock}
                inputRadius="4px"
                textColor="#4C5690"
                textSize={14}
                iconLocation="right"
                style={{
                    inputStyle: {
                        minHeight: "48px",
                        fontWeight: "400",
                    },
                    captionStyle: {
                        fontWeight: "500",
                    },
                }}
            />
            <Input
                reference={(el: any) => (inputRefs.current[1] = el)}
                onKeyDown={(e: any) => handleTabPress(e, 1)}
                type="time"
                captionText={"Fin"}
                defaultValue={shiftFixedData.to}
                onBlur={(value: any) => {
                    if (tabPressed.current) {
                        tabPressed.current = false;
                    } else {
                        setShiftFixedData((prev: any) => ({
                            ...prev,
                            to: value,
                        }));
                    }
                }}
                inputWidth="200px"
                borderColor="#C7CBE2"
                captionColor="#2a346d"
                borderWidth={1}
                captionSize="12px"
                inputRadius="4px"
                textColor="#4C5690"
                textSize={14}
                iconLocation="right"
                style={{
                    inputStyle: {
                        minHeight: "48px",
                        fontWeight: "400",
                    },
                    captionStyle: {
                        fontWeight: "500",
                    },
                }}
            />

            {branchSettings.usePause && (
                <Input
                    captionText={"Pause(min)"}
                    captionColor="#2a346d"
                    disabled={pauseDisabled}
                    placeholder="Pause (min)"
                    inputWidth="100px"
                    borderColor="#C7CBE2"
                    borderWidth={1}
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    type="number"
                    value={!pauseDisabled ? shiftFixedData.pause : undefined}
                    onBlur={(value: any) => {
                        setShiftFixedData((prev: any) => ({
                            ...prev,
                            pause: parseFloat(value),
                        }));
                    }}
                    maxLength={3}
                    min="0"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                            opacity: pauseDisabled ? "0.2" : "1",
                        },
                        captionStyle: {
                            fontWeight: "500",
                            color: "#8E96C1",
                            opacity: pauseDisabled ? "0.2" : "1",
                        },
                    }}
                />
            )}
            {planningDefaultPause !== undefined && branchSettings.usePause && (
                <label className="custom-checkbox">
                    <input
                        type="checkbox"
                        checked={pauseDisabled}
                        onChange={(e) => {
                            setPauseDisabled(e.target.checked);
                            setShiftFixedData((prev: any) => ({
                                ...prev,
                                pause: e.target.checked
                                    ? planningDefaultPause
                                    : 0,
                            }));
                        }}
                    />
                    <div className="checkmark">
                        <span>
                            <CheckSVG />
                        </span>
                    </div>
                    <div
                        style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#8E96C1",
                        }}
                    >
                        Pause par défaut ({planningDefaultPause}
                        min)
                    </div>
                </label>
            )}
            {branchSettings.useRepas && (
                <SelectInput
                    title="Repas"
                    captionColor="#2a346d"
                    options={[
                        { label: "0 repas", value: 0 },
                        { label: "1 repas", value: 1 },
                        { label: "2 repas", value: 2 },
                    ]}
                    selectedValue={{
                        label: `${shiftFixedData.repas} repas`,
                        value: parseInt(shiftFixedData.repas),
                    }}
                    onSelectOption={(option) =>
                        setShiftFixedData((prev: any) => ({
                            ...prev,
                            repas: option.value,
                        }))
                    }
                    style={{
                        height: "48px",
                        color: "#C7CBE2",
                    }}
                    captionSize="12px"
                    optionColor="#4C5690"
                    optionSize="14px"
                />
            )}
        </div>
    );
}
