
import Button from "../../../../components/atoms/ZButton";

export const ActionColumnFormatter = (
  row: any,
  extras: any) => {
    return (
      <span className="text-v-800 text-md font-light">
      <Button
       onClick={() => {
        extras.openVacationRequestDialog(); 
        extras.updateEmployeeValue(row);   // This passes the row data to the function
      }}
                                title="Traiter"
                                style={{
                                    fontSize: "16px",
                                    width: "auto",
                                    height: "auto",
                                }}
                            />
      </span>
    );
  };
  