import React from "react";
import { FieldInterface } from "../../../_helpers/FormHelpers";

export default function ZTextarea({
  field,
  placeholder,
  onBlur,
  onUpdate,
  withFeedbackLabel,
  required,
  label,
  rows = 3,
}: ZTextfieldInterface) {
  const { error, hasError, value, touched, name } = field;
  const getClassNameInput = () => {
    let baseClass: string =
      "h-full w-full border rounded-md focus:outline-none focus:border-t-500 px-4 py-2 text-md font-light text-n-600";
    if (touched && hasError) {
      baseClass += " border-error";
    } else {
      baseClass += " border-n-100";
    }
    return baseClass;
  };

  return (
    <div className="w-full">
      <div className="flex justify-between text-n-400">
        <label className="text-base font-medium mb-1">
          {label}
          {required && <span className="text-error font-semibold">*</span>}
        </label>
      </div>

      <div className="relative flex items-center">
        <textarea
          rows={rows}
          placeholder={placeholder}
          name={name}
          value={value}
          onBlur={() => {
            onBlur && onBlur(field.name);
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            onUpdate && onUpdate(e.target.value);
          }}
          className={getClassNameInput()}
        />
      </div>
      <div>
        {withFeedbackLabel && touched && hasError && (
          <span className="text-error text-sm font-light w-full max-w-[20px]">
            {error}
          </span>
        )}
      </div>
    </div>
  );
}

interface ZTextfieldInterface {
  withFeedbackLabel?: boolean;
  placeholder?: string;
  required: boolean;
  onUpdate?: (data: string | number) => void;
  onBlur?: (field: string) => void;
  field: FieldInterface;
  label?: string;
  rows?: number;
}
