import * as React from "react";
import "./style.scss";

function Modal(props: ModalProps) {
    const { children, onClose, modalEnabled } = props;
    const [modalStatus, setModalStatus] = React.useState<boolean>(
        modalEnabled ?? true,
    );
    const handleClose = () => {
        if (modalEnabled === null) {
            setModalStatus(false);
        }
        onClose && onClose();
    };
    React.useEffect(() => {
        if (modalEnabled !== null && modalEnabled !== undefined) {
            setModalStatus(modalEnabled);
        }
    }, [modalEnabled]);

    if (modalStatus) {
        return (
            <div
                className="modal-container"
                style={{
                    display: modalStatus ? "flex" : "none",
                    zIndex: "1000",
                }}
                onClick={handleClose}
            >
                <div
                    className="modal-content"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {children}
                </div>
            </div>
        );
    } else {
        return null;
    }
}
interface ModalProps {
    children: React.ReactNode;
    onClose?: () => void;
    modalEnabled?: boolean | null;
}
Modal.defaultProps = {
    children: "hello",
    onClose: () => {},
    modalEnabled: null,
};

export default Modal;
