import React from "react";
import { usePlanning } from "../../../hooks/usePlanning";
import "./style.css";
import ShiftSummary from "./ShiftSummary";
export default function Shift(props: any) {
    const {
        data,
        setIsShiftEditing,
        setShiftToEdit,
        setIsShift,
        setIsClicked,
        extern,
    } = props;
    const [isHover, setisHover] = React.useState(false);
    const frontDivRef = React.useRef<HTMLDivElement>(null);
    const backDivRef = React.useRef<HTMLDivElement>(null);
    const divContainer = React.useRef<HTMLDivElement>(null);
    const { planningInfo, absenceColors } = usePlanning();

    React.useLayoutEffect(() => {
        // console.log("frontDivRef.current", frontDivRef.current);
        // console.log("backDivRef.current", backDivRef.current);
        if (frontDivRef.current && backDivRef.current) {
            // console.log("frontDivRef.current", frontDivRef.current);
            // console.log("backDivRef.current", backDivRef.current);
            // console.log("use");
            const frontDivHeight =
                frontDivRef.current.getBoundingClientRect().height ?? 0;
            const backDivHeight =
                backDivRef.current.getBoundingClientRect().height ?? 0;
            const frontDivWidth =
                frontDivRef.current.getBoundingClientRect().width ?? 0;
            const backDivWidth =
                backDivRef.current.getBoundingClientRect().width ?? 0;

            const maxHeight = Math.max(frontDivHeight, backDivHeight);
            const maxWidth = Math.max(frontDivWidth, backDivWidth);
            frontDivRef.current.style.cssText += `width: 100%`;
            backDivRef.current.style.cssText += `height: ${maxHeight}px; width: 100%;`;
            if (divContainer.current)
                divContainer.current.style.cssText = `height: ${maxHeight}px; width:100%`;
            // frontDivRef.current.style.cssText += `height: ${maxHeight}px; width: ${maxWidth}px;`;
            // backDivRef.current.style.cssText += `height: ${maxHeight}px; width: ${maxWidth}px;`;
            // if (divContainer.current)
            //     divContainer.current.style.cssText = `height: ${maxHeight}px; width: ${maxWidth}px;`;
        }
    }, [isHover]);

    const toggleHover = (value: boolean) => {
        setisHover(value);
    };
    const { setDraggedShiftType } = usePlanning();
    // React.useEffect(() => {
    //     if (
    //         data.type === 0 &&
    //         data.typeAbsence &&
    //         data.typeAbsence !== "" &&
    //         absenceColors?.length > 0
    //     ) {
    //         for (let i = 0; i < absenceColors.length; i++) {
    //             // #f6b93b
    //             if (absenceColors[i]?.absence === data.typeAbsence) {
    //                 console.log(
    //                     " *-*-*-*-*  ",
    //                     absenceColors[i]?.absence,
    //                     data.typeAbsence,
    //                     absenceColors[i]?.color
    //                 );
    //                 setAbsenceColor(absenceColors[i].color);
    //                 break;
    //             }
    //         }
    //     }
    // }, [data.type, data.typeAbsence]);
    const handleEditShift = () => {
        if (!planningInfo?.published) {
            setIsShiftEditing(true);
            setIsClicked(true);
            if (data?.type === 1) {
                setIsShift(true);
            } else {
                setIsShift(false);
            }
            setShiftToEdit(data);
        }
    };
    const absenceColor = React.useMemo(() => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)?._id;
            for (let i = 0; i < absenceColors?.length; i++) {
                const { absence, site, color } = absenceColors[i];
                if (absence === data.typeAbsence && site === siteId) {
                    return color;
                }
            }
        }
        return "#000000";
    }, [data, absenceColors]);

    // const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    //     // Set the shift data to be transferred during drag
    //     setDraggedShiftType("normal");
    //     console.log(" dragged shift = ", data);
    //     event.dataTransfer.setData(
    //         "text/plain",
    //         JSON.stringify({ type: "normal", shift: data })
    //     );
    // };
    return (
        <div
            // draggable
            // onDragStart={handleDragStart}
            style={{
                width: "100%",
                height: "100%",
                borderRadius: "5px",
                opacity: extern ? "0.5" : "1",
            }}
            onClick={handleEditShift}
        >
            <ShiftSummary
                data={data}
                absenceColor={absenceColor}
                extern={extern}
            />
        </div>
    );
}
