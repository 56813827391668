import React from "react";
import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";

interface ProfilePictureProps {
    firstName: string;
    lastName: string;
    profileImage: string;
    imageWidth?: string;
    imageHeight?: string;
    imageRadius?: string;
    pictureProps: any;
    altColor?: string;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
    profileImage,
    firstName,
    lastName,
    imageRadius,
    pictureProps,
    altColor,
}) => {
    return (
        <div
            style={{
                width: pictureProps.width,
                height: pictureProps.height,
            }}
        >
            {profileImage ? (
                <Picture {...pictureProps} /> // Adjust the prop type accordingly
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: altColor || "blue",
                        color: "white",
                        width: pictureProps.width,
                        height: pictureProps.height,
                        borderRadius: pictureProps.borderRadius || imageRadius,
                        fontWeight: "bold",
                    }}
                >
                    {`${firstName?.charAt(0)}${lastName?.charAt(0)}`}
                </div>
            )}
        </div>
    );
};

export default ProfilePicture;
