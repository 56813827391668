import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants/env";
import axios from "axios";

export const getAllHolidaysService = createAsyncThunk(
    "vacation/getAllUserHolidays",
    async (payload: any, { rejectWithValue }) => {
        const { userId, startDay, endDay } = payload;
        const token = localStorage.getItem("token");
        if (token && userId && startDay && endDay) {
            try {
                const res = await fetch(API.client.holiday.getUserHolidays, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ userId, startDay, endDay }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const getAllHolidays = createAsyncThunk(
    "vacation/getAllHolidays",
    async (payload: any, { rejectWithValue }) => {
        const { userId, status, month, year, licenseId, siteId } = payload;
        const token = localStorage.getItem("token");

        if (token) {
            try {
                const res = await axios.get(API.client.holiday.getHolidays, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    params: {
                        userId,
                        status,
                        month,
                        year,
                        licenseId,
                        siteId,
                    },
                });

                return {
                    status,
                    data: res.data,
                };
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const acceptHoliday = createAsyncThunk(
    "vacation/acceptHoliday",
    async (payload: any, { rejectWithValue }) => {
        const data: any = {
            congeId: payload.congeId,
            cause: payload.cause,
            userId: payload.userId,
        };
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const res = await axios.post(API.client.holiday.accept, data, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                return res.data;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const rejectHoliday = createAsyncThunk(
    "vacation/rejectHoliday",
    async (payload: any, { rejectWithValue }) => {
        const data: any = {
            congeId: payload.congeId,
            cause: payload.cause,
            userId: payload.userId,
        };
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const res = await axios.post(API.client.holiday.reject, data, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                return res.data;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const createHoliday = createAsyncThunk(
    "vacation/createHoliday",
    async (payload: any, { rejectWithValue }) => {
        const data: any = {
            userId: payload.userId,
            fromDay: payload.fromDay,
            fromHour: payload.fromHour,
            toDay: payload.toDay,
            toHour: payload.toHour,
            allDay: payload.allDay,
            type: payload.type,
            message: payload.message,
            licenseId: payload.licenseId,
        };
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const res = await axios.post(
                    API.client.holiday.create,
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    },
                );

                return {
                    status: "success",
                    data: res.data,
                };
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const deleteHoliday = createAsyncThunk(
    "vacation/delete_conge",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const { congeId } = payload;

                const response = await axios.post(
                    API.client.holiday.delete,
                    { id: congeId },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    },
                );
                return response.data;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
