import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants/env";

export const getAllRulesService = createAsyncThunk(
    "vacationRule/getAllRules",
    async (_, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const res = await fetch(API.client.vacationRule.getAll, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const createNewRuleService = createAsyncThunk(
    "vacationRule/createNewRuleService",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (
            token &&
            payload.designation &&
            payload.baseWorkDays &&
            payload.baseEntitlement &&
            payload.restrictedBypassLimitDays
        ) {
            try {
                const users = (payload.users && payload.users.length > 0) ? payload.users : undefined;
                const res = await fetch(API.client.vacationRule.create, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        designation: payload.designation,
                        baseWorkDays: payload.baseWorkDays,
                        baseEntitlement: payload.baseEntitlement,
                        restrictedBypassLimitDays:
                            payload.restrictedBypassLimitDays,
                        users
                    }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const updateRuleService = createAsyncThunk(
    "vacationRule/updateRuleService",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (
            token &&
            payload.idRule &&
            payload.designation &&
            payload.baseWorkDays &&
            payload.baseEntitlement &&
            payload.restrictedBypassLimitDays
        ) {
            console.log(
                "payload.restrictedBypassLimitDays : ",
                payload.restrictedBypassLimitDays
            );
            try {
                const res = await fetch(
                    API.client.vacationRule.update(payload.idRule),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            designation: payload.designation,
                            baseWorkDays: payload.baseWorkDays,
                            baseEntitlement: payload.baseEntitlement,
                            restrictedBypassLimitDays:
                                payload.restrictedBypassLimitDays,
                        }),
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const deleteRuleService = createAsyncThunk(
    "vacationRule/deleteRuleService",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.idRule) {
            try {
                const res = await fetch(
                    API.client.vacationRule.delete(payload.idRule),
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const assignUsersToRuleService = createAsyncThunk(
    "vacationRule/assignUsersToRuleService",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.users && payload.idRule) {
            try {
                const res = await fetch(
                    API.client.vacationRule.assignUsersToRule(payload.idRule),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            users: payload.users,
                        }),
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const getUsersByRuleService = createAsyncThunk(
    "vacationRule/getUsersByRuleService",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.idRule) {
            try {
                const res = await fetch(
                    API.client.vacationRule.getUsersByRule(payload.idRule),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
