import React from "react";
import UserNameImgCard from "../../../../components/molecules/UserNameImgCard";
import { generatePicUrl } from "../../../../_helpers/PictureHelpers";

export const NameColumnFormatter = ( row: any) => {
  return (
    <UserNameImgCard
      firstName={row?.user?.fname || "no first"}
      lastName={row?.user?.lname || "no first"}
      role={row?.user?.role}
      img={row?.user?.picture && generatePicUrl(row?.user?.picture)}
      variant={"n-800"}
    />
  );
};
